<template>
  <div>
    <el-button type="default" @click="editOutline('custom', outline_id)" icon="">View/Edit</el-button>

    <!-- Start of dialog items -->
    <el-dialog
      center
      width="100%"
      height="100%"
      :visible.sync="modals.custom">

      <div class="row text-center">
        <div class="col-md-12">
                      <span style="font-weight: bold; font-size: 3em">{{
                          outline.description
                        }}</span>
        </div>
      </div>

      <!-- begin of name card-->
      <div class="container">
        <card class="stacked-form">
          <div class="row">
            <div class="col-md-12 text-center" id="top">
              <h5>User Items</h5>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <label for="name">Name</label>
              <el-input id="name" type="text" :value="fullName" disabled></el-input>
            </div>
            <div class="col-sm-6">
              <label for="weight">Weight</label>
              <el-input id="weight" type="text" @input="changeWeightClass"
                        v-model="outline.weight">
              </el-input>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <label for="class">Class</label>
              <el-input id="class" :value="'Class ' + userClass" disabled
                        type="text"></el-input>
            </div>
            <div class="col-sm-6">
              <label>Rank</label><br>
              <el-select filterable v-model="outline.rank_id" placeholder="select item">
                <el-option v-for="rank in dropdown_ranks"
                           :key="rank.id" :value="rank.id" :label="rank.name">
                </el-option>
              </el-select>

            </div>
          </div>
          <br>
          <!--begin BBT Reqs-->
          <div class="row">
            <div class="col-sm-4">
              <div class="col-sm-12 text-center">
                <span style="font-weight: bold;">BBT Reqs</span>
              </div>
              <div class="col-sm-12 text-center">
                <el-checkbox v-model="outline.bbt_reqs_checked" size="large"></el-checkbox>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="col-sm-12 text-center">
                <span style="font-weight: bold;">Testing Reqs</span>
              </div>
              <div class="col-sm-12 text-center">
                <el-checkbox v-model="outline.testing_reqs_checked" size="large"></el-checkbox>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="col-md-12 text-center">
                <span style="font-weight: bold;">Scoring View</span>
              </div>
              <div class="col-md-12 text-center">
                <el-checkbox v-model="outline.scoring_view_checked" size="large" @change="setTechniqueNames"></el-checkbox>
              </div>
            </div>
          </div>
          <div v-if="outline.bbt_reqs_checked === true">
            <br>
            <div class="row">
              <div class="col-sm-12 text-center"><span style="font-weight: bold; font-size: 2em;">BBT Reqs</span>
              </div>
            </div>
            <div id="requirements" v-html="requirements_output"></div>
          </div>
          <!--end BBT Reqs-->
        </card>
      </div>
      <!--end of name card-->

      <!--begin card for non scoring view-->
      <card v-if="!outline.scoring_view_checked" style="width: 90%; margin: auto; padding: 0px 10px">
        <!--Begin first row-->
        <div class="row pb-4 techniqueHeader">
          <div class="custom-col-1 text-center">
            Order
          </div>
          <div class="custom-col-1-wd text-center">
            Support Option
          </div>
          <div class="custom-col-1-wd text-center">
            Technique
          </div>
          <div class="custom-dir text-center">
            Direction
          </div>
          <div class="custom-col-1 text-center">
            Board Size
          </div>
          <div class="custom-col-1-th text-center">
            # of Boards
          </div>
          <div class="custom-col-2 text-center">
            <div class="row">
              <div class="custom-col-5 text-center">
                <span class="verticaltext_content">Self Held</span>
              </div>
              <div class="custom-col-5 text-center">
                <span class="verticaltext_content">Blindfolded</span>
              </div>
              <div class="custom-col-5 text-center">
                <span class="verticaltext_content">Simultaneous</span>
              </div>
              <div class="custom-col-5 text-center">
                <span class="verticaltext_content">Successive</span>
              </div>
              <div class="custom-col-5 text-center">
                <span class="verticaltext_content">No Spacers</span>
              </div>
            </div>
          </div>
          <div class="custom-col-1-th text-center pb-4">
            Score
          </div>
        </div>
        <br>
        <!--end first row-->

        <div class="row p-3" v-for="technique in outline.breaking_techniques_to_outlines"
             :key="technique.id" :id="technique.id">

          <!--first 1-->
          <div class="custom-col-1">
            <div class="col-md-12">
              <div class="row">
                <div class="col-sm-3">{{ technique.order }}</div>
                <div class="col-sm-3">
                  <el-button type="text" icon="el-icon-arrow-up" @click="moveTechniqueUp(technique, technique.id)" size="large"/>
                </div>
                <div class="col-sm-3">
                  <el-button type="text" icon="el-icon-arrow-down" @click="moveTechniqueDown(technique, technique.id)" size="large"/>
                </div>
                <div class="col-sm-3">
                  <el-button v-if="outline.locked" type="danger" @click="deleteTechniqueRow(technique)" icon="el-icon-delete" disabled></el-button>
                  <el-button v-else type="danger" @click="deleteTechniqueRow(technique)" icon="el-icon-delete"></el-button>
                </div>
              </div>
            </div>
          </div>
          <!--end first one-->

          <!--begin 2-->
          <div class="custom-col-1-wd">
              <el-select filterable :class="technique.speed_break_color" style="border-radius: 4px;width: 100%"
                         v-model="technique.dropdown_modifier_id" placeholder="Select Support Option"
                          @input="setAModifiers">
                <el-option v-for="support in dropdown_support_options"
                            :key="support.id" :value="support.id" :label="support.description">
                </el-option>
              </el-select>
          </div>
          <!--end 2-->

          <!--begin 3-->
          <div class="custom-col-1-wd">
            <el-select filterable v-model="technique.breaking_technique_id" placeholder="Select Technique" style="width: 100%"
                       @input="getDirectionsForTechnique(technique.id, technique.breaking_technique_id, technique)">
              <el-option v-for="technique in dropdown_techniques"
                         :key="technique.id" :value="technique.id" :label="technique.name">
              </el-option>
            </el-select>
          </div>
          <!--end 3-->

          <!--begin 4-->
          <div class="custom-dir">
            <el-select filterable v-if="technique && technique.breaking_technique && technique.breaking_technique.directions"
                       v-model="technique.direction_id" placeholder="Select Direction" style="width: 100%"
                       @input="setAModifiers">
              <el-option v-for="direction in technique.breaking_technique.directions"
                         :key="direction.id" :value="direction.id" :label="direction.name">
              </el-option>
            </el-select>
          </div>
          <!--end 4-->

          <!--begin 5-->
          <div class="custom-col-1">
            <el-select filterable :class="technique.color" style="border-radius: 4px"
                       v-model="technique.board_id" placeholder="Select Board Size"
                       @input="setAModifiers">
              <el-option v-for="board in dropdown_boards"
                         :key="board.id" :value="board.id" :label="board.name">
              </el-option>
            </el-select>
          </div>
          <!--end 5-->

          <!--begin 6-->
          <div class="custom-col-1-th">
            <el-input type="number" :min="1" placeholder="Num Boards"
                      @change="boardChange(technique)"
                      v-model="technique.num_boards"></el-input>
          </div>
          <!--end 6-->

          <!--begin 7-->
          <div class="custom-col-2">
            <div class="row">
              <div class="custom-col-5 text-center align-self-center">
                <el-checkbox @input="setAModifiers" v-model="technique.self_held" size="large"></el-checkbox>
              </div>
              <div class="custom-col-5 text-center align-self-center">
                <el-checkbox @input="setAModifiers" v-model="technique.blindfolded" size="large"></el-checkbox>
              </div>
              <div class="custom-col-5 text-center align-self-center">
                <el-checkbox @input="setAModifiers" v-model="technique.simultaneous" size="large"></el-checkbox>
              </div>
              <div class="custom-col-5 text-center align-self-center">
                <el-checkbox @input="setAModifiers" v-model="technique.successive" size="large"></el-checkbox>
              </div>
              <div class="custom-col-5 text-center align-self-center">
                <el-checkbox @input="setAModifiers" v-model="technique.no_spacers" size="large"></el-checkbox>
              </div>
            </div>
          </div>
          <!--end 7-->

          <!--begin 8-->
          <div class="custom-col-1-th">
            <div v-if="scores_objects[technique.id]"
                 class="col-sm-12 text-center align-self-center">
              <el-input type="text" disabled>
                {{
                  scores_objects[technique.id].line_score
                }}
              </el-input>
            </div>
            <div v-else>
              <el-input type="text" disabled>0</el-input>
            </div>
          </div>
          <!--end 8-->

        </div>
        <br>
        <!--begin buttons at the bottom of the card-->
        <div class="techniqueFooter">
          <div class="row">
            <div class="custom-col-1 text-center">
              <el-button v-if="outline.locked" type="primary" @click="addTechniqueRow" icon="" disabled>Add Another Break</el-button>
              <el-button v-else type="primary" @click="addTechniqueRow" icon="">Add Another Break</el-button>
            </div>
            <div class="custom-col-1"></div>
            <div class="custom-col-1 text-center">
              <el-button type="primary" @click="generatePDF" icon="">Print Techniques</el-button>
            </div>
            <div class="custom-col-1"></div>
            <div class="custom-col-1"></div>
            <div class="custom-col-1"><span style="font-weight: bold">Total Boards:</span> {{ total_boards }}</div>
            <div class="custom-col-1"></div>
            <div class="custom-col-1"></div>
            <div class="custom-col-1"><span style="font-weight: bold">Possible Score:</span> {{ scores_objects.total_average_score }}</div>
          </div>
        </div>
        <!--end buttons at bottom of card-->

      </card>
      <!-- end of non scoring card view-->

      <!-- begin Scoring view card -->
      <card v-if="outline.scoring_view_checked" style="width: 90%; margin: auto; padding: 0px 10px">
        <!--Begin first row of scoring view-->
        <div class="row techniqueHeader pb-4">
          <div class="custom-lg-col-1 text-center">Order</div>
          <div class="custom-lg-col-2 text-center">Technique</div>
          <div class="custom-lg-col-1 text-center">Attempts</div>
          <div class="custom-lg-col-3 text-center">
            <div class="row">
              <div class="col-sm-3"><span class="verticaltext_content">Changed<br>Break</span>
              </div>
              <div class="col-sm-3"><span class="verticaltext_content">Dropped<br>Board</span>
              </div>
              <div class="col-sm-3"><span class="verticaltext_content">Partial<br>Break</span>
              </div>
              <div class="col-sm-3"><span class="verticaltext_content">Didn't<br>Break</span>
              </div>
            </div>
          </div>
          <div class="custom-lg-col-1 text-center">Possible<br>Score</div>
          <div class="custom-lg-col-1 text-center">Penalties</div>
          <div class="custom-lg-col-1 text-center">Technique<br>Score</div>
        </div>
        <!--End of first row of scoring view-->

        <br>

        <!--Begin first technique line row of scoring view-->
        <div class="row" v-for="technique of outline.breaking_techniques_to_outlines" :key="technique.id" style="padding: 15px 0">
          <div class="custom-lg-col-1 text-center">{{ technique.order }}</div>
          <div class="custom-lg-col-2 text-center">{{ technique.techName }}</div>
          <div class="custom-lg-col-1 text-center">
            <el-input type="number" :min="0" @input="setAModifiers"
                      v-model="technique.attempts"></el-input>
          </div>
          <div class="custom-lg-col-3 text-center">
            <div class="row">
              <div class="col-sm-3 text-center">
                <el-checkbox @input="setAModifiers" v-model="technique.changed_break" size="large" ></el-checkbox>
              </div>
              <div class="col-sm-3 text-center align-self-center">
                <el-input type="number" :min="0" @input="setAModifiers"
                          v-model="technique.dropped_a_board"></el-input>
              </div>
              <div class="col-sm-3 text-center">
                <el-checkbox @input="setAModifiers" v-model="technique.partial_break" size="large"></el-checkbox>
              </div>
              <div class="col-sm-3 text-center">
                <el-checkbox @input="setAModifiers" v-model="technique.didnt_break" size="large"></el-checkbox>
              </div>
            </div>
          </div>
          <div class="custom-lg-col-1 text-center">
            {{
              scores_objects[technique.id].line_score ? scores_objects[technique.id].line_score : 0
            }}
          </div>
          <div class="custom-lg-col-1 text-center">
            {{
              scores_objects[technique.id] ? scores_objects[technique.id].penalties : 0
            }}
          </div>
          <div class="custom-lg-col-1 text-center">
            {{
              scores_objects[technique.id] ? scores_objects[technique.id].total_score : 0
            }}
          </div>
        </div>
        <!--end line of technique scoring-->

        <!--Begin line scores row-->
        <div class="row pb-3">
          <div class="custom-lg-col-7"></div>
          <div class="custom-lg-col-1 text-center">
            {{ scores_objects.avg_total_line_scores }}
          </div>
          <div class="custom-lg-col-2"></div>
        </div>
        <!--end line scores row-->

        <!--begin last judge row-->
        <div class="techniqueFooter">
          <div class="row">
            <div class="custom-lg-col-1">
              <label for="J1">J1</label>
              <el-input type="number" @input="setAModifiers" v-model="outline.j1"
                        :min="0" id="J1"
                        placeholder="J1"></el-input>
            </div>
            <div class="custom-lg-col-1">
              <label for="J2">J2</label>
              <el-input type="number" @input="setAModifiers" v-model="outline.j2"
                        :min="0" id="J2"
                        placeholder="J2"></el-input>
            </div>
            <div class="custom-lg-col-1">
              <label for="J3">J3</label>
              <el-input type="number" @input="setAModifiers" v-model="outline.j3"
                        :min="0" id="J3"
                        placeholder="J3"></el-input>
            </div>
            <div class="custom-lg-col-1">
              <label for="J4">J4</label>
              <el-input type="number" @input="setAModifiers" v-model="outline.j4"
                        :min="0" id="J4"
                        placeholder="J4"></el-input>
            </div>
            <div class="custom-lg-col-1">
              <label for="J5">J5</label>
              <el-input type="number" @input="setAModifiers" v-model="outline.j5"
                        :min="0" id="J5"
                        placeholder="J5"></el-input>
            </div>
            <div class="custom-lg-col-1">
              <label for="time">Time</label>
              <el-input type="number" v-model="outline.time"
                        :min="0" id="time"
                        placeholder="Time"></el-input>
            </div>
            <div class="custom-lg-col-2">
              <div class="row">
                <div class="col-sm-12"></div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <label for="presentation">Presentation</label>
                  <el-input type="number"
                            v-model="presentation"
                            :min="0"
                            id="presentation"
                            placeholder="Presentation"></el-input>
                </div>
              </div>
            </div>
            <div class="custom-lg-col-1 text-center">
              <div class="row">
                <div class="col-sm-12"><span
                  style="font-weight: bold;">Technical<br>Score</span></div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  {{
                    scores_objects.final_technical_score
                  }}
                </div>
              </div>
            </div>
            <div class="custom-lg-col-1 text-center">
              <div class="row">
                <div class="col-sm-12"><span
                  style="font-weight: bold;">Final<br>Score</span></div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  {{
                    scores_objects.final_score
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </card>
      <!-- end of scoring view card -->

      <!-- start of footer items-->
      <span slot="footer" class="dialog-footer">
        <div class="row">
          <div class="col-sm-2">
            <el-button v-if="outline.locked" type="success" @click="saveTechniqueToOutline('okmodal')" icon="" disabled>Save</el-button>
            <el-button v-else type="success" @click="saveTechniqueToOutline('okmodal')" icon="">Save</el-button>
          </div>
          <div class="col-sm-2">
            <el-button type="primary" @click="assignJudges" icon="">Assign Judges</el-button>
          </div>
          <div class="col-sm-3">
          </div>
          <div class="col-sm-3">
            <el-button v-if="scoring_view_checked" type="primary" @click="printScoreView" icon="">Print Score View</el-button>
          </div>
          <div class="col-sm-2 text-right">
            <el-button type="danger" @click="closeCustomModal('custom')" icon="">Cancel</el-button>
          </div>
        </div>
    </span>
    </el-dialog>
    <!-- end of primary dialog -->

    <el-dialog
      center
      :before-close="handleClose"
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :before-close="handleClose"
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :before-close="handleClose"
      title="Save Complete"
      :visible.sync="modals.okmodal">
      <div class="text-center">
        <span>Outline Saved</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('okmodal')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>

    <el-dialog
      center
      :before-close="handleClose"
      title="Assign Judges"
      :visible.sync="modals.judges">
      <div class="row">
        <div class="col-sm-6"><label>Judge 1</label></div>
        <div class="col-sm-6">
          <el-select v-model="judge1" placeholder="Select Judge 1" style="width: 100%"
                     @input="selectJudge(1)">
            <el-option v-for="item in judgelist1"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6"><label>Judge 2</label></div>
        <div class="col-sm-6">
          <el-select v-model="judge2" placeholder="Select Judge 2" style="width: 100%"
                     @input="selectJudge(2)">
            <el-option v-for="item in judgelist2"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6"><label>Judge 3</label></div>
        <div class="col-sm-6">
          <el-select v-model="judge3" placeholder="Select Judge 3" style="width: 100%"
                     @input="selectJudge(3)">
            <el-option v-for="item in judgelist3"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6"><label>Judge 4</label></div>
        <div class="col-sm-6">
          <el-select v-model="judge4" placeholder="Select Judge 4" style="width: 100%"
                     @input="selectJudge(4)">
            <el-option v-for="item in judgelist4"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6"><label>Judge 5</label></div>
        <div class="col-sm-6">
          <el-select v-model="judge5" placeholder="Select Judge 5" style="width: 100%"
                     @input="selectJudge(5)">
            <el-option v-for="item in judgelist5"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveJudges('judges')" icon="">Save Judges</el-button>
        <el-button type="danger" @click="closeModal('judges')" icon="">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import OutlinesAPIService from "src/servicehandlers/OutlinesAPIService";
import RanksAPIService from "src/servicehandlers/RanksAPIService";
import PenaltiesAPIService from "src/servicehandlers/PenaltiesAPIService";
import BoardsAPIService from "src/servicehandlers/BoardsAPIService";
import UsersAPIService from "src/servicehandlers/UsersAPIService";
import DirectionsAPIService from "src/servicehandlers/DirectionsAPIService";
import BreakingTechniqueToOutlineAPIService
  from "src/servicehandlers/BreakingTechniqueToOutlineAPIService";
import JudgeAdminAPIService from "src/servicehandlers/JudgeAdminAPIService";
import BreakingTechniquesAPIService from "src/servicehandlers/BreakingTechniquesAPIService";
import ModifiersAPIService from "src/servicehandlers/ModifiersAPIService";
import SupportOptionsAPIService from "src/servicehandlers/SupportOptionsAPIService";
import { Dialog, Option, Select, Input, Checkbox, Button } from 'element-ui';

const ranksAPIService = new RanksAPIService();
const boardsAPIService = new BoardsAPIService();
const usersAPIService = new UsersAPIService();
const techniquesAPIService = new BreakingTechniquesAPIService();
const directionsAPIService = new DirectionsAPIService();
const modifiersAPIService = new ModifiersAPIService();
const penaltiesAPIService = new PenaltiesAPIService();
const outlineAPIService = new OutlinesAPIService();
const supportOptionsAPIService = new SupportOptionsAPIService();
const techniqueToOutlineAPIService = new BreakingTechniqueToOutlineAPIService();
const judgeAdminAPIService = new JudgeAdminAPIService();

export default {
  name: "Outline",
  components: {
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  props: {
    outline_id: Number,
  },
  data() {
    return {
      scoring_view_checked: false,
      judge1: null,
      judge2: null,
      judge3: null,
      judge4: null,
      judge5: null,
      judgelist1: [],
      judgelist2: [],
      judgelist3: [],
      judgelist4: [],
      judgelist5: [],
      outline: {
        bbt_reqs_checked: false,
        was_bbt: false,
        locked: false,
        scoring_view_checked: false,
        breaking_techniques_to_outlines: [],
        testing_reqs_checked: false,
        weight: 99999,
      },

      // all dropdown items right here
      dropdown_ranks: [],
      dropdown_support_options: [],
      dropdown_boards: [],
      dropdown_directions: [],
      dropdown_techniques: [],
      checkbox_modifiers: [],
      board_techniques: [],
      board_technique_select_options: [],
      users: [],

      // storage objects to hold values in prior to calculating scores
      support_option_objects: {},
      rank_requirements: {},
      technique_objects: {},
      direction_objects: {},
      modifier_objects: {},
      board_size_objects: {},
      board_objects: {
        additional_boards: 0
      },
      penalties_objects: {},
      scores_objects: {},
      user: {},

      // score variables
      total_boards: 0,
      total_speed_breaks: 0,
      total_power_breaks: 0,

      canSave: true,
      presentation: null,
      outline_rank: null,
      error_txt: null,
      success_txt: null,
      fullName: null,
      allDirections: null,
      userClass: null,
      techniqueTableColumns: ['', 'Technique', 'Direction', 'Board Size', 'Num Boards', 'Add Boards'],

      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false,
        delete: false,
        outline: false,
        okmodal: false,
        success: false,
        error: false,
        judges: false,
      },
    }
  },
  computed: {
    breaking_minimum_requirements() {
      let output = "<card class='"
      if (this.scores_objects.total_average_score >= this.rank_requirements.breaking_point_minimum) {
        output += "met'>"
      } else {
        output += "unmet'>"
      }

      output += "       <div class=\"row\">\n" +
        "                  <div class=\"col-sm-12 text-center\">\n" +
        "                    Minimum Possible Score: " + this.rank_requirements.breaking_point_minimum + "\n" +
        "                  </div>\n" +
        "                </div>\n" +
        "                <div class=\"row\">\n" +
        "                  <div class=\"col-sm-12 text-center\">\n" +
        "                    <span class=\"fraction\">\n" +
        "                      <div class=\"numerator\">" + this.scores_objects.total_average_score + "</div>\n" +
        "                      <div class=\"denominator\">" + this.rank_requirements.breaking_point_minimum + "</div>\n" +
        "                    </span>\n" +
        "                  </div>\n" +
        "                </div>\n" +
        "              </card>"

      return output
    },
    rank_to_outline_requirements() {
      let output = "<card class='"
      if (this.outline
        && this.outline.breaking_techniques_to_outlines
        && this.outline.breaking_techniques_to_outlines.length > 0
        && this.outline.breaking_techniques_to_outlines.length === this.rank_requirements.number_of_breaks) {
        output += "met'>"
      } else {
        output += "unmet'>"
      }

      output += "       <div class=\"row\">\n" +
        "                  <div class=\"col-sm-12 text-center\">\n" +
        "                    Required Number of Breaks: " + this.rank_requirements.number_of_breaks + "\n" +
        "                  </div>\n" +
        "                </div>\n" +
        "                <div class=\"row\">\n" +
        "                  <div class=\"col-sm-12 text-center\">\n" +
        "                    <span class=\"fraction\">\n" +
        "                      <div class=\"numerator\">" + this.outline.breaking_techniques_to_outlines.length + "</div>\n" +
        "                      <div class=\"denominator\">" + this.rank_requirements.number_of_breaks + "</div>\n" +
        "                    </span>\n" +
        "                  </div>\n" +
        "                </div>\n" +
        "              </card>"

      return output
    },
    speed_break_requirements() {
      let output = "<card class='"
      if (this.total_speed_breaks >= this.rank_requirements.required_speed_breaks) {
        output += "met'>"
      } else {
        output += "unmet'>"
      }

      output += "       <div class=\"row\">\n" +
        "                  <div class=\"col-sm-12 text-center\">\n" +
        "                    Required Speed Breaks: " + this.rank_requirements.required_speed_breaks + "\n" +
        "                  </div>\n" +
        "                </div>\n" +
        "                <div class=\"row\">\n" +
        "                  <div class=\"col-sm-12 text-center\">\n" +
        "                    <span class=\"fraction\">\n" +
        "                      <div class=\"numerator\">" + this.total_speed_breaks + "</div>\n" +
        "                      <div class=\"denominator\">" + this.rank_requirements.required_speed_breaks + "</div>\n" +
        "                    </span>\n" +
        "                  </div>\n" +
        "                </div>\n"
      if (!this.outline.scoring_view_checked) {
        output += "                <div class=\"col-sm-12 text-center\">" +
          "                 (Speed Breaks are outlined in <span" +
          "                       style=\"font-weight: bold; color: green\">GREEN</span>)" +
          "                </div>"
      }
      output += "              </card>"

      return output
    },
    power_break_requirements() {
      let output = "<card class='"
      if (this.total_power_breaks >= this.rank_requirements.required_power_breaks) {
        output += "met'>"
      } else {
        output += "unmet'>"
      }

      output += "       <div class=\"row\">\n" +
        "                  <div class=\"col-sm-12 text-center\">\n" +
        "                    Required Power Breaks: " + this.rank_requirements.required_power_breaks + "\n" +
        "                  </div>\n" +
        "                </div>\n" +
        "                <div class=\"row\">\n" +
        "                  <div class=\"col-sm-12 text-center\">\n" +
        "                    <span class=\"fraction\">\n" +
        "                      <div class=\"numerator\">" + this.total_power_breaks + "</div>\n" +
        "                      <div class=\"denominator\">" + this.rank_requirements.required_power_breaks + "</div>\n" +
        "                    </span>\n" +
        "                  </div>\n" +
        "                </div>\n"
      if (!this.outline.scoring_view_checked) {
        output += "                <div class=\"col-sm-12 text-center\">" +
          "                 (Power Breaks are outlined in <span" +
          "                       style=\"font-weight: bold; color: blue\">BLUE</span>)" +
          "                </div>"
      }
      output += "              </card>"

      return output
    },
    requirements_output() {
      return this.rank_to_outline_requirements + this.breaking_minimum_requirements + this.speed_break_requirements + this.power_break_requirements
    }
  },
  methods: {
    selectJudge(judgeChanged) {
      // depending on which judge is selected, remove the other judges or disable them if not selected
      if (judgeChanged === 1) {
        if (this.judge1 === this.judge2) {
          this.judge2 = null;
        }
        if (this.judge1 === this.judge3) {
          this.judge3 = null;
        }
        if (this.judge1 === this.judge4) {
          this.judge4 = null;
        }
        if (this.judge1 === this.judge5) {
          this.judge5 = null;
        }
      }
      if (judgeChanged === 2) {
        if (this.judge2 === this.judge1) {
          this.judge1 = null;
        }
        if (this.judge2 === this.judge3) {
          this.judge3 = null;
        }
        if (this.judge2 === this.judge4) {
          this.judge4 = null;
        }
        if (this.judge2 === this.judge5) {
          this.judge5 = null;
        }
      }
      if (judgeChanged === 3) {
        if (this.judge3 === this.judge1) {
          this.judge1 = null;
        }
        if (this.judge3 === this.judge2) {
          this.judge2 = null;
        }
        if (this.judge3 === this.judge4) {
          this.judge4 = null;
        }
        if (this.judge3 === this.judge5) {
          this.judge5 = null;
        }
      }
      if (judgeChanged === 4) {
        if (this.judge4 === this.judge1) {
          this.judge1 = null;
        }
        if (this.judge4 === this.judge2) {
          this.judge2 = null;
        }
        if (this.judge4 === this.judge3) {
          this.judge3 = null;
        }
        if (this.judge4 === this.judge5) {
          this.judge5 = null;
        }
      }
      if (judgeChanged === 5) {
        if (this.judge5 === this.judge1) {
          this.judge1 = null;
        }
        if (this.judge5 === this.judge2) {
          this.judge2 = null;
        }
        if (this.judge5 === this.judge3) {
          this.judge3 = null;
        }
        if (this.judge5 === this.judge4) {
          this.judge4 = null;
        }
      }
    },
    async getUsers() {
      return usersAPIService.getUserList(this.$router)
      .then((users) => {
        for (const user of users) {
          user.value = user.id;
          user.text = user.first_name + ' ' + user.last_name;
        }
        this.users = users;
        this.judgelist1 = users;
        this.judgelist2 = users;
        this.judgelist3 = users;
        this.judgelist4 = users;
        this.judgelist5 = users;
      })
      .catch((error) => {
        this.error_txt = 'Error getting users: ' + error;
        this.openModal('error');
      });
    },
    saveJudges: function (name) {
      // update the judges in the judgeadmin table with these judges that are in the current outline
      // do not update if null
      const updateParms = {
        current_outline: this.outline_id
      };
      if (this.judge1) {
        updateParms.judge1 = this.judge1;
      }
      if (this.judge2) {
        updateParms.judge2 = this.judge2;
      }
      if (this.judge3) {
        updateParms.judge3 = this.judge3;
      }
      if (this.judge4) {
        updateParms.judge4 = this.judge4;
      }
      if (this.judge5) {
        updateParms.judge5 = this.judge5;
      }
      // NOTE: hardcoded 1 should be the only entry in the judges table
      return judgeAdminAPIService.updateJudgeAdmin(1, updateParms, this.$router)
      .then(() => {
        this.outline.j1 = this.judge1;
        this.outline.j2 = this.judge2;
        this.outline.j3 = this.judge3;
        this.outline.j4 = this.judge4;
        this.outline.j5 = this.judge5;
        const outline = {
          j1: this.judge1,
          j2: this.judge2,
          j3: this.judge3,
          j4: this.judge4,
          j5: this.judge5,
        };
        return outlineAPIService.updateOutline(this.outline_id, outline, this.$router);
      })
      .then(() => {
        this.closeModal(name);
        const self = this;
        this.success_txt = 'Judges Saved successfully';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error updating current judges: ' + error;
        this.openModal('error');
      });
    },
    assignJudges() {
      // set up judge1 through 5, if null, don't set em
      return judgeAdminAPIService.getCurrentOutline(this.$router)
      .then((currentJudges) => {
        this.judge1 = currentJudges.judge1;
        this.judge2 = currentJudges.judge2;
        this.judge3 = currentJudges.judge3;
        this.judge4 = currentJudges.judge4;
        this.judge5 = currentJudges.judge5;

        if (this.outline.j1) {
          this.judge1 = this.outline.j1;
        }
        if (this.outline.j2) {
          this.judge2 = this.outline.j2;
        }
        if (this.outline.j3) {
          this.judge3 = this.outline.j3;
        }
        if (this.outline.j4) {
          this.judge4 = this.outline.j4;
        }
        if (this.outline.j5) {
          this.judge5 = this.outline.j5;
        }
        this.openModal('judges');
      })
      .catch((error) => {
        this.error_txt = 'Error getting current judges: ' + error;
        this.openModal('error');
      });
    },
    onEnd() {
      for (let i = 0; i < this.outline.breaking_techniques_to_outlines.length; i += 1) {
        const id = this.outline.breaking_techniques_to_outlines[i].id;
        this.outline.breaking_techniques_to_outlines[i].order = i + 1;
      }
    },
    // get the penalties used in equations
    async getPenalties() {
      return penaltiesAPIService.getPenalties(this.$router)
      .then((penalties) => {
        this.penalties = penalties;
      })
      .catch((error) => {
        this.error_txt = 'Error getting penalties: ' + error;
        this.openModal('error');
      });
    },

    // get the boards used in all dropdowns
    async getBoards() {
      return boardsAPIService.getBoards(this.$router)
      .then((boards) => {
        for (let i = 0; i < boards.length; i += 1) {
          boards[i].text = boards[i].name;
          boards[i].value = boards[i].id;
        }
        const tmpBoard = {
          value: null,
          text: 'N/A',
          id: null,
          name: 'N/A'
        };
        boards.unshift(tmpBoard);
        this.dropdown_boards = boards;
      })
      .catch((error) => {
        this.error_txt = 'Error getting boards for dropdown: ' + error;
        this.openModal('error');
      });
    },

    // gets the current outline data.  We need it to loop through and to save
    async getCurrentOutline(outline_id) {
      return outlineAPIService.getOutlineByID(outline_id, this.$router)
      .then((outline) => {
        const tempDir = {
          id: null,
          name: 'Pls Select',
          value: null,
          text: 'Pls Select',
          a_minus: 0,
          additional_point_value: 0,
        };
        this.outline = outline;
        this.scoring_view_checked = outline.scoring_view_checked;
        // get a direction from the DB for each outline pulled
        for (const technique of this.outline.breaking_techniques_to_outlines) {
          if (technique.breaking_technique === null) {
            technique.directions = [];
            technique.directions.push(tempDir);
          } else {
            techniquesAPIService.getBreakingTechniqueWithDirections(technique.breaking_technique_id, this.$router)
            .then((directions) => {
              for (const dir of directions.directions) {
                dir.value = dir.id;
                dir.text = dir.name;
              }
              technique.breaking_technique.directions = directions.directions;
              technique.breaking_technique.directions.unshift(tempDir);
              technique.prior_num_boards = parseInt(technique.num_boards);
              const rights = this.$store.getters.scopes;
              for (let i = 0; i < rights.length; i += 1) {
                if (rights[i].name === 'edit-all-events') {
                  this.outline.locked = false; // disable the save button until a technique is selected
                }
              }
            })
            .catch((error) => {
              this.error_txt = 'Error getting directions for technique: ' + error;
              this.openModal('error');
            });
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting outline from DB: ' + error;
        this.openModal('error');
      });
    },

    // get the full list of directions so it'll loop way better and cleaner
    async getAllDirections() {
      return directionsAPIService.getAllDirections(this.$router)
      .then((directions) => {
        for (const dir of directions) {
          dir.text = dir.name;
          dir.value = dir.id;
        }
        this.allDirections = directions;
      })
      .catch((error) => {
        this.error_txt = 'Error getting directions: ' + error;
        this.openModal('error');
      });
    },

    // get all the directions used in dropdowns
    async getDirections() {
      return directionsAPIService.getDirections(this.$router)
      .then((directions) => {
        for (const dir of directions) {
          dir.text = dir.name;
          dir.value = dir.id;
        }
        this.dropdown_directions = directions;
      })
      .catch((error) => {
        this.error_txt = 'Error getting directions for dropdown: ' + error;
        this.openModal('error');
      });
    },

    // get all the techniques used in dropdowns
    async getTechniques() {
      return techniquesAPIService.getBreakingTechniques(this.$router)
      .then((techniques) => {
        for (const technique of techniques) {
          technique.text = technique.name;
          technique.value = technique.id;
        }
        const tmpTechnique = {
          text: '-------',
          value: null,
          id: null,
          name: '-------',
        };
        techniques.unshift(tmpTechnique);
        this.dropdown_techniques = techniques;
      })
      .catch((error) => {
        this.error_txt = 'Error getting techniques for dropdown: ' + error;
        this.openModal('error');
      });
    },

    // get all the ranks used in dropdowns
    async getRanks() {
      return ranksAPIService.getRanks(this.$router)
      .then((ranks) => {
        this.dropdown_ranks = ranks;
        for (const rank of this.dropdown_ranks) {
          rank.text = rank.name;
          rank.value = rank.id;
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting ranks for dropdown: ' + error;
        this.openModal('error');
      });
    },

    async getSupportOptions() {
      return supportOptionsAPIService.getSupportOptions(this.$router)
      .then((modifiers) => {
        for (const modifier of modifiers) {
          modifier.text = modifier.description;
          modifier.value = modifier.id;
          this.dropdown_support_options.push(modifier);
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting modifiers for dropdown: ' + error;
        this.openModal('error');
      });
    },

    // get al the modifiers used in dropdowns
    async getModifiers() {
      return modifiersAPIService.getModifiers(this.$router)
      .then((modifiers) => {
        for (const modifier of modifiers) {
          modifier.text = modifier.description;
          modifier.value = modifier.id;
          this.checkbox_modifiers.push(modifier);
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting modifiers for dropdown: ' + error;
        this.openModal('error');
      });
    },

    refresh() {
    },

    closestMultipleUp(n, x) {
      if (x > n)
        return x;
      n = n + parseInt(x / 2, 10);
      n = n - (n % x);
      return n;
    },

    closestMultipleDown(n, x) {
      if (x > n)
        return x;
      n = n - parseInt(x / 2, 10);
      n = n - (n % x);
      return n;
    },

    boardChange(technique) {
      const num_boards = parseInt(technique.num_boards);
      const prior_boards = parseInt(technique.prior_num_boards);
      const board_multiplier = parseInt(technique.breaking_technique.board_multiplier);
      if (num_boards > prior_boards) {
        technique.num_boards = this.closestMultipleUp(num_boards, board_multiplier);
      } else {
        technique.num_boards = this.closestMultipleDown(num_boards, board_multiplier);
      }
      technique.prior_num_boards = num_boards;
      this.setAModifiers();
    },

    reorderOutline() {
      // reorders the outline according to the order number
      this.outline.breaking_techniques_to_outlines.sort((a, b) => (a.order > b.order) ? 1 : -1);
    },

    // sets the technique name for the scoring view
    async setTechniqueNames() {
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const techItem of this.outline.breaking_techniques_to_outlines) {
          techItem.techName = '';
          for (const modifier of this.dropdown_support_options) {
            if (modifier.id === techItem.dropdown_modifier_id) {
              techItem.techName += modifier.description + ' ';
            }
          }
          if (techItem.breaking_technique && techItem.breaking_technique.name) {
            techItem.techName += techItem.breaking_technique.name + ', ';
          }
          if (techItem.breaking_technique && techItem.breaking_technique.directions) {
            for (const techdir of techItem.breaking_technique.directions) {
              if (techdir && techdir.id !== null && techdir.id === techItem.direction_id) {
                if (techdir.name !== 'N/A') {
                  techItem.techName += techdir.name + ', ';
                }
              }
            }
          }
          if (techItem.blindfolded) {
            techItem.techName += 'Blindfolded, ';
          }
          if (techItem.self_held) {
            techItem.techName += 'Self Held, ';
          }
          if (techItem.simultaneous) {
            techItem.techName += 'Simultaneous, ';
          }
          if (techItem.successive) {
            techItem.techName += 'Successive, ';
          }
          if (techItem.no_spacers) {
            techItem.techName += 'No Spacers, ';
          }
          techItem.techName = techItem.techName.replace(/,\s*$/, "");
          if (techItem.additional_boards === 1) {
            techItem.techName += ' 1 Additional Board';
          } else if (techItem.additional_boards > 1) {
            techItem.techName += techItem.additional_boards + ' Additional Boards';
          }
        }
      }
    },

    // pulls all the items needed to process everything
    async getMountedItems(outline_id) {
      await this.initializePage();
      await this.initializeScores();
      this.board_techniques = [];
      this.board_technique_select_options = [];
      this.dropdown_ranks = [];
      this.dropdown_modifiers = [];
      this.dropdown_boards = [];
      this.dropdown_directions = [];
      this.dropdown_techniques = [];
      this.checkbox_modifiers = [];
      this.outline = {};
      await this.getUsers();
      await this.getRanks();
      await this.getModifiers();
      await this.getAllDirections();
      await this.getDirections();
      await this.getSupportOptions();
      await this.getBoards();
      await this.getPenalties();
      await this.getTechniques();
      await this.getCurrentOutline(outline_id);
      await this.setUserClass();
      this.user = this.outline.user;
      await this.setTechniqueNames();
      await this.setAModifiers();
      if (this.outline && this.outline.user && this.outline.user.first_name) {
        this.fullName = this.outline.user.first_name + ' ' + this.outline.user.last_name;
      } else {
        this.fullName = '';
      }
      if (!this.outline.rank_id) {
        this.outline.rank_id = this.user.rank_id;
      }
    },
    async setBBTReqs() {
      this.total_power_breaks = 0
      for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
        let technique_id = null;
        if (technique_to_outline.breaking_technique_id) {
          technique_id = technique_to_outline.breaking_technique_id;
        }
        let board_id = null;
        if (technique_to_outline.board_id) {
          board_id = technique_to_outline.board_id;
        }
        // first get the board size of the technique_to_outline
        let board_size = 0;
        for (const board of this.dropdown_boards) {
          if (board.id === board_id) {
            board_size = parseInt(board.order);
          }
        }

        let possible_power_break = false;
        for (const technique of this.dropdown_techniques) {
          if (technique_id === technique.id) {
            if (technique.is_power_break) {
              possible_power_break = true;
            }
          }
        }

        // this.board_objects[technique_to_outline.id].additional_boards = (num_boards / board_multiplier) - 1;
        // order - weight class is if it's a power break or not
        if (possible_power_break) {
          let power_break_num = board_size - this.userClass;
          if (power_break_num >= 2) {
            // yay, power break!
            this.total_power_breaks += 1;
            technique_to_outline.color = 'text-center border border-primary';
          } else if (power_break_num >= 0) {
            technique_to_outline.color = 'text-center';
            if (this.board_objects[technique_to_outline.id].additional_boards >= 1) {
              this.total_power_breaks += 1;
              technique_to_outline.color = 'text-center border border-primary';
            }
          } else if (power_break_num < 0) {
            power_break_num += (this.scores_objects[technique_to_outline.id].a_minus +
              this.technique_objects[technique_to_outline.id].a_minus);
            if (power_break_num < 0) {
              technique_to_outline.color = 'text-center border border-warning';
            } else {
              technique_to_outline.color = 'text-center';
            }
          } else {
            technique_to_outline.color = 'text-center';
          }
        }
      }

    },

    editOutline(name, outline_id) {
      this.getMountedItems(outline_id);
      this.modals[name] = true;
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      document.body.classList.remove('off-canvas-sidebar');
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    closeCustomModal(name) {
      this.board_techniques = [];
      this.board_technique_select_options = [];
      this.dropdown_ranks = [];
      this.dropdown_modifiers = [];
      this.dropdown_boards = [];
      this.dropdown_directions = [];
      this.dropdown_techniques = [];
      this.checkbox_modifiers = [];
      this.scores = {};
      this.outline = {};
      this.modals[name] = false;
    },

    printScoreView() {
      const doc = new jsPDF({
        orientation: "landscape",
        format: 'letter',
      });
      return outlineAPIService.getBase64File('score_view_items.png', this.$router)
      .then((response) => {
        const imgData = response;
        let x = 118;
        let y = 10;
        doc.setFontSize('20');
        doc.text(this.outline.description, x, y);
        x = 10;
        y += 30;

        doc.setFontSize('12');
        doc.text(this.outline.user.first_name + ' ' + this.outline.user.last_name + '-' + this.outline.weight + ' lbs,  Weight Class ' + scores_objects.userClass, 10, 10);

        x = 10;
        y = 40;

        doc.setFontSize('8');
        // set each technique column up, one by one
        doc.text('Order', x, y);
        x += 17;
        doc.text('Technique', x, y);
        x += 85;
        doc.text('Attempts', x, y);
        x += 15;
        doc.addImage(imgData, 'PNG', x, 22, 50, 20);
        x += 65;
        doc.text('Possible Score', x, y);
        x += 25;
        doc.text('Penalties', x, y);
        x += 25;
        doc.text('Technical Score', x, y);
        x += 17;
        // doc.text('Final Score', x, y);
        // x += 17;

        for (const technique of this.outline.breaking_techniques_to_outlines) {
          x = 10;
          y += 10;
          doc.text(technique.order.toString(), x, y);
          x += 17;
          doc.text(technique.techName.toString(), x, y);
          x += 85;
          doc.text(technique.attempts.toString(), x, y);
          x += 17;
          doc.rect(x, y - 3, 5, 5);
          if (technique.changed_break) {
            doc.text('X', x + 2, y + 1);
          }
          x += 13;
          doc.rect(x, y - 3, 5, 5);
          if (technique.dropped_a_board) {
            doc.text('X', x + 2, y + 1);
          }
          x += 13;
          doc.rect(x, y - 3, 5, 5);
          if (technique.partial_break) {
            doc.text('X', x + 2, y + 1);
          }
          x += 13;
          doc.rect(x, y - 3, 5, 5);
          if (technique.didnt_break) {
            doc.text('X', x + 2, y + 1);
          }
          x += 25;

          doc.text(this.scores_objects[technique.id].line_score.toString(), x, y);
          x += 25;
          doc.text(this.scores_objects[technique.id].penalties.toString(), x, y);
          x += 25;
          doc.text(this.scores_objects[technique.id].total_score.toString(), x, y);
          //x += 17;
          // doc.text(this.scores_objects[technique.id].total_score.toString(), x, y);
        }
        y += 15;
        x = 40;

        // second part, totals
        doc.text('J1', x, y);
        x += 15;
        doc.text('J2', x, y);
        x += 15;
        doc.text('J3', x, y);
        x += 15;
        doc.text('J4', x, y);
        x += 15;
        doc.text('J5', x, y);
        x += 15;
        doc.text('Time', x, y);
        x += 15;
        doc.text('Presentation', x, y);
        x += 45;
        doc.text('Technical Score', x, y);
        x += 25;
        doc.text('Final Score', x, y);

        y += 10;
        x = 40;

        if (!this.outline.j1) {
          this.outline.j1 = 0;
        }
        if (!this.outline.j2) {
          this.outline.j2 = 0;
        }
        if (!this.outline.j3) {
          this.outline.j3 = 0;
        }
        if (!this.outline.j4) {
          this.outline.j4 = 0;
        }
        if (!this.outline.j5) {
          this.outline.j5 = 0;
        }
        doc.text(this.outline.j1.toString(), x, y);
        x += 15;
        doc.text(this.outline.j2.toString(), x, y);
        x += 15;
        doc.text(this.outline.j3.toString(), x, y);
        x += 15;
        doc.text(this.outline.j4.toString(), x, y);
        x += 15;
        doc.text(this.outline.j5.toString(), x, y);
        x += 15;
        if (!this.outline.time) {
          this.outline.time = 0;
        }
        doc.text(this.outline.time.toString(), x, y);
        x += 15;
        if (!this.presentation) {
          this.presentation = 0;
        }
        doc.text(this.presentation.toString(), x, y);
        x += 45;
        if (!this.scores_objects.total_score) {
          this.scores_objects.total_score = 0;
        }
        doc.text(this.scores_objects.final_technical_score.toString(), x, y);
        x += 25;
        if (!this.scores_objects.total_score) {
          this.scores_objects.total_score = 0;
        }
        doc.text(this.scores_objects.total_average_score.toString(), x, y);

        // now add the headers
        doc.save("scoreview.pdf");
      })
      .catch((error) => {
        this.error_txt = 'Error adding row: ' + error;
        this.openModal('error');
      });
    },

    generatePDF() {
      const doc = new jsPDF({
        orientation: "landscape",
        format: 'letter',
      });

      // get the file as a base 64 image
      outlineAPIService.getBase64File('pdf_titles.png', this.$router)
      .then((response) => {
        // outline.technique_to_outlines
        const imgData = response;
        let x = 118;
        let y = 10;
        doc.text(this.outline.description, x, y);
        x = 10;
        y += 30;

        doc.setFontSize('12');
        doc.text(this.outline.weight + ' lbs,  Weight Class ' + this.userClass, 10, 10);

        y += 20;

        doc.setFontSize('8');
        // set each technique column up, one by one
        y = 40;
        doc.text('Order', x, y);
        x += 17;
        doc.text('Technique', x, y);
        x += 90;
        doc.text('Board Size', x, y);
        x += 23;
        doc.text('# of Boards', x, y);
        x += 23;
        y = 10;
        doc.addImage(imgData, 'PNG', x, y, 60, 30);
        y = 40;
        x += 66;
        doc.text('Score', x, y);

        for (const technique of this.outline.breaking_techniques_to_outlines) {
          const techniqueDirections = technique.breaking_technique.directions;
          const direction_id = technique.direction_id;
          const board_id = technique.board_id;
          const modifier_id = technique.dropdown_modifier_id;
          x = 10;
          y += 10;
          doc.text(technique.order.toString(), x, y);
          x += 17;
          let technique_string = '';
          for (const modifier of this.dropdown_support_options) {
            if (modifier.id === modifier_id) {
              technique_string += modifier.description + " ";
              break;
            }
          }
          technique_string += technique.breaking_technique.name + " ";

          for (const dir of techniqueDirections) {
            if (dir.id === direction_id) {
              if (dir.id !== null && dir.name !== "N/A") {
                technique_string += dir.name + " ";
              }
              break;
            }
          }
          doc.text(technique_string, x, y);
          x += 90;

          for (const board of this.dropdown_boards) {
            if (board.id === board_id) {
              doc.text(board.name, x, y);
              break;
            }
          }
          x += 23;

          doc.text(technique.num_boards.toString(), x, y);
          x += 24;

          doc.rect(x, y - 3, 5, 5);
          if (technique.self_held) {
            doc.text('X', x + 2, y + 1);
          }
          x += 10;

          doc.rect(x, y - 3, 5, 5);
          if (technique.blindfolded) {
            doc.text('X', x + 2, y + 1);
          }
          x += 10;

          doc.rect(x, y - 3, 5, 5);
          if (technique.simultaneous) {
            doc.text('X', x + 2, y + 1);
          }
          x += 12;

          doc.rect(x, y - 3, 5, 5);
          if (technique.successive) {
            doc.text('X', x + 2, y + 1);
          }
          x += 12;

          doc.rect(x, y - 3, 5, 5);
          if (technique.no_spacers) {
            doc.text('X', x + 2, y + 1);
          }
          x += 23;

          // print the score out
          doc.text(this.scores_objects[technique.id].line_score.toString(), x, y);
        }

        // now put the totals in at the bottom
        // start with total boards
        y += 10;
        x = 10 + 17 + 75 + 23;
        doc.text("Total Boards: " + this.total_boards.toString(), x, y);
        x += 24 + 10 + 10 + 12 + 12 + 11 + 12;
        doc.text('Possible Score: ' + this.scores_objects.total_average_score.toString(), x, y);

        y += 10;
        x = 10;

        const boards = {};
        doc.text('Total Board Breakdown: ', x, y);
        for (const board of this.dropdown_boards) {
          boards[board.id] = {
            name: board.name,
            total: 0,
          };
        }
        y += 6;
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          // first get the number of the board, add all them together
          boards[technique_to_outline.board_id].total += parseInt(technique_to_outline.num_boards);
        }
        const keys = Object.keys(boards);
        for (const key of keys) {
          const board = boards[key];
          if (board.name !== 'N/A') {
            x = 10;
            doc.text(board.name + 's - ' + board.total.toString(), x, y);
            y += 6;
          }
        }
        x += 24;

        doc.save("techniques.pdf");

      })
      .catch((error) => {
        this.error_txt = 'Error adding row: ' + error;
        this.openModal('error');
      });
    },

    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    async setUserClass() {
      this.userClass = 6;
      if (!this.user.weight) {
        this.user.weight = 99999;
      }
      if (!this.outline.weight) {
        this.outline.weight = parseFloat(this.user.weight);
      }
      if (this.outline.weight >= 35 && this.outline.weight <= 49.9) {
        this.userClass = 1;
      } else if (this.outline.weight >= 50 && this.outline.weight <= 70.5) {
        this.userClass = 2;
      } else if (this.outline.weight >= 70.6 && this.outline.weight <= 96.6) {
        this.userClass = 3;
      } else if (this.outline.weight >= 96.7 && this.outline.weight <= 127.5) {
        this.userClass = 4;
      } else if (this.outline.weight >= 127.6 && this.outline.weight <= 164.9) {
        this.userClass = 5;
      } else if (this.outline.weight >= 165) {
        this.userClass = 6;
      } else {
        this.userClass = 6;
      }
    },

    async changeWeightClass() {
      this.spinning = true;
      if (this.outline.id) {
        const parms = {
          weight: this.outline.weight,
        };
        return outlineAPIService.updateSingleOutline(this.outline.id, parms, this.$router)
        .then(() => {
          // update the actual user weight too
          const userId = this.$store.getters.userId;
          return usersAPIService.updateUser(userId, parms, this.$router);
        })
        .then(() => {
          this.setUserClass();
          this.setAModifiers();
          this.spinning = false;
        })
        .catch((error) => {
          this.error_txt = 'Error while changing weight ' + error;
          this.openModal('error');
          this.spinning = false;
        });
      }
    },

    async setPresentation() {
      this.presentation = 0;
      const judgeScores = [];
      let allJudges = true;
      const j1 = parseFloat(this.outline.j1);
      const j2 = parseFloat(this.outline.j2);
      const j3 = parseFloat(this.outline.j3);
      const j4 = parseFloat(this.outline.j4);
      const j5 = parseFloat(this.outline.j5);

      if (j1 > 0) {
        judgeScores.push(j1);
      } else {
        allJudges = false;
      }
      if (j2 > 0) {
        judgeScores.push(j2);
      } else {
        allJudges = false;
      }
      if (j3 > 0) {
        judgeScores.push(j3);
      } else {
        allJudges = false;
      }
      if (j4 > 0) {
        judgeScores.push(j4);
      } else {
        allJudges = false;
      }
      if (j5 > 0) {
        judgeScores.push(j5);
      } else {
        allJudges = false;
      }

      if (allJudges) {
        const min = Math.min(...judgeScores);
        const max = Math.max(...judgeScores);
        let min_dropped = false;
        let max_dropped = false;
        for (let i = 0; i < judgeScores.length; i += 1) {
          let judge_score = judgeScores[i];
          if (min === judge_score && !min_dropped) {
            judgeScores.splice(i, 1);
            min_dropped = true;
          }
          if (max === judge_score && !max_dropped) {
            judgeScores.splice(i, 1);
            max_dropped = true;
          }
        }
      }
      const sum = judgeScores.reduce((a, b) => a + b, 0);
      this.presentation = (sum / judgeScores.length) || 0;
      this.presentation = parseFloat(this.presentation.toFixed(2));
    },

    async initializePage() {
      this.outline = {};
      this.scoring_view_checked = false;
      this.outline = {
        bbt_reqs_checked: false,
        locked: false,
        scoring_view_checked: false,
        breaking_techniques_to_outlines: [],
        testing_reqs_checked: false,
        weight: 99999,
        required_number_breaks: false,
        required_speed_breaks: false,
        required_power_breaks: false,
        required_minimum_score: false,
      };

      this.dropdown_ranks = [];
      this.dropdown_support_options = [];
      this.dropdown_boards = [];
      this.dropdown_directions = [];
      this.dropdown_techniques = [];
      this.checkbox_modifiers = [];
      this.board_techniques = [];
      this.board_technique_select_options = [];

      // storage objects to hold values in prior to calculating scores
      this.support_option_objects = {};
      this.technique_objects = {};
      this.rank_requirements = {};
      this.direction_objects = {};
      this.modifier_objects = {};
      this.board_size_objects = {};
      this.board_objects = {
        additional_boards: 0,
      };
      this.penalties_objects = {};
      this.scores_objects = {};
      this.user = {};
      this.canSave = true;
      this.presentation = null;
      this.total_boards = 0;
      this.total_speed_breaks = 0;
      this.total_power_breaks = 0;
      this.outline_rank = null;
      this.error_txt = null;
      this.fullName = null;
      this.allDirections = null;
      this.userClass = null;
    },

    async initializeScores() {
      if (!this.outline.was_bbt && this.outline.bbt_reqs_checked){
        this.outline.was_bbt = this.outline.bbt_reqs_checked
        this.outline.bbt_reqs_checked = false
      }
      this.presentation = 0;
      this.total_speed_breaks = 0;
      this.total_power_breaks = 0;
      this.total_boards = 0;
      this.board_objects = {
        additional_boards: 0,
      };
      this.support_option_objects = {};
      this.technique_objects = {};
      this.direction_objects = {};
      this.modifier_objects = {};
      this.board_size_objects = {};
      this.penalties_objects = {};
      this.scores_objects = {};
      this.total_score = 0;
      this.scores_objects.total_average_score = 0;
      this.scores_objects.total_attempts = 0;
      this.scores_objects.total_penalties = 0;
      this.scores_objects.pre_final_technical_score = 0;
      this.scores_objects.final_technical_score = 0;
      this.scores_objects.final_technical_score_length = 0;
      this.scores_objects.total_line_scores = 0;
      this.scores_objects.avg_total_line_scores = 0;
      this.scores_objects.final_score = 0;
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          const id = technique_to_outline.id;
          this.board_objects[id] = {
            additional_boards: 0
          };
          this.support_option_objects[id] = {};
          this.technique_objects[id] = {};
          this.direction_objects[id] = {};
          this.modifier_objects[id] = {};
          this.board_size_objects[id] = {};
          this.penalties_objects[id] = {};
          this.scores_objects[id] = {};
          this.scores_objects[id].line_score = 0;
          this.scores_objects[id].additional_points = 0;
          this.scores_objects[id].possible_score = 0;
          this.scores_objects[id].final_score = 0;
          this.scores_objects[id].presentation_score = 0;
          this.scores_objects[id].penalties = 0;
          this.scores_objects[id].a_minus = 0;
          this.scores_objects[id].average_board_size = 0;
          this.scores_objects[id].starting_line_score = 0;
          this.scores_objects[id].attempts = 0;
        }
      }
    },

    async setSupportOptions() {
      // loop through the modifiers
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          technique_to_outline.speed_break_color = 'text-center';
          const dropdown_modifier_id = technique_to_outline.dropdown_modifier_id;
          if (technique_to_outline.breaking_technique) {
            const id = technique_to_outline.id;
            this.support_option_objects[id] = {
              a_minus: 0,
              point_value: 0,
              additional_points: 0,
            };
            for (const modifier of this.dropdown_support_options) {
              // console.log('Modifier: ', modifier);
              // console.log('Breaking Technique: ', technique_to_outline.breaking_technique);
              if (modifier && (modifier.id === dropdown_modifier_id)) {
                this.support_option_objects[id].a_minus = modifier.a_minus;
                this.support_option_objects[id].point_value = modifier.point_value;
              }
              const support_option_id = technique_to_outline.dropdown_modifier_id;
              if (modifier.speed_break && modifier.id === support_option_id) {
                technique_to_outline.speed_break = modifier.speed_break;
                this.total_speed_breaks += 1;
                technique_to_outline.speed_break_color = 'text-center border border-success';
                if (modifier.name === 'airborne' && technique_to_outline.breaking_technique.is_spinning) {
                  // console.log('I just matched a spinning');
                  this.support_option_objects[id].additional_points += 1;
                }
                if (modifier.name === 'airborne' && technique_to_outline.breaking_technique.is_kick) {
                  // console.log('I just matched a kick');
                  this.support_option_objects[id].additional_points += 1;
                }
              }
            }
          }
        }
      }
    },

    async setBoards() {
      // loop through each of the technique to outlines
      // figure out how many boards we have, add them together
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          if (technique_to_outline.breaking_technique) {
            this.board_objects[technique_to_outline.id].additional_boards = 0;
            const technique = technique_to_outline.breaking_technique;
            const num_boards = parseInt(technique_to_outline.num_boards);
            const board_multiplier = parseInt(technique.board_multiplier);
            this.board_objects[technique_to_outline.id].additional_boards = (num_boards / board_multiplier) - 1;
            this.total_boards += num_boards;
          }
        }
      }
    },

    async setTechniqueSelections() {
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          const id = technique_to_outline.id;
          if (technique_to_outline.breaking_technique_id && technique_to_outline.breaking_technique) {
            const technique = technique_to_outline.breaking_technique;
            const base_point_value = parseInt(technique.base_point_value);
            const board_multiplier = parseInt(technique.board_multiplier);
            const a_minus = parseInt(technique.a_minus);
            const isPowerBreak = technique.is_power_break;
            this.technique_objects[id] = {
              'base_point_value': base_point_value,
              'board_multiplier': board_multiplier,
              'a_minus': a_minus,
              'is_power_break': isPowerBreak,
            };
          } else {
            this.technique_objects[id] = {
              'base_point_value': 0,
              'board_multiplier': 0,
              'a_minus': 0,
              'is_power_break': false,
            }
          }
        }
      }
    },

    async setDirectionSelections() {
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          const id = technique_to_outline.id;
          const direction_id = technique_to_outline.direction_id;
          this.direction_objects[id] = {
            'direction_id': direction_id,
            'a_minus': 0,
            'additional_point_value': 0
          };
          if (direction_id) {
            for (const direction of this.allDirections) {
              if (direction_id === direction.id) {
                const a_minus = parseInt(direction.a_minus);
                const additional_point_value = parseInt(direction.additional_point_value);
                this.direction_objects[id] = {
                  'direction_id': direction_id,
                  'a_minus': a_minus,
                  'additional_point_value': additional_point_value
                };
              }
            }
          }
        }
      }
    },

    async setBoardSizeObjects() {
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          const id = technique_to_outline.id;
          this.board_size_objects[id] = {
            order: 1,
            name: '',
          };
          const board_id = technique_to_outline.board_id;
          for (const board of this.dropdown_boards) {
            if (board_id === board.id) {
              this.board_size_objects[id] = {
                order: board.order,
                name: board.name,
              };
            }
          }
        }
      }
    },

    async setModifiers() {
      let successive_mods = 0;
      // sets each modifier in the objects
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          const id = technique_to_outline.id;
          // set the modifiers to nothing for starting out
          this.modifier_objects[id] = [];

          if (technique_to_outline.blindfolded) {
            technique_to_outline.speed_break = false;
            for (const modifier of this.checkbox_modifiers) {

              if (modifier && (modifier.name === 'blindfolded')) {
                const a_minus = parseInt(modifier.a_minus);
                const point_value = parseInt(modifier.point_value);
                const name = modifier.description;
                const mod_item = {
                  'a_minus': a_minus,
                  'point_value': point_value,
                  'name': name,
                };
                this.modifier_objects[id].push(mod_item);
              }
            }
          }

          if (technique_to_outline.self_held) {
            for (const modifier of this.checkbox_modifiers) {
              if (modifier && (modifier.name === 'self_held')) {
                const a_minus = parseInt(modifier.a_minus);
                const point_value = parseInt(modifier.point_value);
                const name = modifier.description;
                const mod_item = {
                  'a_minus': a_minus,
                  'point_value': point_value,
                  'name': name,
                };
                this.modifier_objects[id].push(mod_item);
              }
            }
          }
          if (technique_to_outline.simultaneous) {
            for (const modifier of this.checkbox_modifiers) {
              if (modifier && (modifier.name === 'simultaneous')) {
                const a_minus = parseInt(modifier.a_minus);
                const point_value = parseInt(modifier.point_value);
                const name = modifier.description;
                const mod_item = {
                  'a_minus': a_minus,
                  'point_value': point_value,
                  'name': name,
                };
                this.modifier_objects[id].push(mod_item);
              }
            }
          }

          // if marked successive, give them 1 point per mark
          if (technique_to_outline.successive) {
            for (const modifier of this.checkbox_modifiers) {
              if (modifier && (modifier.name === 'successive')) {
                successive_mods += 1;
                if (successive_mods === 1) {
                  const mod_item = {
                    'name': modifier.description,
                    'a_minus': 2,
                    'point_value': 0,
                  };
                  // technique_item.board_plus_minus += 2;
                  this.modifier_objects[id].push(mod_item);
                } else if (successive_mods === 2) {
                  const mod_item = {
                    'name': modifier.description,
                    'a_minus': 2,
                    'point_value': 1,
                  };
                  this.modifier_objects[id].push(mod_item);
                } else if (successive_mods >= 3) {
                  const mod_item = {
                    'name': modifier.description,
                    'a_minus': 2,
                    'point_value': 3,
                  };
                  this.modifier_objects[id].push(mod_item);
                }
              }
            }
          }
          if (technique_to_outline.no_spacers) {
            for (const modifier of this.checkbox_modifiers) {
              if (modifier && (modifier.name === 'no_spacers')) {
                const a_minus = parseInt(modifier.a_minus);
                const point_value = parseInt(modifier.point_value);
                const name = modifier.name;
                const mod_item = {
                  'a_minus': a_minus,
                  'point_value': point_value,
                  'name': name,
                };
                this.modifier_objects[id].push(mod_item);
              }
            }
          }
        }
      }
    },

    async setPenalties() {
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        for (const technique_to_outline of this.outline.breaking_techniques_to_outlines) {
          const id = technique_to_outline.id;
          this.penalties_objects[id] = [];
          if (technique_to_outline.attempts >= 1) {
            for (const penalty of this.penalties) {
              if (penalty && (penalty.name === 'attempts')) {
                const penalty_point_value = parseInt(penalty.point_value);
                const attempts = parseInt(technique_to_outline.attempts);
                const tmpPenalty = {
                  point_value: penalty_point_value,
                  attempts,
                  name: penalty.name,
                };
                this.penalties_objects[id].push(tmpPenalty);
              }
            }
          }

          if (technique_to_outline.changed_break) {
            for (const penalty of this.penalties) {
              if (penalty && (penalty.name === 'changed_break')) {
                const penalty_point_value = parseInt(penalty.point_value);
                const tmpPenalty = {
                  point_value: penalty_point_value,
                  name: penalty.name,
                };
                this.penalties_objects[id].push(tmpPenalty);
              }
            }
          }

          if (parseInt(technique_to_outline.dropped_a_board) > 0) {
            for (const penalty of this.penalties) {
              if (penalty && (penalty.name === 'dropped_board')) {
                const penalty_point_value = parseInt(penalty.point_value);
                const tmpPenalty = {
                  point_value: penalty_point_value,
                  name: penalty.name,
                };
                this.penalties_objects[id].push(tmpPenalty);
              }
            }
          }

          if (technique_to_outline.partial_break) {
            for (const penalty of this.penalties) {
              if (penalty && (penalty.name === 'partial_break')) {
                const penalty_point_value = parseInt(penalty.point_value);
                const tmpPenalty = {
                  point_value: penalty_point_value,
                  name: penalty.name,
                };
                this.penalties_objects[id].push(tmpPenalty);
              }
            }
          }
          if (technique_to_outline.didnt_break) {
            for (const penalty of this.penalties) {
              if (penalty && (penalty.name === 'no_break')) {
                const penalty_point_value = parseInt(penalty.point_value);
                const tmpPenalty = {
                  point_value: penalty_point_value,
                  name: penalty.name,
                };
                this.penalties_objects[id].push(tmpPenalty);
              }
            }
          }
        }
      }
    },

    // now that we have all the objects and values, calculate the final scores
    async calculateScores() {
      this.scores_objects.pre_final_technical_score = 0;
      this.scores_objects.final_technical_score = 0;
      this.scores_objects.final_technical_score_length = 0;
      this.scores_objects.total_line_scores = 0;
      this.scores_objects.avg_total_line_scores = 0;
      this.scores_objects.final_score = 0;
      const outline_techniques = this.outline.breaking_techniques_to_outlines;
      for (const technique_to_outline of outline_techniques) {
        const id = technique_to_outline.id;
        // initializers
        this.scores_objects[id].average_board_size = this.userClass - this.technique_objects[id].a_minus;
        this.scores_objects[id].starting_line_score = this.technique_objects[id].base_point_value +
          this.board_size_objects[id].order - this.scores_objects[id].average_board_size;

        // first, get all the a-minuses
        // this.scores_objects[id].a_minus = this.userClass - this.board_size_objects[id].order;
        this.scores_objects[id].a_minus += this.support_option_objects[id].a_minus;
        // this.scores_objects[id].a_minus += this.technique_objects[id].a_minus;
        this.scores_objects[id].a_minus += this.direction_objects[id].a_minus;
        const modifiers = this.modifier_objects[id];
        for (const modifier of modifiers) {
          if (modifier.a_minus) {
            this.scores_objects[id].a_minus += modifier.a_minus;
          }
        }

        // Build the possible score calculations now
        // this.scores_objects[id].possible_score = this.technique_objects[id].base_point_value;
        this.scores_objects[id].additional_points += (this.board_objects[id].additional_boards * 2);
        this.scores_objects[id].additional_points += this.support_option_objects[id].point_value;
        this.scores_objects[id].additional_points += this.direction_objects[id].additional_point_value;

        let no_spacers = false;
        for (const modifier of modifiers) {
          if (modifier.name === 'no_spacers') {
            no_spacers = true;
            this.scores_objects[id].additional_points += (this.board_objects[id].additional_boards * 1);
          } else {
            if (modifier.point_value) {
              this.scores_objects[id].additional_points += modifier.point_value;
            }
          }
        }

        let partial_break = false;
        let drop_board = false;
        let no_break = false;
        const penaltyObj = this.penalties_objects[id];
        for (const penalty of penaltyObj) {
          let attempts = 0;
          if (penalty.name === 'attempts') {
            attempts += penalty.attempts;
            this.scores_objects[id].penalties += (attempts);
          }
          if (penalty.name === 'changed_break') {
            this.scores_objects[id].penalties += penalty.point_value;
          }
          if (penalty.name === 'partial_break') {
            partial_break = true;
          }
          if (penalty.name === 'no_break') {
            no_break = true;
          }
          if (penalty.name === 'dropped_board') {
            drop_board = true;
          }
        }

        // now calculate the possible score
        this.scores_objects[id].line_score = this.scores_objects[id].starting_line_score + this.scores_objects[id].a_minus + this.scores_objects[id].additional_points;
        this.scores_objects.total_line_scores += this.scores_objects[id].line_score;
        this.scores_objects.total_average_score = ((this.scores_objects.total_line_scores / outline_techniques.length) || 0).toFixed(2);
        if (this.support_option_objects[id].additional_points > 0) {
          //console.log('Points are greater then 0: ', this.support_option_objects[id])
        }
        this.scores_objects[id].line_score += this.support_option_objects[id].additional_points;

        // now calculate the possible score
        this.scores_objects[id].total_score = this.scores_objects[id].line_score;

        if (no_break) {
          this.scores_objects[id].total_score = 0;
          this.scores_objects[id].penalties += this.scores_objects[id].line_score;
        }

        if (drop_board) {
          // for each board dropped it will give 2 points of penalty
          if (no_spacers) {
            this.scores_objects[id].penalties += (parseInt(technique_to_outline.dropped_a_board) * 2);
            this.scores_objects[id].penalties += parseInt(technique_to_outline.dropped_a_board);
          } else {
            this.scores_objects[id].penalties += (parseInt(technique_to_outline.dropped_a_board) * 2);
          }
        }

        if (partial_break) {
          this.scores_objects[id].penalties += this.scores_objects[id].line_score / 2;
        }

        // now calculate the total scores for each line
        this.scores_objects[id].total_score = this.scores_objects[id].line_score - this.scores_objects[id].penalties;
        if (this.scores_objects[id].total_score < 0) {
          this.scores_objects[id].total_score = 0;
        }
        this.scores_objects.final_technical_score_length += 1;
        this.scores_objects.pre_final_technical_score += this.scores_objects[id].total_score;
      }

      // calculate the technical score
      if (this.scores_objects.final_technical_score_length > 0) {
        this.scores_objects.final_technical_score = (this.scores_objects.pre_final_technical_score /
          this.scores_objects.final_technical_score_length);
      } else {
        this.scores_objects.final_technical_score = 0;
      }

      // calculate the avg of the total line scores
      if (this.scores_objects.final_technical_score_length > 0) {
        this.scores_objects.avg_total_line_scores = (this.scores_objects.total_line_scores /
          this.scores_objects.final_technical_score_length);
      } else {
        this.scores_objects.avg_total_line_scores = 0;
      }

      this.scores_objects.final_score = this.scores_objects.final_technical_score + this.presentation;
      this.$forceUpdate()
      if (this.outline.was_bbt) {
        this.outline.bbt_reqs_checked = true
        this.outline.was_bbt = false
      }
    },

    async setAModifiers() {
      // every single technique has a score attached to it, set each one individually, then
      // calculate each of the total scores by adding things up
      await this.initializeScores();
      await this.setPresentation();
      await this.setBoards();
      await this.setBoardSizeObjects();
      await this.setSupportOptions();
      await this.setTechniqueSelections();
      await this.setDirectionSelections();
      await this.setModifiers();
      await this.setPenalties();
      await this.calculateScores();
      await this.setRankOptions();
      await this.setBBTReqs();
    },

    async setRankOptions() {
      const rank_id = this.outline.rank_id;
      for (const rank of this.dropdown_ranks) {
        if (rank.id === rank_id) {
          this.rank_requirements = rank;
        }
      }
    },

    async addTechniqueRow() {
      const last_technique = this.outline.breaking_techniques_to_outlines.at(-1);
      let order;
      if (last_technique) {
        order = parseInt(last_technique.order);
        order += 1;
      } else {
        order = 1;
      }
      this.canSave = false; // disable the save button until a technique is selected
      const rights = this.$store.getters.scopes;
      for (let i = 0; i < rights.length; i += 1) {
        if (rights[i].name === 'edit-all-events') {
          this.canSave = true; // disable the save button until a technique is selected
        }
      }
      // add an empty row.
      const technique_obj = {
        outline_id: this.outline.id,
        order,
        breaking_technique_id: null,
        board_id: 1,
        direction_id: null,
        dropdown_modifier_id: 41, // default to supported
        num_boards: 0,
        additional_boards: 0,
        blindfolded: false,
        supported: false,
        unsupported: false,
        self_held: false,
        freestanding: false,
        airborne: false,
        simultaneous: false,
        no_spacers: false,
        successive: false,
        attempts: 0,
        changed_break: false,
        dropped_a_board: 0,
        partial_break: false,
        didnt_break: false
      };
      this.outline.breaking_techniques_to_outlines.push(technique_obj);
      await this.setTechniqueNames();
      await this.setAModifiers();
    },

    getDirectionsForTechnique(technique_to_outline_id, technique_id, technique) {
      technique.direction_id = null;
      return techniquesAPIService.getBreakingTechniqueWithDirections(technique_id, this.$router)
      .then((techniqueWithDir) => {
        // make sure that the directions work.  First, this is a technique with the directions attached.
        technique.breaking_technique = techniqueWithDir; // this will add the technique to the current outline

        // If there isn't anything in the direction, set it to N/A
        if (technique.breaking_technique && technique.breaking_technique.directions
          && technique.breaking_technique.directions.length >= 1) {
          // the technique directions are set, now bring it in
          for (const dir of technique.breaking_technique.directions) {
            dir.text = dir.name;
            dir.value = dir.id;
          }
          // we have a technique in there, set it to "please select"
          const tempDir = {
            id: null,
            name: 'Pls Select',
            value: null,
            text: 'Pls Select',
            a_minus: 0,
            additional_point_value: 0,
          }
          this.canSave = false;
          const rights = this.$store.getters.scopes;
          for (let i = 0; i < rights.length; i += 1) {
            if (rights[i].name === 'edit-all-events') {
              this.canSave = true; // disable the save button until a technique is selected
            }
          }
          technique.breaking_technique.directions.unshift(tempDir);
        } else {
          // no direction found, set it to null, but N/A as the title
          const tempDir = {
            id: null,
            name: 'N/A',
            value: null,
            text: 'N/A',
            a_minus: 0,
            additional_point_value: 0,
          }
          technique.breaking_technique.directions = [];
          technique.breaking_technique.directions.push(tempDir);
        }
        technique.num_boards = technique.breaking_technique.board_multiplier;

        // set the directions up as text value
        this.setTechniqueNames();
        this.setAModifiers();
      })
      .catch((error) => {
        this.error_txt = 'Error setting technique directions: ' + error;
        this.openModal('error');
      });
    },
    moveTechniqueUp(technique, id){
      const index = this.outline.breaking_techniques_to_outlines.indexOf(technique);
      if (index > 0){
        let prevID = this.outline.breaking_techniques_to_outlines[index-1].id
        document.getElementById(id).classList.add("moveUp")
        document.getElementById(prevID).classList.add("moveDown")
        let frontSide = this.outline.breaking_techniques_to_outlines.slice(0, index-1)
        let backSide = this.outline.breaking_techniques_to_outlines.slice(index+1)
        frontSide.push(this.outline.breaking_techniques_to_outlines[index])
        backSide.unshift(this.outline.breaking_techniques_to_outlines[index-1])
        this.outline.breaking_techniques_to_outlines = frontSide.concat(backSide)
        this.updateTechniqueOrder()
        setTimeout(function (){
          document.getElementById((id)).classList.remove("moveUp")
          document.getElementById(prevID).classList.remove("moveDown")
        }, 1000)
      }
    },
    moveTechniqueDown(technique, id){
      const index = this.outline.breaking_techniques_to_outlines.indexOf(technique);
      if (index < this.outline.breaking_techniques_to_outlines.length-1){
        let nextID = this.outline.breaking_techniques_to_outlines[index+1].id
        document.getElementById((id)).classList.add("moveDown")
        document.getElementById(nextID).classList.add("moveUp")
        let frontSide = this.outline.breaking_techniques_to_outlines.slice(0, index)
        let backSide = this.outline.breaking_techniques_to_outlines.slice(index+2)
        frontSide.push(this.outline.breaking_techniques_to_outlines[index+1])
        frontSide.push(this.outline.breaking_techniques_to_outlines[index])
        this.outline.breaking_techniques_to_outlines = frontSide.concat(backSide)
        this.updateTechniqueOrder()
        setTimeout(function (){
          document.getElementById((id)).classList.remove("moveDown")
          document.getElementById(nextID).classList.remove("moveUp")
        }, 1000)
      }
    },
    deleteTechniqueRow(technique) {
      this.spinning = true;
      const index = this.outline.breaking_techniques_to_outlines.indexOf(technique);
      if (technique.id) {
        return techniquesAPIService.deleteBreakingTechnique(technique.id, '', this.$router)
        .then(() => {
          if (index > -1) {
            this.outline.breaking_techniques_to_outlines.splice(index, 1);
          }
          this.spinning = false;
          this.setAModifiers();
          this.updateTechniqueOrder()
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'Error deleting technique: ' + error;
          this.openModal('error');
        });
      } else {
        if (index > -1) {
          this.outline.breaking_techniques_to_outlines.splice(index, 1);
          this.spinning = false;
        }
      }
    },
    updateTechniqueOrder(){
      for (let i = 0; i < this.outline.breaking_techniques_to_outlines.length; i++){
        this.outline.breaking_techniques_to_outlines[i].order = i+1
      }
    },
    saveTechniqueToOutline(name) {
      this.spinning = true;
      // see if we can save it first
      // check the techniques, make sure they're not null and directions.
      let technique_check = true;
      let direction_check = true;
      for (const technique of this.outline.breaking_techniques_to_outlines) {
        if (!technique.breaking_technique_id) {
          technique_check = false;
        }
      }
      for (const technique of this.outline.breaking_techniques_to_outlines) {
        if (!technique.direction_id) {
          direction_check = false;
        }
      }
      if (!technique_check) {
        this.error_txt = 'Cannot proceed, not all techniques have been selected';
        this.openModal('error')
        return;
      }
      /*
       if (!direction_check) {
       this.error_txt = 'Cannot proceed, not all directions have been selected';
       this.openModal('error')
       return;
       }
       */
      this.outline.meets_requirements = false;
      this.outline.required_number_breaks = false;
      this.outline.required_speed_breaks = false;
      this.outline.required_power_breaks = false;
      this.outline.required_minimum_score = false;
      if (this.outline && this.outline.breaking_techniques_to_outlines) {
        if (this.outline.breaking_techniques_to_outlines.length === this.rank_requirements.number_of_breaks &&
          this.total_speed_breaks >= this.rank_requirements.required_speed_breaks &&
          this.total_power_breaks >= this.rank_requirements.required_power_breaks &&
          this.scores_objects.total_average_score >= this.rank_requirements.breaking_point_minimum) {
          this.outline.meets_requirements = true;
        }
      }

      if (this.outline.breaking_techniques_to_outlines.length === this.rank_requirements.number_of_breaks) {
        this.outline.required_number_breaks = true;
      }

      if (this.total_speed_breaks >= this.rank_requirements.required_speed_breaks) {
        this.outline.required_speed_breaks = true;
      }

      if (this.total_power_breaks >= this.rank_requirements.required_power_breaks) {
        this.outline.required_power_breaks = true;
      }

      if (this.scores_objects.total_average_score >= this.rank_requirements.breaking_point_minimum) {
        this.outline.required_minimum_score = true;
      }

      // now take it all in, assemble the outline, send to the DB
      if (this.outline.id) {
        // update not create
        return outlineAPIService.updateOutline(this.outline.id, this.outline, this.$router)
        .then(() => {
          const userId = this.$store.getters.userId;
          const parms = {
            weight: this.outline.weight,
          };
          return usersAPIService.updateUser(userId, parms, this.$router);
        })
        .then(async () => {
          return Swal.fire({
            title: 'Success saving Outline',
            text: 'Save Successful',
            timer: 1000,
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        })
        .then(async () => {
          const self = this;
          await this.getMountedItems(this.outline_id);
          this.spinning = false;
          self.modals[name] = true;
          setTimeout(function () {
            self.modals[name] = false; // Use your variable name
          }, 1 * 1500); // Hide after 5 secs
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'Error saving technique to outline: ' + error;
          this.openModal('error');
        });
      } else {
        return outlineAPIService.addOutline(this.outline, this.$router)
        .then(() => {
          const promises = [];
          for (let i = 0; i < this.outline.breaking_techniques_to_outlines.length; i += 1) {
            const id = this.outline.breaking_techniques_to_outlines[i].id;
            this.outline.breaking_techniques_to_outlines[i].order = i + 1;
            promises.push(techniqueToOutlineAPIService.update(id, { order: i + 1 }, this.$router));
          }
          return Promise.all(promises)
          // now start updating both the old index and the new to the new values
        })
        .then(() => {
          this.spinning = false;
          const self = this;
          self.modals[name] = true;
          setTimeout(function () {
            self.modals[name] = false; // Use your variable name
          }, 1 * 1500); // Hide after 5 secs
        })
        .catch((error) => {
          this.spinning = false;
          this.error_txt = 'Error saving outline: ' + error;
          this.openModal('error');
        });
      }
    },

    getItemByID(items, id) {
      for (const item of items) {
        if (item.id === id) {
          return item;
        }
      }
      return null;
    }
  }
}
</script>

<style>
@media print {
  @page {
    size: landscape;
  }
}

.verticaltext {
  position: relative;
  min-height: 80px;
}

.verticaltext_content {
  -webkit-transform: rotate(-65deg);
  -moz-transform: rotate(-65deg);
  -ms-transform: rotate(-65deg);
  -o-transform: rotate(-65deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  min-height: 80px;
  min-width: 80px;
}

.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

#requirements{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: .5em;
}

.fraction{
  display: inline-block;
  min-width: 2em;
}

.numerator{
  font-size: 26px;
  text-align: center;
  border-bottom: solid black 2px;
  font-weight: bold;
}

.denominator{
  font-size: 26px;
  text-align: center;
  font-weight: bold;
}

.met{
  border: solid green 1px;
  border-radius: 3px;
  background-color: #abff8f;
  color: black;
}

.unmet{
  border: solid red 1px;
  border-radius: 3px;
  background-color: #ff8f8f;
  color: black;
}

.custom-col-1,.custom-col-2,.custom-col-5,.custom-col-1-wd,.custom-col-1-th,.custom-dir{
  position:relative;
  width:100%;
  padding-right:15px;
  padding-left:15px;
}

.custom-col-1{
  -ms-flex:0 0 10%;
  flex:0 0 10%;
  max-width:10%;
  text-align: center;
  align-self: center;
}

.custom-col-1-wd{
  -ms-flex:0 0 18.333333%;
  flex:0 0 18.333333%;
  max-width: 18.333333%;
  text-align: center;
  align-self: center;
}

.custom-dir{
  -ms-flex:0 0 15.333333%;
  flex:0 0 15.333333%;
  max-width: 15.333333%;
  text-align: center;
  align-self: center;
}

.custom-col-1-th{
  -ms-flex:0 0 6.5%;
  flex:0 0 6.5%;
  max-width: 6.5%;
  text-align: center;
  align-self: center;
}

.custom-col-2{
  -ms-flex:0 0 15%;
  flex:0 0 15%;
  max-width: 15%;
}

.custom-col-5{
  -ms-flex:0 0 20%;
  flex:0 0 20%;
  max-width:20%;
}

.custom-lg-col-1,.custom-lg-col-2,.custom-lg-col-3.custom-lg-col-7{
  position:relative;
  width:100%;
  padding-right:15px;
  padding-left:15px;
  text-align: center;
  align-self: center;
}

.custom-lg-col-1{
  -ms-flex:0 0 10%;
  flex:0 0 10%;
  max-width:10%;
}

.custom-lg-col-2{
  -ms-flex:0 0 20%;
  flex:0 0 20%;
  max-width:20%;
}

.custom-lg-col-3{
  -ms-flex:0 0 30%;
  flex:0 0 30%;
  max-width:30%;
}

.custom-lg-col-7{
  -ms-flex:0 0 70%;
  flex:0 0 70%;
  max-width:70%;
}

.moveUp{
  animation: moveUp .5s ease;
}
.moveDown{
  animation: moveDown .5s ease;
}

@keyframes moveUp {
  0%{
    transform: translate(0, 65px);
  }
  100%{
    transform: translate(0);
  }
}

@keyframes moveDown {
  0%{
    transform: translate(0, -65px);
  }
  100%{
    transform: translate(0);
  }
}

.techniqueHeader{
  position: sticky;
  top: 10px;
  background-color: #f0f0f0;
  z-index: 100;
  padding-top: 1em;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
}

.techniqueFooter{
  background-color: #f0f0f0;
  border-radius: 6px;
  font-size: 16px;
  padding: 15px;
}
</style>
