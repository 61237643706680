<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Ranks</h4>
                <p class="card-category">
                  Create or Edit Ranks
                </p>
                <p class="card-category">
                  <el-button type="primary" @click="openModal('create')" icon="">Add New Rank</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label>Select a Rank to modify:</label>
            <el-select filterable v-model="selected_rank_id" placeholder="Select Rank" style="width: 100%"
                       @input="toggleSelected">
              <el-option v-for="item in ranks"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <el-table stripe :data="selected_ranks" style="width: 100%" v-if="selected_rank">
        <el-table-column label="Details" :min-width="200" align="center">
          <template v-slot="scope">
            <label>Name:</label>
            <el-input v-model="scope.row.name" placeholder="Name" @change="updateRank"></el-input>
            <br>
            <label>Program:</label>
            <el-select filterable v-model="scope.row.program_id" placeholder="Select Program" style="width: 100%" @change="updateRank">
              <el-option v-for="item in programs"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
            <br>
            <label>Rank Type:</label>
            <el-select filterable v-model="scope.row.rank_type_id" placeholder="Select Rank Type" style="width: 100%" @change="updateRank">
              <el-option v-for="item in rank_types"
                         :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Breaking Requirements" :min-width="300" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-6">
                <label>Number of Breaks:</label>
                <el-input v-model="scope.row.number_of_breaks" min="0" type="number" @change="updateRank"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Breaking Point Minimum:</label>
                <el-input v-model="scope.row.breaking_point_minimum" min="0" type="number" @change="updateRank"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Required Speed Breaks:</label>
                <el-input v-model="scope.row.required_speed_breaks" min="0" type="number" @change="updateRank"></el-input>
              </div>
              <div class="col-sm-6">
                <label>Required Power Breaks:</label>
                <el-input v-model="scope.row.required_power_breaks" min="0" type="number" @change="updateRank"></el-input>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Options" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-12 text-center">
                <el-checkbox v-model="scope.row.freestyle_breaks" size="large" border @change="updateRank">Freestyle Breaks</el-checkbox>
              </div>
              <div class="col-sm-12 text-center">
                <el-checkbox v-model="scope.row.assigned_breaks" size="large" border @change="updateRank">Assigned Breaks</el-checkbox>
              </div>
              <div class="col-sm-12 text-center">
                <el-checkbox v-model="scope.row.show_poomsae" size="large" border @change="updateRank">Show Poomsae</el-checkbox>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template v-slot="scope">
            <el-button type="danger" @click="deleteRank(scope.row)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>

    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Create New Rank"
      :visible.sync="modals.create">
      <div class="text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <label>Name:</label>
                <el-input v-model="new_rank.name" placeholder="Name"></el-input>
              </div>
              <div class="col-sm-12">
                <label>Program:</label>
                <el-select filterable v-model="new_rank.program_id" placeholder="Select Program" style="width: 100%">
                  <el-option v-for="item in programs"
                             :key="item.id" :value="item.id" :label="item.name">
                  </el-option>
                </el-select>
              </div>
              <div class="col-sm-12">
                <label>Rank Type:</label>
                <el-select filterable v-model="new_rank.rank_type_id" placeholder="Select Rank Type" style="width: 100%" @change="updateRank">
                  <el-option v-for="item in rank_types"
                             :key="item.id" :value="item.id" :label="item.name">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12 text-center">
                <el-checkbox v-model="new_rank.freestyle_breaks" size="large" border>Freestyle Breaks</el-checkbox>
              </div>
              <div class="col-sm-12 text-center">
                <br>
                <el-checkbox v-model="new_rank.assigned_breaks" size="large" border>Assigned Breaks</el-checkbox>
              </div>
              <div class="col-sm-12 text-center">
                <br>
                <el-checkbox v-model="new_rank.show_poomsae" size="large" border>Show Poomsae</el-checkbox>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-3">
                <label>Number of Breaks:</label>
                <el-input v-model="new_rank.number_of_breaks" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Break Point Min:</label>
                <el-input v-model="new_rank.breaking_point_minimum" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Speed Breaks:</label>
                <el-input v-model="new_rank.required_speed_breaks" min="0" type="number"></el-input>
              </div>
              <div class="col-sm-3">
                <label>Power Breaks:</label>
                <el-input v-model="new_rank.required_power_breaks" min="0" type="number"></el-input>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div slot="footer" class="dialog-footer text-center">
          <el-button type="success" @click="createRank('create')" icon="">Create</el-button>
          <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Select, Option, Input, Button, Checkbox, Table, TableColumn } from 'element-ui';
import ProgramsAPIService from "src/servicehandlers/ProgramsAPIService";
import RanksAPIService from "src/servicehandlers/RanksAPIService";
import RankTypesAPIService from "../../../../servicehandlers/RankTypesAPIService";

const programsAPIService = new ProgramsAPIService();
const ranksAPIService = new RanksAPIService();
const rankTypesAPIService = new RankTypesAPIService();

export default {
  name: "RanksAdmin",
  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      spinning: false,
      is_rank_selected: false,
      selected_rank_id: null,
      selected_rank: null,
      selected_ranks: [],
      programs: [],
      new_rank: {},
      ranks: [],
      rank_types: [],
      reorder_ranks: false,
      error_txt: null,
      program_name: null,
      success_txt: null,
      last_order: null,
      modals: {
        error: false,
        success: false,
        person: false,
        create: false,
      },
    }
  },
  mounted() {
    this.getRanks();
    this.getRankTypes();
    this.getPrograms();
  },
  methods: {
    toggleSelected() {
      this.is_rank_selected = true;
      this.selected_ranks = []
      for (const rank of this.ranks) {
        if (this.selected_rank_id && this.selected_rank_id === rank.id) {
          this.selected_rank = rank;
          this.selected_ranks.push(rank)
          return;
        }
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.ranks.length; i += 1) {
        const id = this.ranks[i].id;
        this.ranks[i].order = i + 1;
        promises.push(ranksAPIService.updateRank(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        // const self = this;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Error updating rank! ' + error,
          timer: 1000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          showConfirmButton: false
        });
      });
    },
    addRank(name) {
      this.openModal(name);
    },
    getPrograms() {
      return programsAPIService.getPrograms(this.$router)
      .then((programs) => {
        for (const program of programs) {
          program.value = program.id;
          program.text = program.name;
        }
        this.programs = programs;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Error getting programs! ' + error,
          timer: 1000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          showConfirmButton: false
        });
      });
    },
    getRanks() {
      return ranksAPIService.getRanks(this.$router)
      .then((response) => {
        this.ranks = response;
        for (const rank of this.ranks) {
          rank.text = rank.name;
          rank.value = rank.id;
          this.last_order = rank.order;
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Ranks: ' + error;
        this.openModal('error');
      });
    },
    getRankTypes(){
      return rankTypesAPIService.getRankTypes(this.$router)
        .then((response) => {
          this.rank_types = response;
        })
        .catch((error) => {
          this.error_txt = 'Error getting Rank Types: ' + error;
          this.openModal('error');
        });
    },
    updateRank() {
      let rank = this.selected_ranks[0]
      return ranksAPIService.updateRank(rank.id, rank, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Rank update successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error updating Rank: ' + error;
        this.openModal('error');
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      if (name === 'create') {
        this.new_rank = {};
      }
      this.modals[name] = false;
    },
    deleteRank(id) {
      return ranksAPIService.deleteRank(id, this.$router)
      .then(() => {
        this.getRanks();
        this.selected_rank = {};
        this.is_rank_selected = false;
        const self = this;
        this.success_txt = 'Delete Rank successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error deleting rank: ' + error;
        this.openModal('error');
      });
    },
    createRank(modalName) {
      if (!this.new_rank.name) {
        const self = this;
        this.error_txt = 'Missing rank name';
        this.openModal('error');
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        return;
      }
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      if (!this.new_rank.freestyle_breaks) {
        this.new_rank.freestyle_breaks = false;
      }
      if (!this.new_rank.assigned_breaks) {
        this.new_rank.assigned_breaks = false;
      }
      if (!this.new_rank.show_poomsae) {
        this.new_rank.show_poomsae = false;
      }
      this.new_rank.order = order;
      return ranksAPIService.addRank(this.new_rank, this.$router)
      .then(() => {
        this.selected_rank = {};
        this.is_rank_selected = false;
        this.new_rank = {};
        this.getRanks();
        this.closeModal(modalName);
        const self = this;
        this.success_txt = 'Add Rank successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error rank program: ' + error;
        this.openModal('error');
      });
    }
  }
}
</script>

<style scoped>
</style>
