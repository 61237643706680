import CommonAPIService from './CommonHandler';

export class DocumentationAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the documentation
  getDocumentation(router) {
    const url = '/api/documentation';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getDocumentationByRank(rank_id, router) {
    const url = `/api/documentation/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveDocumentationToTechniques(techniques, router) {
    const url = `/api/documentation/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getDocumentationByID(documentation_id, router) {
    const url = `/api/documentation/${documentation_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveDocumentationToRank(ranks, router) {
    const url = '/api/documentation/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addDocumentation(documentation, router) {
    const url = '/api/documentation';
    return this.CommonAPIService.postCall(url, documentation, router);
  }

  updateDocumentation(documentation_id, parms, router) {
    const url = `/api/documentation/${documentation_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  linkDocumentationToRank(documentation_id, rank_id, router) {
    const url = `/api/documentation/${documentation_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteDocumentationToRank(documentation_id, rank_id, router) {
    const url = `/api/documentation/${documentation_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  updateDocumentationToRank(documentation_ids, rank_id, router) {
    const url = `/api/documentation/${documentation_ids}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deleteDocumentation(documentation_id, router) {
    const url = `/api/documentation/${documentation_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  updateTestedOnDocumentation(documentation_id, rank_id, tested, router) {
    const url = `/api/documentation/${documentation_id}/${rank_id}/${tested}/tested`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default DocumentationAPIService;
