<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">{{ event.name }}</h4>
                <p class="card-category">
                  Manage {{ event.description }} event
                </p>
                <div class="card-category">
                  <el-button type="primary" @click="addPerson" icon="">Add Person</el-button>
                  <el-button v-if="event.event_type_id === 1" type="primary" @click="printScores" icon="">Print Scores</el-button>
                  <el-button v-if="event.event_type_id === 1" type="primary" @click="printOutlines" icon="">Print Outlines</el-button>
                  <el-button v-if="event.event_type_id === 2" type="primary" @click="printNextRank" icon="">Print Rank Checklists</el-button>
                  <el-button v-if="event.event_type_id === 2" type="primary" @click="openModal('certs')" icon="">Print Certificates</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterOrders"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterOrders" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredOrders" style="width: 100%" height="60vh" @sort-change="handleSortChange">
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :min-width="150" align="center">
          <template slot="header">
            <el-checkbox :checked="isChecked()" id="select_all" @change="clickSelect"></el-checkbox>
            Competitor
          </template>
          <template v-slot="scope">
            <el-checkbox v-if="scope.row.outline && scope.row.outline.id" type="checkbox"
                         :value="checkSelected(scope.row.outline.id)" @change="selectID(scope.row.outline.id)"></el-checkbox>
            {{ scope.row.user.first_name + ' ' + scope.row.user.last_name }}
          </template>
        </el-table-column>
        <el-table-column label="Number" :min-width="100" align="center">
          <template v-slot="scope">
            {{ scope.row.code }}
          </template>
        </el-table-column>
        <el-table-column label="Registered" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.outline">Registered</div>
            <div v-else>Not Registered</div>
          </template>
        </el-table-column>
        <el-table-column label="Meets Criteria" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.outline && scope.row.outline.meets_requirements"
                 class="met text-center p-2"><span>Yes</span>
            </div>
            <div v-else class="unmet text-center p-2"><span>No</span></div>
          </template>
        </el-table-column>
        <el-table-column label="Outline" :min-width="150" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.outline && scope.row.outline.description">{{
                scope.row.user.first_name + ' ' + scope.row.user.last_name + ' - ' + event.name
              }}
              <outline v-if="scope.row.outline && scope.row.outline.id" :outline_id="scope.row.outline.id"></outline>
            </div>
            <div v-else>No outline</div>
          </template>
        </el-table-column>
        <el-table-column :min-width="200" align="center">
          <template v-slot="scope">
            <el-button v-if="!scope.row.outline || !scope.row.outline.locked" type="warning" @click="lockOutline(scope.row.outline.id)" icon="el-icon-lock"></el-button>
            <el-button v-else type="warning" @click="unlockOutline(scope.row.outline.id)" icon="el-icon-unlock"></el-button>
            <el-button type="danger" @click="deleteCode(scope.row.id, scope.row.user.first_name + ' ' + scope.row.user.last_name, scope.row.code)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
     <el-dialog
       center
       :title="'Add Person to Event:' + event.description"
       :visible.sync="modals.person">
       <div class="text-center">
         <div class="row">
           <div class="col-sm-4 font-weight-bold">User</div>
           <div class="col-sm-4 font-weight-bold">Code</div>
           <div class="col-sm-4 font-weight-bold">Outline (optional)</div>
         </div>
         <div class="row">
           <div class="col-sm-4 font-weight-bold">
             <model-select class="select-primary" @input="getOutlinesForUser" :options="users"
                           v-model="selected_user"
                           placeholder="select user">
             </model-select>
           </div>
           <div class="col-sm-4 font-weight-bold"><fg-input v-model="code" placeholder="Enter the code"></fg-input></div>
           <div class="col-sm-4 font-weight-bold">
             <model-select class="select-primary" :options="possible_user_outlines"
                           v-model="selected_user_outline"
                           placeholder="select outline (optional)">
             </model-select>
           </div>
         </div>
         <span slot="footer" class="dialog-footer">
           <el-button type="success" @click="savePerson('person')" icon="">OK</el-button>
           <el-button type="danger" @click="closeModal('person')" icon="">Cancel</el-button>
         </span>
       </div>
     </el-dialog>
     <el-dialog
       center
       title="Error"
       :visible.sync="modals.error">
       <div class="text-center">
         <span>Error: {{ error_txt }}</span>
         <br>
         <span slot="footer" class="dialog-footer">
           <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
         </span>
       </div>
     </el-dialog>
     <el-dialog
       center
       title="Success"
       :visible.sync="modals.success">
       <div class="text-center">
         <span>Success: {{ success_txt }}</span>
         <br>
         <span slot="footer" class="dialog-footer">
           <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
         </span>
       </div>
     </el-dialog>
    <el-dialog
       center
       title="Print Certificates"
       :visible.sync="modals.certs">
       <div class="text-center">
         <el-button type="primary" @click="printCertificates(false)" icon="">Print Red Certificates</el-button>
         <el-button type="primary" @click="printCertificates(true)" icon="">Print Black Certificates</el-button>
         <br>
         <br>
         <span slot="footer" class="dialog-footer">
           <el-button type="success" @click="closeModal('certs')" icon="">Cancel</el-button>
         </span>
       </div>
     </el-dialog>
   </div>
 </template>
 <script>
 import Swal from 'sweetalert2';
 import { ModelSelect } from 'vue-search-select';
 import jsPDF from 'jspdf';
 import moment from 'moment';
 import { Dialog, Select, Option, Input, Button, Checkbox, Table, TableColumn } from 'element-ui';
 import EventsAPIService from "../../../../servicehandlers/EventsAPIService";
 import OutlineAPIService from "../../../../servicehandlers/OutlinesAPIService";
 import UsersAPIService from "../../../../servicehandlers/UsersAPIService";
 import OrderNumbersAPIService from "../../../../servicehandlers/OrderNumbersAPIService";
 import SupportOptionsAPIService from "src/servicehandlers/SupportOptionsAPIService";
 import BoardsAPIService from "src/servicehandlers/BoardsAPIService";
 import RanksAPIService from "../../../../servicehandlers/RanksAPIService";
 import BreakingTechniquesAPIService from "../../../../servicehandlers/BreakingTechniquesAPIService";
 import DirectionsAPIService from "../../../../servicehandlers/DirectionsAPIService";
 import ModifiersAPIService from "../../../../servicehandlers/ModifiersAPIService";
 import PenaltiesAPIService from "../../../../servicehandlers/PenaltiesAPIService";
 import PoomsaeAPIService from "../../../../servicehandlers/PoomsaeAPIService";
 import KicksAPIService from "../../../../servicehandlers/KicksAPIService";
 import SelfDefenseTechniquesAPIService from "../../../../servicehandlers/SelfDefenseTechniquesAPIService";
 import KickCombosAPIService from "../../../../servicehandlers/KickCombosAPIService";
 import TechniquesAPIService from "../../../../servicehandlers/TechniquesAPIService";
 import TerminologyAPIService from "../../../../servicehandlers/TerminologyAPIService";
 import DocumentationAPIService from "../../../../servicehandlers/DocumentationAPIService";
 import { base64BlackCert } from "./Images/BlackCertificate.js";
 import { base64RedCert } from "./Images/RedCertificate.js";
 import { nyalaNormalBase64 } from "./Fonts/nyala-normal";
 import { parchmentNormalBase64 } from "./Fonts/Parchment MF-normal";
 import { nyalaBoldBase64 } from "./Fonts/nyala-bold";

 const eventsAPIService = new EventsAPIService();
 const boardsAPIService = new BoardsAPIService();
 const outlineAPIService = new OutlineAPIService();
 const orderNumbersAPIService = new OrderNumbersAPIService();
 const supportOptionsAPIService = new SupportOptionsAPIService();
 const ranksAPIService = new RanksAPIService();
 const usersAPIService = new UsersAPIService();
 const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
 const directionsAPIService = new DirectionsAPIService();
 const modifiersAPIService = new ModifiersAPIService();
 const penaltiesAPIService = new PenaltiesAPIService();
 const poomsaeAPIService = new PoomsaeAPIService()
 const kicksAPIService = new KicksAPIService()
 const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService()
 const kickCombosAPIService = new KickCombosAPIService()
 const techniquesAPIService1 = new TechniquesAPIService()
 const terminologyAPIService = new TerminologyAPIService()
 const documentationAPIService = new DocumentationAPIService()
 import Outline from "../Outline/Outline";

 export default {
   name: "EventManager",
   components: {
     [Dialog.name]: Dialog,
     [Select.name]: Select,
     [Option.name]: Option,
     [Input.name]: Input,
     [Button.name]: Button,
     [Checkbox.name]: Checkbox,
     [Table.name]: Table,
     [TableColumn.name]: TableColumn,
     Outline,
     ModelSelect,
   },
   data() {
     return {
       event: {},
       event_id: null,
       drag: false,
       success_txt: null,
       error_txt: null,
       spinning: false,
       selectAll: false,
       fromId: null,
       toId: null,
       selected_user_outline: null,
       selected_user: null,
       outlines: [],
       selected_outlines: [],
       possible_user_outlines: [],
       order_numbers: [],
       filteredOrders: [],
       code: null,
       users: [],
       files: {},
       event_name: null,
       outlineSelected: [],
       events: [],
       search: '',

       modals: {
         error: false,
         success: false,
         person: false,
         certs: false,
       },

       // outline items
       userClass: null,
       user: null,

       // all dropdown items right here
       dropdown_ranks: [],
       dropdown_support_options: [],
       dropdown_boards: [],
       dropdown_directions: [],
       dropdown_techniques: [],
       checkbox_modifiers: [],
       dropdown_modifiers: [],
       board_techniques: [],
       board_technique_select_options: [],

       // storage objects to hold values in prior to calculating scores
       support_option_objects: {},
       rank_requirements: {},
       technique_objects: {},
       direction_objects: {},
       modifier_objects: {},
       board_size_objects: {},
       board_objects: {},
       penalties_objects: {},
       scores_objects: {},

       categories: [
         {
           id: 'breaking_techniques',
           name: 'Breaking(Green Tape)',
           items: [],
         },
         {
           id: 'poomsae',
           name: 'Poomsae(Black Tape)',
           items: [],
         },
         {
           id: 'kicks',
           name: 'Kicks(Blue Tape)',
           items: [],
         },
         {
           id: 'self_defense',
           name: 'Self Defence(Brown Tape)',
           items: [],
         },
         {
           id: 'kick_combos',
           name: 'Kick Combos(Red Tape)',
           items: [],
         },
         {
           id: 'techniques',
           name: 'Techniques',
           items: [],
         },
         {
           id: 'terminology',
           name: 'Yellow Tape(ADD PDF FOR MEDIA)',
           items: [],
         },
         {
           id: 'documentation',
           name: 'White (ADD PDF FOR MEDIA)',
           items: [],
         },
       ],
     }
   },
   async created() {
     this.spinning = true;
     this.event_id = this.$route.query.event_id;
     await this.getEventOutlines();
     await this.getEvent();
     await this.getRanks();
     await this.getModifiers();
     await this.getAllDirections();
     await this.getDirections();
     await this.getSupportOptions();
     await this.getBoards();
     await this.getPenalties();
     await this.getTechniques();
     await this.getUsers();
     this.spinning = false;
   },
   computed: {
     isChecked(){
       return () => {
         return this.selectAll
       }
     }
   },
   methods: {
     onEnd() {
       // go through all the order numbers, align with the indexes
       const promises = [];
       for (let i = 0; i < this.order_numbers.length; i += 1) {
         const id = this.order_numbers[i].id;
         this.order_numbers[i].order = i + 1;
         promises.push(orderNumbersAPIService.updateOrderNumber(id, {order: i + 1}, this.$router));
       }
       return Promise.all(promises)
       // now start updating both the old index and the new to the new values
       .then(() => {
       })
       .catch((error) => {
         Swal.fire({
           title: 'Error',
           text: 'Error updating order! ' + error,
           timer: 1000,
           confirmButtonClass: 'btn btn-danger btn-fill',
           showConfirmButton: false
         });
       });
     },
     deleteCode(id, name, code) {
       this.spinning = true;
       if (!id) {
         this.error_txt = 'No user was selected';
         this.openModal('error');
         this.spinning = false;
         return;
       }
       if (!name) {
         this.error_txt = 'No name was selected';
         this.openModal('error');
         this.spinning = false;
         return;
       }
       if (!code) {
         this.error_txt = 'No code was selected';
         this.openModal('error');
         this.spinning = false;
         return;
       }

       Swal.fire({
         title: 'Are you sure?',
         text: name + ' ' + 'will be deleted for code ' + code,
         type: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Delete',
         cancelButtonText: 'Cancel',
         confirmButtonClass: 'btn btn-success btn-fill',
         cancelButtonClass: 'btn btn-danger btn-fill',
         buttonsStyling: false
       }).then(function (dismiss) {
         if (dismiss.isDismissed) {
           // this.spinning = false;
           Swal.fire({
             title: 'Cancelled',
             text: 'Cancelled',
             timer: 1000,
             confirmButtonClass: 'btn btn-success btn-fill',
             buttonsStyling: false
           });
           return false;
         } else {
           // do the deletions
           return orderNumbersAPIService.deleteOrderNumber(id, null);
         }
       })
       .then(async (trueorfalse) => {
         await this.getEventOutlines();
         if (trueorfalse) {
           Swal.fire({
             title: 'Success',
             text: 'Deletion Successful',
             timer: 1000,
             confirmButtonClass: 'btn btn-success btn-fill',
             buttonsStyling: false
           });
           this.updateCompetitorOrder()
         }
         this.spinning = false;
       })
       .catch((error) => {
         Swal.fire({
           title: 'Error',
           text: 'Error deleting code! ' + error,
           timer: 1000,
           confirmButtonClass: 'btn btn-danger btn-fill',
           showConfirmButton: true
         });
         this.spinning = false;
       });
     },
     savePerson(name) {
       this.spinning = true;
       // save the code into the DB for the user
       if (!this.selected_user) {
         this.error_txt = 'No user was selected';
         this.openModal('error');
         this.spinning = false;
         return;
       }
       if (!this.code) {
         this.error_txt = 'No code was entered';
         this.openModal('error');
         this.spinning = false;
         return;
       }
       const order_number = {
         code: this.code,
         event_id: this.event_id,
         user_id: this.selected_user,
       };
       if (this.selected_user_outline) {
         order_number.outline_id = this.selected_user_outline;
       }

       return orderNumbersAPIService.addOrderNumber(order_number, this.$router)
       .then(async () => {
         this.selected_user_outline = null;
         this.code = null;
         this.possible_user_outlines = [];
         this.selected_user = null;
         await this.getEventOutlines();
         this.closeModal(name);
         this.spinning = false;
         Swal.fire({
           title: 'Success',
           text: 'Successfully inserted user.',
           timer: 1000,
           showConfirmButton: true
         });
         this.updateOrder()
       })
       .catch((error) => {
         this.error_txt = 'Error creating order for user: ' + error;
         this.openModal('error');
         this.spinning = false;
       });
     },
     getOutlinesForUser() {
       this.possible_user_outlines = [];
       if (!this.selected_user) {
         this.error_txt = 'No user was selected';
         this.openModal('error');
         return;
       }
       this.spinning = true;
       return outlineAPIService.getOutlinesSimple(this.selected_user, this.$router)
       .then((userOutlines) => {
         for (const outline of userOutlines) {
           this.possible_user_outlines.push({
             text: outline.description,
             value: outline.id
           });
         }
         this.spinning = false;
       })
       .catch((error) => {
         this.spinning = false;
         this.error_txt = 'Error getting users: ' + error;
         this.openModal('error');
       });
     },
     addPerson() {
       this.openModal('person');
     },
     async getUsers() {
       this.users = [];
       return usersAPIService.getUserList(this.$router)
       .then((allUsers) => {
         for (const user of allUsers) {
           this.users.push({
             text: user.first_name + ' ' + user.last_name,
             value: user.id,
           });
         }
       })
       .catch((error) => {
         this.error_txt = 'Error getting users: ' + error;
         this.openModal('error');
       });
     },
     // sets the technique name for the scoring view
     setTechniqueNames(outline) {
       if (outline && outline.breaking_techniques_to_outlines) {
         for (const techItem of outline.breaking_techniques_to_outlines) {
           techItem.techName = '';
           for (const modifier of this.dropdown_support_options) {
             if (modifier.id === techItem.dropdown_modifier_id) {
               techItem.techName += modifier.description + ' ';
             }
           }
           if (techItem.breaking_technique && techItem.breaking_technique.name) {
             techItem.techName += techItem.breaking_technique.name + ', ';
           }
           if (techItem.breaking_technique && techItem.breaking_technique.directions) {
             for (const techdir of techItem.breaking_technique.directions) {
               if (techdir && techdir.id !== null && techdir.id === techItem.direction_id) {
                 if (techdir.name !== 'N/A') {
                   techItem.techName += techdir.name + ', ';
                 }
               }
             }
           }
           if (techItem.blindfolded) {
             techItem.techName += 'Blindfolded, ';
           }
           if (techItem.self_held) {
             techItem.techName += 'Self Held, ';
           }
           if (techItem.simultaneous) {
             techItem.techName += 'Simultaneous, ';
           }
           if (techItem.successive) {
             techItem.techName += 'Successive, ';
           }
           if (techItem.no_spacers) {
             techItem.techName += 'No Spacers, ';
           }
           techItem.techName = techItem.techName.replace(/,\s*$/, "");
           if (techItem.additional_boards === 1) {
             techItem.techName += ' 1 Additional Board';
           } else if (techItem.additional_boards > 1) {
             techItem.techName += techItem.additional_boards + ' Additional Boards';
           }
         }
       }
     },
     // get al the modifiers used in dropdowns
     getModifiers() {
       return modifiersAPIService.getModifiers(this.$router)
       .then((modifiers) => {
         for (const modifier of modifiers) {
           modifier.text = modifier.description;
           modifier.value = modifier.id;
           this.checkbox_modifiers.push(modifier);
         }
       })
       .catch((error) => {
         this.error_txt = 'Error getting modifiers for dropdown: ' + error;
         this.openModal('error');
       });
     },
     setTechniqueSelections(outline) {
       const technique_objects = {};
       if (outline && outline.breaking_techniques_to_outlines) {
         for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
           const id = technique_to_outline.id;
           if (technique_to_outline.breaking_technique_id && technique_to_outline.breaking_technique) {
             const technique = technique_to_outline.breaking_technique;
             const base_point_value = parseInt(technique.base_point_value);
             const board_multiplier = parseInt(technique.board_multiplier);
             const a_minus = parseInt(technique.a_minus);
             const isPowerBreak = technique.is_power_break;
             technique_objects[id] = {};
             technique_objects[id] = {
               'base_point_value': base_point_value,
               'board_multiplier': board_multiplier,
               'a_minus': a_minus,
               'is_power_break': isPowerBreak,
             };
           } else {
             technique_objects[id] = {
               'base_point_value': 0,
               'board_multiplier': 0,
               'a_minus': 0,
               'is_power_break': false,
             }
           }
         }
       }
       return technique_objects;
     },
     setDirectionSelections(outline) {
       const direction_objects = {};
       if (outline && outline.breaking_techniques_to_outlines) {
         for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
           const id = technique_to_outline.id;
           const direction_id = technique_to_outline.direction_id;
           direction_objects[id] = {};
           direction_objects[id] = {
             'direction_id': direction_id,
             'a_minus': 0,
             'additional_point_value': 0
           };
           if (direction_id) {
             for (const direction of this.allDirections) {
               if (direction_id === direction.id) {
                 const a_minus = parseInt(direction.a_minus);
                 const additional_point_value = parseInt(direction.additional_point_value);
                 direction_objects[id] = {
                   'direction_id': direction_id,
                   'a_minus': a_minus,
                   'additional_point_value': additional_point_value
                 };
               }
             }
           }
         }
       }
       return direction_objects;
     },
     setBoardSizeObjects(outline) {
       const board_size_objects = {};
       if (outline && outline.breaking_techniques_to_outlines) {
         for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
           const id = technique_to_outline.id;
           board_size_objects[id] = {};
           board_size_objects[id] = {
             order: 1,
             name: '',
           };
           const board_id = technique_to_outline.board_id;
           for (const board of this.dropdown_boards) {
             if (board_id === board.id) {
               board_size_objects[id] = {
                 order: board.order,
                 name: board.name,
               };
             }
           }
         }
       }
       return board_size_objects;
     },
     setModifiers(outline) {
       const modifier_objects = {};
       let successive_mods = 0;
       // sets each modifier in the objects
       if (outline && outline.breaking_techniques_to_outlines) {
         for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
           const id = technique_to_outline.id;
           // set the modifiers to nothing for starting out
           modifier_objects[id] = [];

           if (technique_to_outline.blindfolded) {
             technique_to_outline.speed_break = false;
             for (const modifier of this.checkbox_modifiers) {

               if (modifier && (modifier.name === 'blindfolded')) {
                 const a_minus = parseInt(modifier.a_minus);
                 const point_value = parseInt(modifier.point_value);
                 const name = modifier.description;
                 const mod_item = {
                   'a_minus': a_minus,
                   'point_value': point_value,
                   'name': name,
                 };
                 modifier_objects[id].push(mod_item);
               }
             }
           }

           if (technique_to_outline.self_held) {
             for (const modifier of this.checkbox_modifiers) {
               if (modifier && (modifier.name === 'self_held')) {
                 const a_minus = parseInt(modifier.a_minus);
                 const point_value = parseInt(modifier.point_value);
                 const name = modifier.description;
                 const mod_item = {
                   'a_minus': a_minus,
                   'point_value': point_value,
                   'name': name,
                 };
                 modifier_objects[id].push(mod_item);
               }
             }
           }
           if (technique_to_outline.simultaneous) {
             for (const modifier of this.checkbox_modifiers) {
               if (modifier && (modifier.name === 'simultaneous')) {
                 const a_minus = parseInt(modifier.a_minus);
                 const point_value = parseInt(modifier.point_value);
                 const name = modifier.description;
                 const mod_item = {
                   'a_minus': a_minus,
                   'point_value': point_value,
                   'name': name,
                 };
                 modifier_objects[id].push(mod_item);
               }
             }
           }

           // if marked successive, give them 1 point per mark
           if (technique_to_outline.successive) {
             for (const modifier of this.checkbox_modifiers) {
               if (modifier && (modifier.name === 'successive')) {
                 successive_mods += 1;
                 if (successive_mods === 1) {
                   const mod_item = {
                     'name': modifier.description,
                     'a_minus': 2,
                     'point_value': 0,
                   };
                   // technique_item.board_plus_minus += 2;
                   modifier_objects[id].push(mod_item);
                 } else if (successive_mods === 2) {
                   const mod_item = {
                     'name': modifier.description,
                     'a_minus': 2,
                     'point_value': 1,
                   };
                   modifier_objects[id].push(mod_item);
                 } else if (successive_mods >= 3) {
                   const mod_item = {
                     'name': modifier.description,
                     'a_minus': 2,
                     'point_value': 3,
                   };
                   modifier_objects[id].push(mod_item);
                 }
               }
             }
           }
           if (technique_to_outline.no_spacers) {
             for (const modifier of this.checkbox_modifiers) {
               if (modifier && (modifier.name === 'no_spacers')) {
                 const a_minus = parseInt(modifier.a_minus);
                 const point_value = parseInt(modifier.point_value);
                 const name = modifier.name;
                 const mod_item = {
                   'a_minus': a_minus,
                   'point_value': point_value,
                   'name': name,
                 };
                 modifier_objects[id].push(mod_item);
               }
             }
           }
         }
       }
       return modifier_objects;
     },
     setPenalties(outline) {
       const penalties_objects = {};
       if (outline && outline.breaking_techniques_to_outlines) {
         for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
           const id = technique_to_outline.id;
           penalties_objects[id] = [];
           if (technique_to_outline.attempts >= 1) {
             for (const penalty of this.penalties) {
               if (penalty && (penalty.name === 'attempts')) {
                 const penalty_point_value = parseInt(penalty.point_value);
                 const attempts = parseInt(technique_to_outline.attempts);
                 const tmpPenalty = {
                   point_value: penalty_point_value,
                   attempts,
                   name: penalty.name,
                 };
                 penalties_objects[id].push(tmpPenalty);
               }
             }
           }

           if (technique_to_outline.changed_break) {
             for (const penalty of this.penalties) {
               if (penalty && (penalty.name === 'changed_break')) {
                 const penalty_point_value = parseInt(penalty.point_value);
                 const tmpPenalty = {
                   point_value: penalty_point_value,
                   name: penalty.name,
                 };
                 penalties_objects[id].push(tmpPenalty);
               }
             }
           }

           if (parseInt(technique_to_outline.dropped_a_board) > 0) {
             for (const penalty of this.penalties) {
               if (penalty && (penalty.name === 'dropped_board')) {
                 const penalty_point_value = parseInt(penalty.point_value);
                 const tmpPenalty = {
                   point_value: penalty_point_value,
                   name: penalty.name,
                 };
                 penalties_objects[id].push(tmpPenalty);
               }
             }
           }

           if (technique_to_outline.partial_break) {
             for (const penalty of this.penalties) {
               if (penalty && (penalty.name === 'partial_break')) {
                 const penalty_point_value = parseInt(penalty.point_value);
                 const tmpPenalty = {
                   point_value: penalty_point_value,
                   name: penalty.name,
                 };
                 penalties_objects[id].push(tmpPenalty);
               }
             }
           }
           if (technique_to_outline.didnt_break) {
             for (const penalty of this.penalties) {
               if (penalty && (penalty.name === 'no_break')) {
                 const penalty_point_value = parseInt(penalty.point_value);
                 const tmpPenalty = {
                   point_value: penalty_point_value,
                   name: penalty.name,
                 };
                 penalties_objects[id].push(tmpPenalty);
               }
             }
           }
         }
       }
       return penalties_objects;
     },
     setRankOptions(outline) {
       let rank_requirements = {};
       const rank_id = outline.rank_id;
       for (const rank of this.dropdown_ranks) {
         if (rank.id === rank_id) {
           rank_requirements = rank;
         }
       }
       return rank_requirements;
     },
     setBoards(outline, scores_object) {
       const board_objects = {};
       // loop through each of the technique to outlines
       // figure out how many boards we have, add them together
       if (outline && outline.breaking_techniques_to_outlines) {
         for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
           if (technique_to_outline.breaking_technique) {
             board_objects[technique_to_outline.id] = {};
             board_objects[technique_to_outline.id].additional_boards = 0;
             const technique = technique_to_outline.breaking_technique;
             const num_boards = parseInt(technique_to_outline.num_boards);
             const board_multiplier = parseInt(technique.board_multiplier);
             board_objects[technique_to_outline.id].additional_boards = (num_boards / board_multiplier) - 1;
             scores_object.total_boards += num_boards;
           }
         }
       }
       return board_objects;
     },
     setPresentation(outline) {
       let presentation = 0;
       let judgeScores = [];
       let allJudges = true;
       const j1 = parseFloat(outline.j1);
       const j2 = parseFloat(outline.j2);
       const j3 = parseFloat(outline.j3);
       const j4 = parseFloat(outline.j4);
       const j5 = parseFloat(outline.j5);

       if (j1 > 0) {
         judgeScores.push(j1);
       } else {
         allJudges = false;
       }
       if (j2 > 0) {
         judgeScores.push(j2);
       } else {
         allJudges = false;
       }
       if (j3 > 0) {
         judgeScores.push(j3);
       } else {
         allJudges = false;
       }
       if (j4 > 0) {
         judgeScores.push(j4);
       } else {
         allJudges = false;
       }
       if (j5 > 0) {
         judgeScores.push(j5);
       } else {
         allJudges = false;
       }

       if (allJudges) {
         const min = Math.min(...judgeScores);
         const max = Math.max(...judgeScores);
         let min_dropped = false;
         let max_dropped = false;
         for (let i = 0; i < judgeScores.length; i += 1) {
           let judge_score = judgeScores[i];
           if (min === judge_score && !min_dropped) {
             judgeScores.splice(i, 1);
             min_dropped = true;
           }
           if (max === judge_score && !max_dropped) {
             judgeScores.splice(i, 1);
             max_dropped = true;
           }
         }
       }
       const sum = judgeScores.reduce((a, b) => a + b, 0);
       presentation = (sum / judgeScores.length) || 0;
       return presentation;
     },
     setSupportOptions(outline, scores_objects) {
       let support_option_objects = {};
       // loop through the modifiers
       if (outline && outline.breaking_techniques_to_outlines) {
         for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
           technique_to_outline.speed_break_color = 'col-sm-2 text-center';
           const dropdown_modifier_id = technique_to_outline.dropdown_modifier_id;
           if (technique_to_outline.breaking_technique) {
             const id = technique_to_outline.id;
             support_option_objects[id] = {};
             support_option_objects[id] = {
               a_minus: 0,
               point_value: 0,
             };
             for (const modifier of this.dropdown_support_options) {
               if (modifier && (modifier.id === dropdown_modifier_id)) {
                 support_option_objects[id].a_minus = modifier.a_minus;
                 support_option_objects[id].point_value = modifier.point_value;
               }
               const support_option_id = technique_to_outline.dropdown_modifier_id;
               if (modifier.speed_break && modifier.id === support_option_id) {
                 technique_to_outline.speed_break = modifier.speed_break;
                 scores_objects.total_speed_breaks += 1;
               }
             }
           }
         }
       }
       return support_option_objects;
     },
     // now that we have all the objects and values, calculate the final scores
     calculateScoresForOutline(outline) {
       // storage objects to hold values in prior to calculating scores
       const scores_objects = {};
       scores_objects.pre_final_technical_score = 0;
       scores_objects.final_technical_score = 0;
       scores_objects.final_technical_score_length = 0;
       scores_objects.total_line_scores = 0;
       scores_objects.avg_total_line_scores = 0;
       scores_objects.final_score = 0;
       scores_objects.total_boards = 0;
       scores_objects.total_speed_breaks = 0;
       scores_objects.userClass = 6;
       scores_objects.presentation = 0;
       const userClass = this.setUserClass(outline.weight, outline.user);
       scores_objects.userClass = userClass;
       const presentation = this.setPresentation(outline);
       const board_objects = this.setBoards(outline, scores_objects);
       const board_size_objects = this.setBoardSizeObjects(outline);
       const support_option_objects = this.setSupportOptions(outline, scores_objects);
       const technique_objects = this.setTechniqueSelections(outline);
       const direction_objects = this.setDirectionSelections(outline);
       const modifier_objects = this.setModifiers(outline);
       const penalties_objects = this.setPenalties(outline);

       // first setup a scores obj we'll send back
       const outline_techniques = outline.breaking_techniques_to_outlines
       for (const technique_to_outline of outline_techniques) {
         // calculate each line of technique outlines
         const id = technique_to_outline.id;
         scores_objects[id] = {};
         scores_objects[id] = {
           a_minus: 0,
           starting_line_score: 0,
           base_point_value: 0,
           average_board_size: 0,
           additional_points: 0,
           line_score: 0,
           penalties: 0,
           total_score: 0,
           pre_final_technical_score: 0,
           final_technical_score: 0,
           attempts: 0,
           presentation: presentation,
         };
         // initializers
         scores_objects[id].average_board_size = userClass - technique_objects[id].a_minus;
         scores_objects[id].starting_line_score = technique_objects[id].base_point_value +
           board_size_objects[id].order - scores_objects[id].average_board_size;

         // first, get all the a-minuses
         // this.scores_objects[id].a_minus = this.userClass - this.board_size_objects[id].order;
         scores_objects[id].a_minus += support_option_objects[id].a_minus;
         // this.scores_objects[id].a_minus += this.technique_objects[id].a_minus;
         scores_objects[id].a_minus += direction_objects[id].a_minus;
         const modifiers = modifier_objects[id];
         for (const modifier of modifiers) {
           if (modifier.a_minus) {
             scores_objects[id].a_minus += modifier.a_minus;
           }
         }

         // Build the possible score calculations now
         // this.scores_objects[id].possible_score = this.technique_objects[id].base_point_value;
         scores_objects[id].additional_points += (board_objects[id].additional_boards * 2);
         scores_objects[id].additional_points += support_option_objects[id].point_value;
         scores_objects[id].additional_points += direction_objects[id].additional_point_value;

         let no_spacers = false;
         for (const modifier of modifiers) {
           if (modifier.name === 'no_spacers') {
             no_spacers = true;
             scores_objects[id].additional_points += (board_objects[id].additional_boards * 1);
           } else {
             if (modifier.point_value) {
               scores_objects[id].additional_points += modifier.point_value;
             }
           }
         }

         let partial_break = false;
         let drop_board = false;
         let no_break = false;
         const penaltyObj = penalties_objects[id];
         for (const penalty of penaltyObj) {
           let attempts = 0;
           if (penalty.name === 'attempts') {
             attempts += penalty.attempts;
             scores_objects[id].penalties += (attempts);
           }
           if (penalty.name === 'changed_break') {
             scores_objects[id].penalties += penalty.point_value;
           }
           if (penalty.name === 'partial_break') {
             partial_break = true;
           }
           if (penalty.name === 'no_break') {
             no_break = true;
           }
           if (penalty.name === 'dropped_board') {
             drop_board = true;
           }
         }

         // now calculate the possible score
         scores_objects[id].line_score = scores_objects[id].starting_line_score +
           scores_objects[id].a_minus + scores_objects[id].additional_points;
         scores_objects.total_line_scores += scores_objects[id].line_score;
         scores_objects.total_average_score = ((scores_objects.total_line_scores
           / outline_techniques.length) || 0).toFixed(2);

         // now calculate the possible score
         scores_objects[id].total_score = scores_objects[id].line_score;

         if (no_break) {
           scores_objects[id].total_score = 0;
           scores_objects[id].penalties += scores_objects[id].line_score;
         }

         if (drop_board) {
           // for each board dropped it will give 2 points of penalty
           if (no_spacers) {
             scores_objects[id].penalties += (parseInt(technique_to_outline.dropped_a_board) * 2);
             scores_objects[id].penalties += parseInt(technique_to_outline.dropped_a_board);
           } else {
             scores_objects[id].penalties += (parseInt(technique_to_outline.dropped_a_board) * 2);
           }
         }

         if (partial_break) {
           scores_objects[id].penalties += scores_objects[id].line_score / 2;
         }

         // now calculate the total scores for each line
         scores_objects[id].total_score = scores_objects[id].line_score - scores_objects[id].penalties;
         if (scores_objects[id].total_score < 0) {
           scores_objects[id].total_score = 0;
         }
         scores_objects.final_technical_score_length += 1;
         scores_objects.pre_final_technical_score += scores_objects[id].total_score;
         scores_objects.presentation = scores_objects[id].presentation
       }

       // calculate the technical score
       if (scores_objects.final_technical_score_length > 0) {
         scores_objects.final_technical_score = (scores_objects.pre_final_technical_score /
           scores_objects.final_technical_score_length);
       } else {
         scores_objects.final_technical_score = 0;
       }

       // calculate the avg of the total line scores
       if (scores_objects.final_technical_score_length > 0) {
         scores_objects.avg_total_line_scores = (scores_objects.total_line_scores /
           scores_objects.final_technical_score_length);
       } else {
         scores_objects.avg_total_line_scores = 0;
       }

       scores_objects.final_score = scores_objects.final_technical_score + scores_objects.presentation;
       this.setBBTReqs(outline, scores_objects, userClass, board_objects, technique_objects);
       return scores_objects;
     },
     // get the penalties used in equations
     getPenalties() {
       return penaltiesAPIService.getPenalties(this.$router)
       .then((penalties) => {
         this.penalties = penalties;
       })
       .catch((error) => {
         this.error_txt = 'Error getting penalties: ' + error;
         this.openModal('error');
       });
     },
     // get the boards used in all dropdowns
     getBoards() {
       return boardsAPIService.getBoards(this.$router)
       .then((boards) => {
         for (let i = 0; i < boards.length; i += 1) {
           boards[i].text = boards[i].name;
           boards[i].value = boards[i].id;
         }
         const tmpBoard = {
           value: null,
           text: 'N/A',
           id: null,
           name: 'N/A'
         };
         boards.unshift(tmpBoard);
         this.dropdown_boards = boards;
       })
       .catch((error) => {
         this.error_txt = 'Error getting boards for dropdown: ' + error;
         this.openModal('error');
       });
     },
     lockOutline(outline_id) {
       this.spinning = true;
       const outline = {
         locked: true,
       };
       return outlineAPIService.updateSingleOutline(outline_id, outline, this.$router)
       .then(() => {
         this.spinning = false;
         this.success_txt = 'Lock Successful';
         this.openModal('success');
         this.getEventOutlines();
       })
       .catch((error) => {
         this.spinning = false;
         this.error_txt = 'Lock failed: ' + error;
         this.openModal('error');
       });
     },
     setUserClass(weight, user) {
       let userClass = 6;
       if (!user.weight) {
         user.weight = 99999;
       }
       if (!weight) {
         weight = parseFloat(user.weight);
       }
       if (weight >= 35 && weight <= 49.9) {
         userClass = 1;
       } else if (weight >= 50 && weight <= 70.5) {
         userClass = 2;
       } else if (weight >= 70.6 && weight <= 96.6) {
         userClass = 3;
       } else if (weight >= 96.7 && weight <= 127.5) {
         userClass = 4;
       } else if (weight >= 127.6 && weight <= 164.9) {
         userClass = 5;
       } else if (weight >= 165) {
         userClass = 6;
       } else {
         userClass = 6;
       }
       return userClass;
     },

     setBBTReqs(outline, scores_objects, userClass, board_objects, technique_objects) {
       scores_objects.total_power_breaks = 0;
       for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
         let technique_id = null;
         if (technique_to_outline.breaking_technique_id) {
           technique_id = technique_to_outline.breaking_technique_id;
         }
         let board_id = null;
         if (technique_to_outline.board_id) {
           board_id = technique_to_outline.board_id;
         }
         // first get the board size of the technique_to_outline
         let board_size = 0;
         for (const board of this.dropdown_boards) {
           if (board.id === board_id) {
             board_size = parseInt(board.order);
           }
         }

         let possible_power_break = false;
         for (const technique of this.dropdown_techniques) {
           if (technique_id === technique.id) {
             if (technique.is_power_break) {
               possible_power_break = true;
             }
           }
         }

         // this.board_objects[technique_to_outline.id].additional_boards = (num_boards / board_multiplier) - 1;
         // order - weight class is if it's a power break or not
         if (possible_power_break) {
           let power_break_num = board_size - userClass;
           if (power_break_num >= 2) {
             // yay, power break!
             scores_objects.total_power_breaks += 1;
             technique_to_outline.color = 'col-sm-1 text-center border border-primary';
           } else if (power_break_num >= 0) {
             technique_to_outline.color = 'col-sm-1 text-center';
             if (board_objects[technique_to_outline.id].additional_boards >= 1) {
               scores_objects.total_power_breaks += 1;
               technique_to_outline.color = 'col-sm-1 text-center border border-primary';
             }
           } else if (power_break_num < 0) {
             power_break_num += (scores_objects[technique_to_outline.id].a_minus +
               technique_objects[technique_to_outline.id].a_minus);
             if (power_break_num < 0) {
               technique_to_outline.color = 'col-sm-1 text-center border border-warning';
             } else {
               technique_to_outline.color = 'col-sm-1 text-center';
             }
           } else {
             technique_to_outline.color = 'col-sm-1 text-center';
           }
         }
       }
     },
     async printScores() {
       let scores_objects = {};
       this.spinning = true;
       // pull the required items we will be needing from the DB
       const outlines = [];

       for (const id of this.selected_outlines) {
         await outlineAPIService.getOutline(id, this.$router)
         .then((outline) => {
           outlines.push(outline);
         })
         .catch((error) => {
           this.error_txt = 'Lock failed: ' + error;
           this.openModal('error');
         });
       }
       return Promise.all(outlines)
       .then(() => {
         return outlineAPIService.getBase64File('score_view_items.png', this.$router)
       })
       .then((response) => {
         const doc = new jsPDF({
           orientation: "landscape",
           format: 'letter',
         });
         const imgData = response;

         for (const outline of outlines) {
           scores_objects = {};
           this.setTechniqueNames(outline);
           // get all the scores into a nice bundle so we can print it
           scores_objects = this.calculateScoresForOutline(outline);

           let x = 5;
           let y = 10;
           doc.setFontSize('12');
           doc.text(outline.user.first_name + ' ' + outline.user.last_name + '-' + outline.weight + ' lbs,  Weight Class ' + scores_objects.userClass, x, y);
           x = 118;
           doc.setFontSize('20');
           doc.text(outline.description, x, y);
           doc.setFontSize('12');
           y += 5;
           x = 10;
           const rank_requirements = this.setRankOptions(outline);
           doc.text('Required Number of Breaks: ' + rank_requirements.number_of_breaks, x, y);
           x += 70;
           doc.text('Required Speed Breaks: ' + rank_requirements.required_speed_breaks, x, y);
           x += 60;
           doc.text('Required Power Breaks: ' + rank_requirements.required_power_breaks, x, y);
           x += 60;
           doc.text('Breaking Point Minimum: ' + rank_requirements.breaking_point_minimum, x, y);
           x = 10;
           y += 5;

           // now display the requirements met objects
           if (outline.required_number_breaks) {
             doc.setTextColor(0, 255, 0);
             doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
           } else {
             doc.setTextColor(255, 0, 0);
             doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
           }
           x += 70;

           if (outline.required_speed_breaks) {
             doc.setTextColor(0, 255, 0);
             doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
           } else {
             doc.setTextColor(255, 0, 0);
             doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
           }
           x += 60;

           if (outline.required_power_breaks) {
             doc.setTextColor(0, 255, 0);
             doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
           } else {
             doc.setTextColor(255, 0, 0);
             doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
           }
           x += 60;

           if (outline.required_minimum_score) {
             doc.setTextColor(0, 255, 0);
             doc.text('Have: ' + scores_objects.total_average_score, x, y);
           } else {
             doc.setTextColor(255, 0, 0);
             doc.text('Have: ' + scores_objects.total_average_score, x, y);
           }

           doc.setTextColor(0, 0, 0);
           doc.setFontSize('8');

           x = 10;
           y += 30;

           x = 10;
           y = 35;

           doc.setFontSize('8');
           // set each technique column up, one by one
           doc.text('Order', x, y);
           x += 17;
           doc.text('Technique', x, y);
           x += 85;
           doc.text('Board Size', x, y);
           x += 23;
           doc.text('# of Boards', x, y);
           x += 23;
           doc.text('Attempts', x, y);
           x += 15;
           doc.addImage(imgData, 'PNG', x, 22, 50, 20);
           x += 50;
           let tmp_x = x;
           let tmp_y = y + 3;
           doc.text('Possible', x, y);
           doc.text('Score', tmp_x, tmp_y);
           x += 15;
           doc.text('Penalties', x, y);
           x += 15;
           doc.text('Technical', x, y);
           tmp_x = x;
           tmp_y = y + 3;
           doc.text('Score', tmp_x, tmp_y);
           x += 17;
           // doc.text('Final Score', x, y);
           // x += 17;

           for (const technique of outline.breaking_techniques_to_outlines) {
             const board_id = technique.board_id;
             x = 10;
             y += 10;
             doc.text(technique.order.toString(), x, y);
             x += 17;
             doc.text(technique.techName.toString(), x, y);
             x += 85;
             for (const board of this.dropdown_boards) {
               if (board.id === board_id) {
                 doc.text(board.name, x, y);
                 break;
               }
             }
             x += 23;

             doc.text(technique.num_boards.toString(), x, y);
             x += 24;
             doc.text(technique.attempts.toString(), x, y);
             x += 17;
             doc.rect(x, y - 3, 5, 5);
             if (technique.changed_break) {
               doc.text('X', x + 2, y + 1);
             }
             x += 13;
             doc.rect(x, y - 3, 5, 5);
             if (technique.dropped_a_board) {
               doc.text('X', x + 2, y + 1);
             }
             x += 13;
             doc.rect(x, y - 3, 5, 5);
             if (technique.partial_break) {
               doc.text('X', x + 2, y + 1);
             }
             x += 13;
             doc.rect(x, y - 3, 5, 5);
             if (technique.didnt_break) {
               doc.text('X', x + 2, y + 1);
             }
             x += 10;

             doc.text(scores_objects[technique.id].line_score.toString(), x, y);
             x += 15;
             doc.text(scores_objects[technique.id].penalties.toString(), x, y);
             x += 15;
             doc.text(scores_objects[technique.id].total_score.toString(), x, y);
             //x += 17;
             // doc.text(this.scores_objects[technique.id].total_score.toString(), x, y);
           }

           // total boards
           y += 7;
           x = 10 + 17 + 75 + 23;
           doc.text("Total Boards: " + scores_objects.total_boards.toString(), x, y);
           x += 24 + 10 + 10 + 12 + 12 + 11;
           doc.text('Possible Score: ' + scores_objects.total_average_score.toString(), x, y);
           x += 12 + 15 + 8;
           doc.text('Tech Score: ' + scores_objects.final_technical_score.toString(), x, y);

           y += 10;
           x = 40;

           // second part, totals
           doc.text('J1', x, y);
           x += 15;
           doc.text('J2', x, y);
           x += 15;
           doc.text('J3', x, y);
           x += 15;
           doc.text('J4', x, y);
           x += 15;
           doc.text('J5', x, y);
           x += 15;
           doc.text('Time', x, y);
           x += 15;
           doc.text('Presentation', x, y);
           x += 45;
           doc.text('Technical Score', x, y);
           x += 25;
           doc.text('Final Score', x, y);

           y += 10;
           x = 40;

           if (!outline.j1) {
             outline.j1 = 0;
           }
           if (!outline.j2) {
             outline.j2 = 0;
           }
           if (!outline.j3) {
             outline.j3 = 0;
           }
           if (!outline.j4) {
             outline.j4 = 0;
           }
           if (!outline.j5) {
             outline.j5 = 0;
           }
           doc.text(outline.j1.toString(), x, y);
           x += 15;
           doc.text(outline.j2.toString(), x, y);
           x += 15;
           doc.text(outline.j3.toString(), x, y);
           x += 15;
           doc.text(outline.j4.toString(), x, y);
           x += 15;
           doc.text(outline.j5.toString(), x, y);
           x += 15;
           if (!outline.time) {
             outline.time = 0;
           }
           doc.text(outline.time.toString(), x, y);
           x += 15;
           if (!scores_objects.presentation) {
             scores_objects.presentation = 0;
           }
           doc.text(scores_objects.presentation.toFixed(2).toString(), x, y);
           x += 45;
           if (!scores_objects.total_score) {
             scores_objects.total_score = 0;
           }
           scores_objects.final_technical_score = parseInt(scores_objects.final_technical_score);
           doc.text(scores_objects.final_technical_score.toString(), x, y);
           x += 25;
           if (!scores_objects.total_score) {
             scores_objects.total_score = 0;
           }
           if (scores_objects.total_average_score) {
             scores_objects.total_average_score = parseFloat(scores_objects.total_average_score);
             scores_objects.total_average_score = scores_objects.total_average_score.toFixed(2);
           } else {
             scores_objects.total_average_score = 0;
           }
           doc.text(scores_objects.total_average_score.toString(), x, y);

           // now put the totals in at the bottom
           y += 10;
           x = 10;

           const boards = {};
           doc.text('Total Board Breakdown: ', x, y);
           for (const board of this.dropdown_boards) {
             boards[board.id] = {
               name: board.name,
               total: 0,
             };
           }
           y += 6;
           for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
             // first get the number of the board, add all them together
             boards[technique_to_outline.board_id].total += parseInt(technique_to_outline.num_boards);
           }
           const keys = Object.keys(boards);
           for (const key of keys) {
             const board = boards[key];
             if (board.name !== 'N/A') {
               x = 10;
               doc.text(board.name + 's - ' + board.total.toString(), x, y);
               y += 6;
             }
           }
           x += 24;

           if (outlines.indexOf(outline) !== outlines.length-1) {
             doc.addPage();
           }
         }
         this.spinning = false;
         doc.save("scoreview.pdf");
       })
       .catch((error) => {
         this.spinning = false;
         this.error_txt = 'Error adding row: ' + error;
         this.openModal('error');
       });
     },
     async printOutlines() {
       let scores_objects = {};
       this.spinning = true;
       // pull the required items we will be needing from the DB
       const outlines = [];

       for (const id of this.selected_outlines) {
         await outlineAPIService.getOutline(id, this.$router)
         .then((outline) => {
           outlines.push(outline);
         })
         .catch((error) => {
           this.error_txt = 'Lock failed: ' + error;
           this.openModal('error');
         });
       }
       return Promise.all(outlines)
       .then(() => {
         return outlineAPIService.getBase64File('pdf_titles.png', this.$router)
       })
       .then((response) => {
         const doc = new jsPDF({
           orientation: "landscape",
           format: 'letter',
         });
         doc.setTextColor(0, 0, 0);
         const imgData = response;

         for (const outline of outlines) {
           scores_objects = {};
           this.setTechniqueNames(outline);
           // get all the scores into a nice bundle so we can print it
           scores_objects = this.calculateScoresForOutline(outline);
           const rank_requirements = this.setRankOptions(outline);

           // set the description in the center
           let x = 118;
           let y = 10;
           doc.text(outline.description, x, y);
           x = 10;
           y = 10;

           doc.setFontSize('12');
           doc.text(outline.user.first_name + ' ' + outline.user.last_name + '-' + outline.weight + ' lbs,  Weight Class ' + scores_objects.userClass, x, y);

           y += 5;
           x = 10;
           doc.text('Required Number of Breaks: ' + rank_requirements.number_of_breaks, x, y);
           x += 70;
           doc.text('Required Speed Breaks: ' + rank_requirements.required_speed_breaks, x, y);
           x += 60;
           doc.text('Required Power Breaks: ' + rank_requirements.required_power_breaks, x, y);
           x += 60;
           doc.text('Breaking Point Minimum: ' + rank_requirements.breaking_point_minimum, x, y);
           x = 10;
           y += 5;

           // now display the requirements met objects
           if (outline.required_number_breaks) {
             doc.setTextColor(0, 255, 0);
             doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
           } else {
             doc.setTextColor(255, 0, 0);
             doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
           }
           x += 70;

           if (outline.required_speed_breaks) {
             doc.setTextColor(0, 255, 0);
             doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
           } else {
             doc.setTextColor(255, 0, 0);
             doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
           }
           x += 60;

           if (outline.required_power_breaks) {
             doc.setTextColor(0, 255, 0);
             doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
           } else {
             doc.setTextColor(255, 0, 0);
             doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
           }
           x += 60;

           if (outline.required_minimum_score) {
             doc.setTextColor(0, 255, 0);
             doc.text('Have: ' + scores_objects.total_average_score, x, y);
           } else {
             doc.setTextColor(255, 0, 0);
             doc.text('Have: ' + scores_objects.total_average_score, x, y);
           }

           doc.setTextColor(0, 0, 0);
           doc.setFontSize('8');
           // set each technique column up, one by one
           x = 10;
           y = 55;
           doc.text('Order', x, y);
           x += 17;
           doc.text('Technique', x, y);
           x += 90;
           doc.text('Board Size', x, y);
           x += 23;
           doc.text('# of Boards', x, y);
           x += 23;
           y = 30;
           doc.addImage(imgData, 'PNG', x, y, 60, 30);
           y = 55;
           x += 66;
           doc.text('Score', x, y);

           for (const technique of outline.breaking_techniques_to_outlines) {
             const techniqueDirections = technique.breaking_technique.directions;
             const direction_id = technique.direction_id;
             const board_id = technique.board_id;
             const modifier_id = technique.dropdown_modifier_id;
             x = 10;
             y += 10;
             doc.text(technique.order.toString(), x, y);
             x += 17;
             let technique_string = '';
             for (const modifier of this.dropdown_support_options) {
               if (modifier.id === modifier_id) {
                 technique_string += modifier.description + " ";
                 break;
               }
             }
             technique_string += technique.breaking_technique.name + " ";

             for (const dir of techniqueDirections) {
               if (dir.id === direction_id) {
                 if (dir.id !== null && dir.name !== "N/A") {
                   technique_string += dir.name + " ";
                 }
                 break;
               }
             }
             doc.text(technique_string, x, y);
             x += 90;

             for (const board of this.dropdown_boards) {
               if (board.id === board_id) {
                 doc.text(board.name, x, y);
                 break;
               }
             }
             x += 23;

             doc.text(technique.num_boards.toString(), x, y);
             x += 24;

             doc.rect(x, y - 3, 5, 5);
             if (technique.self_held) {
               doc.text('X', x + 2, y + 1);
             }
             x += 10;

             doc.rect(x, y - 3, 5, 5);
             if (technique.blindfolded) {
               doc.text('X', x + 2, y + 1);
             }
             x += 10;

             doc.rect(x, y - 3, 5, 5);
             if (technique.simultaneous) {
               doc.text('X', x + 2, y + 1);
             }
             x += 12;

             doc.rect(x, y - 3, 5, 5);
             if (technique.successive) {
               doc.text('X', x + 2, y + 1);
             }
             x += 12;

             doc.rect(x, y - 3, 5, 5);
             if (technique.no_spacers) {
               doc.text('X', x + 2, y + 1);
             }
             x += 23;

             // print the score out
             doc.text(scores_objects[technique.id].line_score.toString(), x, y);
           }

           // now put the totals in at the bottom
           // start with total boards
           y += 10;
           x = 10 + 17 + 75 + 23;
           doc.text("Total Boards: " + scores_objects.total_boards.toString(), x, y);
           x += 24 + 10 + 10 + 12 + 12 + 11 + 12;
           doc.text('Possible Score: ' + scores_objects.total_average_score.toString(), x, y);

           y += 10;
           x = 10;

           const boards = {};
           doc.text('Total Board Breakdown: ', x, y);
           for (const board of this.dropdown_boards) {
             boards[board.id] = {
               name: board.name,
               total: 0,
             };
           }
           y += 6;
           for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
             // first get the number of the board, add all them together
             boards[technique_to_outline.board_id].total += parseInt(technique_to_outline.num_boards);
           }
           const keys = Object.keys(boards);
           for (const key of keys) {
             const board = boards[key];
             if (board.name !== 'N/A') {
               x = 10;
               doc.text(board.name + 's - ' + board.total.toString(), x, y);
               y += 6;
             }
           }
           x += 24;

           // add 1 page per user, just like it shows right now
           if (outlines.indexOf(outline) !== outlines.length-1) {
             doc.addPage();
           }
         }
         doc.save("outlinesSelected.pdf");
         this.spinning = false;
       })
       .catch((error) => {
         this.spinning = false;
         this.error_txt = 'Error: ' + error;
         this.openModal('error');
       });
     },
     async printCertificates(type) {
       this.spinning = true;
       // get kicks and motions for the kick combos

       let blackBeltID
       for (let rank of this.dropdown_ranks){
         if (rank.name === "9th Degree"){
           blackBeltID = rank.id
           break
         }
       }

       const date = moment().format('MM-DD-YYYY');
       // Get the width of the page
       const doc = new jsPDF({
         orientation: "landscape",
         format: 'letter',
       });
       let empty = true
       let count = 0
       function addFontToPDF(pdf) {
         pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
         pdf.addFont('nyala.ttf', 'nyala', 'normal');
         pdf.addFileToVFS('nyala.ttf', nyalaBoldBase64);
         pdf.addFont('nyala.ttf', 'nyala', 'bold');
         pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
         pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
       }

       addFontToPDF(doc)

       // Create an image element
       const BlackCertImg = new Image();
       BlackCertImg.src = base64BlackCert;
       let VO = this
       BlackCertImg.onload = function() {
         const RedCertImg = new Image();
         RedCertImg.src = base64RedCert;
         RedCertImg.onload = function() {
           for (let order of VO.order_numbers) {
             if (order.outline_id && VO.checkSelected(order.outline_id)) {
               empty = false
               count++

               const imgWidth = this.width;
               const imgHeight = this.height;

               const aspectRatio = imgWidth / imgHeight;

               const pageWidth = doc.internal.pageSize.getWidth();
               const pageHeight = doc.internal.pageSize.getHeight();

               let renderWidth, renderHeight;
               if (aspectRatio > pageWidth / pageHeight) {
                 renderWidth = pageWidth;
                 renderHeight = pageWidth / aspectRatio;
               } else {
                 renderHeight = pageHeight;
                 renderWidth = pageHeight * aspectRatio;
               }

               let x = (pageWidth - renderWidth) / 2;
               let y = (pageHeight - renderHeight) / 2;

               if (type) {
                 doc.addImage(BlackCertImg, 'JPEG', x, y, renderWidth, renderHeight);
                 doc.setFont('Parchment', 'normal')
                 doc.setFontSize(80)
                 let userName = order.user.first_name + " " + order.user.last_name
                 const xTarget = 155;
                 y = 102;
                 let textWidth = doc.getTextWidth(userName);
                 x = xTarget - (textWidth / 2);
                 doc.text(userName, x, y)
                 let rankName
                 for (let rank of VO.dropdown_ranks){
                   if (rank.id === order.user.rank_id) {
                     rankName = rank.name
                     break
                   }
                 }
                 y = 140;
                 doc.setFontSize(60)
                 textWidth = doc.getTextWidth(rankName);
                 x = xTarget - (textWidth / 2);
                 doc.text(rankName, x, y)
               } else {
                 doc.addImage(RedCertImg, 'JPEG', x, y, renderWidth, renderHeight);
                 doc.setFont('nyala', 'normal')
                 doc.setFontSize(60)
                 let userName = order.user.first_name + " " + order.user.last_name
                 let rankName
                 for (let rank of VO.dropdown_ranks){
                   if (rank.id === order.user.rank_id) {
                     rankName = rank.name
                     break
                   }
                 }
                 const xTarget = pageWidth / 2;
                 y = 98;
                 let textWidth = doc.getTextWidth(userName);
                 x = xTarget - (textWidth / 2);

                 doc.setFontSize(60)
                 const outlineColor = [255, 255, 255];
                 const textColor = [0, 0, 0];
                 let outline = .7

                 drawOutlinedText(userName, x, y, outlineColor, textColor, outline);
                 doc.setFontSize(35)
                 textWidth = doc.getTextWidth(rankName);
                 x = xTarget - (textWidth / 2);
                 y = 143
                 outline = .5
                 drawOutlinedText(rankName, x, y, outlineColor, textColor , outline);

                 function drawOutlinedText(text, x, y, outlineColor, textColor, offset) {
                   doc.setTextColor(...outlineColor);

                   for (let i = offset * -1; i <= offset; i += .1) {
                     doc.text(text, x + i, y + offset)
                     doc.text(text, x + offset, y + i)
                     doc.text(text, x + i, y - offset)
                     doc.text(text, x - offset, y + i)
                   }

                   doc.setTextColor(...textColor);
                   doc.text(text, x, y);
                 }

               }

               if (count !== VO.selected_outlines.length) {
                 doc.addPage();
               }
             }
           }
           if (!empty) {
             let fileName = "Belt Testing Certificates.pdf"
             doc.save(fileName);
           } else {
             VO.error_txt = "No Competitors selected"
             VO.openModal('error')
           }
           VO.spinning = false;
         }
         RedCertImg.onerror = function(e) {
           VO.error_txt = 'Red Certificate failed to load.' + e
           VO.openModal('error')
         };
       }
       BlackCertImg.onerror = function(e) {
         VO.error_txt = 'Black Certificate failed to load.' + e
         VO.openModal('error')
       };



       /*

       const offset = .3

       const outlineColor = [255, 0, 0]; // Red
       const textColor = [0, 0, 0]; // Black

       function drawOutlinedText(text, x, y, outlineColor, textColor) {
         doc.setTextColor(...outlineColor);

         for (let i = 0; i <= offset; i += .05) {
           doc.text(text, x - i, y - i);
           doc.text(text, x + i, y - i);
           doc.text(text, x - i, y + i);
           doc.text(text, x + i, y + i);
         }

         doc.setTextColor(...textColor);
         doc.text(text, x, y);
       }

       drawOutlinedText(text, x, y, outlineColor, textColor);



       for (let order of this.order_numbers){
         if (order.outline_id && this.checkSelected(order.outline_id)) {
           empty = false
           count++


           if (count !== this.selected_outlines.length){
             doc.addPage();
           }
         }
       }
       */
     },
     async printNextRank() {
       this.spinning = true;
       // get kicks and motions for the kick combos

       const date = moment().format('MM-DD-YYYY');
       // Get the width of the page
       const doc = new jsPDF({
         orientation: "landscape",
         format: 'letter',
       });
       let empty = true
       let count = 0
       for (let order of this.order_numbers){
         if (order.outline_id && this.checkSelected(order.outline_id)) {
           empty = false
           count++
           doc.setFontSize('12');
           const pageWidth = doc.internal.pageSize.getWidth();
           const pageHeight = doc.internal.pageSize.getHeight();
           const halfPage = pageWidth / 2;
           // do this in two page segments
           let x = 10;
           let y = 5;
           doc.text(date, x, y);
           y += 7;
           doc.setFontSize('12');
           doc.text('Beyond Sports Taekwondo Curriculum', x, y);
           y += 7;
           doc.text(order.user.first_name + " " + order.user.last_name, x, y);
           y += 7;
           let nextRank
           let nextRankBool = false
           for (let rank of this.dropdown_ranks) {
             if (nextRankBool) {
               nextRank = rank
               break
             }
             if (rank.id === order.user.rank_id) {
               if (rank !== this.dropdown_ranks[this.dropdown_ranks.length - 1]) {
                 nextRankBool = true
               }
             }
           }

           doc.text(nextRank.name, x, y);
           y += 9;

           for (let category of this.categories) {
             switch (category.id) {
               case "breaking_techniques":
                 await this.getBreakingByRankId(nextRank.id)
                 break
               case "poomsae":
                 await this.getPoomsaeByRankId(nextRank.id)
                 break
               case "kicks":
                 await this.getKicksByRankId(nextRank.id)
                 break
               case "self_defense":
                 await this.getSelfDefByRankId(nextRank.id)
                 break
               case "kick_combos":
                 await this.getKickCombosByRankId(nextRank.id)
                 break
               case "techniques":
                 await this.getTechsByRankId(nextRank.id)
                 break
               case "terminology":
                 await this.getTermsByRankId(nextRank.id)
                 break
               case "documentation":
                 await this.getDocsByRankId(nextRank.id)
                 break
             }
             if (category.items.length > 0) {
               doc.text(category.name, x, y)
               y += 3;
               doc.line(x, y, pageWidth, y);
               y += 5;
               for (let item of category.items) {
                 let size = 5
                 doc.rect(x, y - 4, size, size)
                 doc.text(item.name, x + 15, y)
                 doc.text("Not Ready", x + 146, y)
                 doc.text("Getting There", x + 175, y)
                 doc.text("Good", x + 210, y)
                 doc.text("Excellent", x + 230, y)
                 y += 5;
               }
               y += 10
             }
           }

           if (count !== this.selected_outlines.length){
             doc.addPage();
           }
         }
       }

       if (!empty) {
         let fileName = "Rank Checklists.pdf"
         doc.save(fileName);
       } else {
         this.error_txt = "No Competitors selected"
         this.openModal('error')
       }

       this.spinning = false;
     },
     getDocsByRankId(id){
       return documentationAPIService.getDocumentationByRank(id, this.$router)
         .then((response) => {
           for (const category of this.categories) {
             if (category.id === 'documentation') {
               category.items = [];
               for (const item of response) {
                 const db_items = item.documentations;
                 for (const iteritem of db_items) {
                   iteritem.tested = false;
                   if (iteritem.documentation_to_ranks) {
                     iteritem.tested = iteritem.documentation_to_ranks.tested;
                   }
                   iteritem.value = iteritem.id;
                   iteritem.text = iteritem.description;
                   iteritem.name = iteritem.description;
                   if (iteritem.tested){
                     category.items.push(iteritem);
                   }
                 }
               }
               break;
             }
           }
         }).catch((error) => {
           this.error_txt = 'Error getting documentation: ' + error;
           this.openModal('error');
         });
     },
     getTermsByRankId(id){
       return terminologyAPIService.getTerminologyByRank(id, this.$router)
         .then((response) => {
           for (const category of this.categories) {
             if (category.id === 'terminology') {
               category.items = [];
               for (const item of response) {
                 const db_items = item.terminologies;
                 for (const iteritem of db_items) {
                   iteritem.tested = false;
                   if (iteritem.terminology_to_ranks.tested) {
                     iteritem.tested = iteritem.terminology_to_ranks.tested;
                   }
                   iteritem.value = iteritem.id;
                   iteritem.text = iteritem.description;
                   iteritem.name = iteritem.description;
                   if (iteritem.tested){
                     category.items.push(iteritem);
                   }
                 }
               }
               break;
             }
           }
         }).catch((error) => {
           this.error_txt = 'Error getting terminology: ' + error;
           this.openModal('error');
         });
     },
     getTechsByRankId(id){
       return techniquesAPIService1.getTechniqueByRank(id, this.$router)
         .then((response) => {
           for (const category of this.categories) {
             if (category.id === 'techniques') {
               category.items = [];
               for (const item of response) {
                 const db_items = item.techniques;
                 for (const iteritem of db_items) {
                   iteritem.tested = false;
                   if (iteritem.techniques_to_ranks) {
                     iteritem.tested = iteritem.techniques_to_ranks.tested;
                   }
                   iteritem.value = iteritem.id;
                   iteritem.text = iteritem.description;
                   iteritem.name = iteritem.description;
                   if (iteritem.tested){
                     category.items.push(iteritem);
                   }
                 }
               }
               break;
             }
           }
         }).catch((error) => {
           this.error_txt = 'Error getting techniques: ' + error;
           this.openModal('error');
         });
     },
     getKickCombosByRankId(id){
       return kickCombosAPIService.getKickCombosByRank(id, this.$router)
         .then((response) => {
           for (const category of this.categories) {
             if (category.id === 'kick_combos') {
               category.items = [];
               for (const item of response) {
                 const db_items = item.kick_combos;
                 for (const iteritem of db_items) {
                   iteritem.tested = false;
                   if (iteritem.kick_combos_to_ranks) {
                     iteritem.tested = iteritem.kick_combos_to_ranks.tested;
                   }
                   iteritem.value = iteritem.id;
                   iteritem.text = iteritem.description;
                   iteritem.name = iteritem.description;
                   if (iteritem.tested){
                     category.items.push(iteritem);
                   }
                 }
               }
               break;
             }
           }
         }).catch((error) => {
           this.error_txt = 'Error getting kick combos: ' + error;
           this.openModal('error');
         });
     },
     getSelfDefByRankId(id){
       return selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(id, this.$router)
         .then((response) => {
           for (const category of this.categories) {
             if (category.id === 'self_defense') {
               category.items = [];
               for (const item of response) {
                 const db_items = item.self_defense_techniques;
                 for (const iteritem of db_items) {
                   iteritem.tested = false;
                   if (iteritem.self_defense_techniques_to_ranks.tested) {
                     iteritem.tested = iteritem.self_defense_techniques_to_ranks.tested;
                   }
                   iteritem.value = iteritem.id;
                   iteritem.text = iteritem.description;
                   iteritem.name = iteritem.description;
                   if (iteritem.tested){
                     category.items.push(iteritem);
                   }
                 }
               }
               break;
             }
           }
         }).catch((error) => {
           this.error_txt = 'Error getting self defense: ' + error;
           this.openModal('error');
         });
     },
     getKicksByRankId(id){
       return kicksAPIService.getKickByRank(id, this.$router)
         .then((response) => {
           for (const category of this.categories) {
             if (category.id === 'kicks') {
               category.items = [];
               for (const kicks_to_rank of response) {
                 const kicks = kicks_to_rank.kicks;
                 for (const kick of kicks) {
                   kick.tested = false;
                   if (kick.kicks_to_ranks) {
                     kick.tested = kick.kicks_to_ranks.tested;
                   }
                   kick.value = kick.id;
                   kick.text = kick.description;
                   kick.name = kick.description;
                   if (kick.tested){
                     category.items.push(kick);
                   }
                 }
               }
               break;
             }
           }
         }).catch((error) => {
           this.error_txt = 'Error getting kicks: ' + error;
           this.openModal('error');
         });
     },
     getPoomsaeByRankId(id){
       return poomsaeAPIService.getPoomsaeByRank(id, this.$router)
         .then((response) => {
           for (const category of this.categories) {
             if (category.id === 'poomsae') {
               category.items = [];
               for (const poomsae_to_rank of response) {
                 const poomsaes = poomsae_to_rank.poomsaes;
                 for (const poomsae of poomsaes) {
                   poomsae.tested = false;
                   if (poomsae.poomsae_to_ranks.tested) {
                     poomsae.tested = poomsae.poomsae_to_ranks.tested;
                   }
                   poomsae.value = poomsae.id;
                   poomsae.text = poomsae.description;
                   poomsae.name = poomsae.description;
                   if (poomsae.tested){
                     category.items.push(poomsae);
                   }
                 }
               }
               break;
             }
           }
         }).catch((error) => {
           this.error_txt = 'Error getting poomsae: ' + error;
           this.openModal('error');
         });
     },
     getBreakingByRankId(id){
       return breakingTechniquesAPIService.getBreakingTechniquesByRankID(id, this.$router)
         .then((response) => {
           for (const category of this.categories) {
             if (category.id === 'breaking_techniques') {
               category.items = [];
               for (const technique_to_rank of response) {
                 const techniques = technique_to_rank.breaking_techniques;
                 for (const technique of techniques) {
                   technique.tested = false;
                   if (technique.breaking_techniques_to_ranks) {
                     technique.tested = technique.breaking_techniques_to_ranks.tested;
                   }
                   technique.value = technique.id;
                   technique.text = technique.name;
                   if (technique.tested){
                     category.items.push(technique);
                   }
                 }
               }
               break;
             }
           }
         }).catch((error) => {
           this.error_txt = 'Error getting breaking Techniques: ' + error;
           this.openModal('error');
         });
     },
     unlockOutline(outline_id) {
       const outline = {
         locked: false,
       };
       return outlineAPIService.updateSingleOutline(outline_id, outline, this.$router)
       .then(() => {
         this.spinning = false;
         this.success_txt = 'Unlock Successful';
         this.openModal('success');
         this.getEventOutlines();
       })
       .catch((error) => {
         this.spinning = false;
         this.error_txt = 'Unlock failed: ' + error;
         this.openModal('error');
       });
     },
     async getEvent() {
       return eventsAPIService.getEvent(this.event_id, this.$router)
       .then((event) => {
         this.event = event;
       })
       .catch((error) => {
         this.error_txt = "Error: " + error.message;
         this.openModal('error');
       });
     },
     clickSelect() {
       this.selectAll = !this.selectAll
       if (this.selectAll === false) {
         this.selected_outlines = [];
       } else {
         for (const order_number of this.order_numbers) {
           if (order_number && order_number.outline && order_number.outline.id) {
             this.selected_outlines.push(order_number.outline.id);
           }
         }
       }
     },
     async getEventOutlines() {
       orderNumbersAPIService.getAllOrdersByEvent(this.event_id, this.$router)
       .then((response) => {
         this.order_numbers = response;
         this.filteredOrders = this.order_numbers
         this.updateOrder()
       })
       .catch((error) => {
         this.error_txt = "Error: " + error.message;
         this.openModal('error');
       });
     },
     openModal(name) {
       this.modals[name] = true
     },
     closeModal(name) {
       this.modals[name] = false
     },
     // get the full list of directions so it'll loop way better and cleaner
     getAllDirections() {
       return directionsAPIService.getAllDirections(this.$router)
       .then((directions) => {
         for (const dir of directions) {
           dir.text = dir.name;
           dir.value = dir.id;
         }
         this.allDirections = directions;
       })
       .catch((error) => {
         this.error_txt = 'Error getting directions: ' + error;
         this.openModal('error');
       });
     },
     // get all the directions used in dropdowns
     getDirections() {
       return directionsAPIService.getDirections(this.$router)
       .then((directions) => {
         for (const dir of directions) {
           dir.text = dir.name;
           dir.value = dir.id;
         }
         this.dropdown_directions = directions;
       })
       .catch((error) => {
         this.error_txt = 'Error getting directions for dropdown: ' + error;
         this.openModal('error');
       });
     },


     // get all the techniques used in dropdowns
     getTechniques() {
       return breakingTechniquesAPIService.getBreakingTechniques(this.$router)
       .then((techniques) => {
         for (const technique of techniques) {
           technique.text = technique.name;
           technique.value = technique.id;
         }
         const tmpTechnique = {
           text: '-------',
           value: null,
           id: null,
           name: '-------',
         };
         techniques.unshift(tmpTechnique);
         this.dropdown_techniques = techniques;
       })
       .catch((error) => {
         this.error_txt = 'Error getting techniques for dropdown: ' + error;
         this.openModal('error');
       });
     },
     // get all the ranks used in dropdowns
     getRanks() {
       return ranksAPIService.getRanks(this.$router)
       .then((ranks) => {
         this.dropdown_ranks = ranks;
         for (const rank of this.dropdown_ranks) {
           rank.text = rank.name;
           rank.value = rank.id;
         }
       })
       .catch((error) => {
         this.error_txt = 'Error getting ranks for dropdown: ' + error;
         this.openModal('error');
       });
     },

     getSupportOptions() {
       return supportOptionsAPIService.getSupportOptions(this.$router)
       .then((modifiers) => {
         for (const modifier of modifiers) {
           modifier.text = modifier.description;
           modifier.value = modifier.id;
           this.dropdown_support_options.push(modifier);
         }
       })
       .catch((error) => {
         this.error_txt = 'Error getting modifiers for dropdown: ' + error;
         this.openModal('error');
       });
     },

     moveUp(index) {
       if (index > 0) {
         const temp = this.order_numbers[index];
         this.order_numbers.splice(index, 1);
         this.order_numbers.splice(index - 1, 0, temp);
         this.updateOrder(temp)
       }
       this.onEnd();
     },
     moveDown(index) {
       if (index < this.order_numbers.length - 1) {
         const temp = this.order_numbers[index];
         this.order_numbers.splice(index, 1);
         this.order_numbers.splice(index + 1, 0, temp);
         this.updateOrder(temp)
       }
       this.onEnd();
     },
     updateOrder(){
       for (let i = 0; i < this.order_numbers.length; i++){
         this.order_numbers[i].order = i+1
       }
       this.filteredOrders =this.order_numbers
     },
     handleSortChange({ column, prop, order}) {
       if (order === "ascending") {
         this.filteredOrders.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
       } else if (order === "descending") {
         this.filteredOrders.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
       }
     },
     filterOrders(){
       this.filteredOrders = this.order_numbers.filter(
         (data) =>
           !this.search ||
           data.name.toLowerCase().includes(this.search.toLowerCase())
       )
     },
     checkSelected(id){
       if (this.selected_outlines.includes(id)) return true;
       return false
     },
     selectID(id){
       if (this.selected_outlines.includes((id))) {
         let index = this.selected_outlines.indexOf(id)
         this.selected_outlines.splice(index, 1)
       } else {
         this.selected_outlines.push(id)
       }
     },
   },
 }
 </script>

 <style scoped>
 .met{
   border: solid green 1px;
   border-radius: 3px;
   background-color: #abff8f;
   color: black;
 }

 .unmet{
   border: solid red 1px;
   border-radius: 3px;
   background-color: #ff8f8f;
   color: black;
 }
 </style>
