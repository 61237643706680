<!--Whole code disappears when comment out to line 16-->
<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Programs Table</h4>
                <p class="card-category">
                  Create or Edit Programs
                </p>
                <p class="card-category">
                  <el-button type="primary" @click="addProgram('create')" icon="">Add New Program</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterPrograms"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterPrograms" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredPrograms" style="width: 100%" height="65vh" @sort-change="handleSortChange">
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" sortable="custom" label="Name" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input placeholder="Name" v-model="scope.row.name" @change="updateProgram(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Description" :min-width="200" align="center">
          <template v-slot="scope">
            <el-input placeholder="Description" v-model="scope.row.description" @change="updateProgram(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template v-slot="scope">
            <el-button type="danger" @click="deleteProgram(scope.row.id)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>

    <!--Dialog for when button is pressed I think-->
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>

    <!--Code for creating new program button-->
    <el-dialog
      center
      title="Create New Program"
      :visible.sync="modals.create">
      <div>
        <div class="row">
          <div class="col">
            <label>Name:</label>
            <el-input v-model="program_name" placeholder="Enter Program Name"></el-input>
          </div>
          <div class="col">
            <label>Description:</label>
            <el-input v-model="program_description" placeholder="Enter Program Description"></el-input>
          </div>
        </div>
        <br>
        <div slot="footer" class="dialog-footer text-center">
          <el-button type="success" @click="createProgram('create')" icon="">OK</el-button>
          <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- Success text for creating new program button-->
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<!--Error when commented out, IDK-->
<script>
import { Dialog, Input, Button, Table, TableColumn } from 'element-ui';
import ProgramsAPIService from "src/servicehandlers/ProgramsAPIService";

const programsAPIService = new ProgramsAPIService();

export default {
  name: "TechniqueAdmin",
  components: {
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      spinning: false,
      program_description: null,
      programs: [],
      filteredPrograms: [],
      search: '',
      error_txt: null,
      program_name: null,
      success_txt: null,
      last_order: null,
      modals: {
        error: false,
        success: false,
        person: false,
        create: false,
      },
    }
  },
  mounted() {
    this.getPrograms();
  },
  methods: {
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.programs.length; i += 1) {
        const id = this.programs[i].id;
        this.programs[i].order = i + 1;
        promises.push(programsAPIService.updateProgram(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        // const self = this;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Error updating program! ' + error,
          timer: 1000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          showConfirmButton: false
        });
      });
    },
    addProgram(name) {
      this.openModal(name);
    },
    getPrograms() {
      return programsAPIService.getPrograms(this.$router)
      .then((response) => {
        this.programs = response;
        for (const program of this.programs) {
          this.last_order = program.order;
        }
        this.filteredPrograms = this.programs
      })
      .catch((error) => {
        this.error_txt = 'Error getting programs: ' + error;
        this.openModal('error');
      });
    },
    updateProgram(program) {
      return programsAPIService.updateProgram(program.id, program, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Program update successful';
        /*this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs*/
      })
      .catch((error) => {
        this.error_txt = 'Error updating Program: ' + error;
        this.openModal('error');
      });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    deleteProgram(id) {
      return programsAPIService.deleteProgram(id, this.$router)
      .then(() => {
        this.getPrograms();
        const self = this;
        this.success_txt = 'Delete Program successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error deleting program: ' + error;
        this.openModal('error');
      });
    },
    createProgram(modalName) {
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      const program = {
        order,
        name: this.program_name,
        description: this.program_description
      };
      return programsAPIService.addProgram(program, this.$router)
      .then((response) => {
        this.programs.push(response);
        this.closeModal(modalName);
        const self = this;
        this.success_txt = 'Add Program successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error creating program: ' + error;
        this.openModal('error');
      });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.programs[index];
        this.programs.splice(index, 1);
        this.programs.splice(index - 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    moveDown(index) {
      if (index < this.programs.length - 1) {
        const temp = this.programs[index];
        this.programs.splice(index, 1);
        this.programs.splice(index + 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    updateOrder(item){
      for (let i = 0; i < this.programs.length; i++){
        this.programs[i].order = i+1
      }
      this.updateProgram(item)
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredPrograms.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredPrograms.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterPrograms(){
      this.filteredPrograms = this.programs.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
  }
}
</script>

<style scoped>
el-input,el-select{
  width: 100%;
}
</style>
