import CommonAPIService from './CommonHandler';

export class TechniquesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the breaking
  getTechnique(router) {
    const url = '/api/techniques';
    return this.CommonAPIService.getCall(url, '', router);
  }
  getTechniqueTypes(router) {
    const url = '/api/techniques/types';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getTechniqueByRank(rank_id, router) {
    const url = '/api/techniques/' + rank_id + '/rank';
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveTechniqueToTechniques(techniques, router) {
    const url = `/api/techniques/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getTechniqueByID(technique_id, router) {
    const url = `/api/techniques/${technique_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveTechniqueToRank(ranks, router) {
    const url = '/api/techniques/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addTechnique(technique, router) {
    const url = '/api/techniques';
    return this.CommonAPIService.postCall(url, technique, router);
  }
  addTechniqueType(technique_type, router) {
    const url = '/api/techniques/types';
    return this.CommonAPIService.postCall(url, technique_type, router);
  }

  updateTechnique(technique_id, parms, router) {
    const url = `/api/techniques/${technique_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  updateTechniqueType(technique_id, parms, router) {
    const url = `/api/techniques/types/${technique_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  linkTechniqueToRank(technique_id, rank_id, router) {
    const url = `/api/techniques/${technique_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  updateTechniqueToRank(technique_ids, rank_id, router) {
    const url = `/api/techniques/${technique_ids}/${rank_id}/torank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteTechniqueToRank(technique_id, rank_id, router) {
    const url = `/api/techniques/${technique_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deleteTechnique(technique_id, router) {
    const url = `/api/techniques/${technique_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  deleteTechniqueType(technique_id, router) {
    const url = `/api/techniques/types/${technique_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  createOrUpdate(technique, router) {
    const url = `/api/techniques/createorupdate`;
    return this.CommonAPIService.postCall(url, technique, router);
  }

  updateTestedOnTechnique(technique_id, rank_id, tested, router) {
    const url = `/api/techniques/${technique_id}/${rank_id}/${tested}/tested`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default TechniquesAPIService;
