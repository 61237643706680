<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <card title="Events">
          <el-table stripe :data="eventData" style="width: 100%" @sort-change="handleSortChangeEvents">
            <el-table-column prop="name" sortable="custom" label="Name" :min-width="200" align="center">
              <template v-slot="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="Description" :min-width="200" align="center">
              <template v-slot="scope">
                {{scope.row.description}}
              </template>
            </el-table-column>
            <el-table-column label="Link" :min-width="150" align="center">
              <template v-slot="scope">
                <a :href="scope.row.media" target="_blank">{{ scope.row.media }}</a>
              </template>
            </el-table-column>
            <el-table-column prop="start_date" sortable="custom" label="Start Date" :min-width="150" align="center">
              <template v-slot="scope">
                {{ scope.row.start_date }}
              </template>
            </el-table-column>
            <el-table-column prop="end_date" sortable="custom" label="End Date" :min-width="150" align="center">
              <template v-slot="scope">
                {{ scope.row.end_date }}
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <card title="Outlines">
          <el-table stripe :data="outlines" style="width: 100%" @sort-change="handleSortChangeOutlines">
            <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
              <template v-slot="scope">
                {{scope.row.order}}
              </template>
            </el-table-column>
            <el-table-column prop="description" sortable="custom" label="Description" :min-width="200" align="center">
              <template v-slot="scope">
                {{scope.row.description}}
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import EventsAPIService from 'src/servicehandlers/EventsAPIService';
import OutlinesAPIService from 'src/servicehandlers/OutlinesAPIService';
import moment from 'moment';
import { Table, TableColumn } from 'element-ui';

const eventAPIService = new EventsAPIService();
const outlineAPIService = new OutlinesAPIService();

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      eventData: [],
      eventColumns: ['Name','Description','Link','Start Date','End Date'],
      outlineColumns: ['Order','Description','Edit','Delete'],
      editTooltip: 'Edit Task',
      deleteTooltip: 'Remove',
      outlines: [],
      pieChart: {
        data: {
          labels: ['40%', '20%', '40%'],
          series: [40, 20, 40]
        }
      },
      lineChart: {
        data: {
          labels: ['9:00AM', '12:00AM', '3:00PM', '6:00PM', '9:00PM', '12:00PM', '3:00AM', '6:00AM'],
          series: [
            [287, 385, 490, 492, 554, 586, 698, 695],
            [67, 152, 143, 240, 287, 335, 435, 437],
            [23, 113, 67, 108, 190, 239, 307, 308]
          ]
        },
        options: {
          low: 0,
          high: 800,
          showArea: false,
          height: '245px',
          axisX: {
            showGrid: false
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      barChart: {
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
            [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
          ]
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false
          },
          height: '245px'
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc(value) {
                return value[0]
              }
            }
          }]
        ]
      },
      tableData: {
        data: [
          {
            title: 'Sign contract for "What are conference organizers afraid of?"',
            checked: false
          },
          {
            title: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
            checked: true
          },
          {
            title: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
            checked: true
          },
          { title: 'Create 4 Invisible User Experiences you Never Knew About', checked: false },
          { title: 'Read "Following makes Medium better"', checked: false },
          { title: 'Unfollow 5 enemies from twitter', checked: false }
        ]
      }
    }
  },
  mounted() {
    // on mount get the events data, populate list of events
    return eventAPIService.getEvents(this.$router)
    .then((events) => {
      for (const event of events) {
        const newEvent = {
          name: event.name,
          description: event.description,
          media: event.media,
          start_date: moment.utc(event.start_date).format('MM-DD-YYYY HH:mm:ss'),
          end_date: moment.utc(event.end_date).format('MM-DD-YYYY HH:mm:ss'),
        };
        this.eventData.push(newEvent);
      }
      const userId = this.$store.getters.userId;
      return outlineAPIService.getOutlinesSimple(userId, this.$router)
    })
    .then((allOutlines) => {
      this.outlines = allOutlines;
    })
    .catch((error) => {
    });
  },
  methods: {
    handleSortChangeEvents({ column, prop, order}) {
      if (order === "ascending") {
        this.eventData.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.eventData.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    handleSortChangeOutlines({ column, prop, order}) {
      if (order === "ascending") {
        this.outlines.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.outlines.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
  }
}
</script>
<style>

</style>
