<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Print Curriculum</h4>
                <p class="card-category">Print the details of your assigned curriculum</p>
                <p class="card-category">
                  <el-button type="primary" @click="generatePDF">Print Selected Items</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-12 float-right">
                <p class="float-right">
                  <span style="font-weight: bold">Current Rank:</span> {{userRank.name}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex">
        <el-table stripe :data="categories" style="width: 25%">
          <el-table-column label="Include" align="center">
            <template v-slot="scope">
              <el-checkbox v-model="scope.row.visible"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Name" align="center">
            <template v-slot="scope">
              {{scope.row.name}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </card>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>Success: {{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button, Dialog, Checkbox } from 'element-ui';
import moment from 'moment';
import jsPDF from 'jspdf';
import RanksAPIService from "src/servicehandlers/RanksAPIService";
import BreakingTechniquesAPIService from "src/servicehandlers/BreakingTechniquesAPIService";
import PoomsaeAPIService from "src/servicehandlers/PoomsaeAPIService";
import KicksAPIService from "src/servicehandlers/KicksAPIService";
import SelfDefenseTechniquesAPIService from "src/servicehandlers/SelfDefenseTechniquesAPIService";
import KickCombosAPIService from "src/servicehandlers/KickCombosAPIService";
import TechniquesAPIService from "src/servicehandlers/TechniquesAPIService";
import TerminologyAPIService from "src/servicehandlers/TerminologyAPIService";
import DocumentationAPIService from "src/servicehandlers/DocumentationAPIService";
import ProgramsAPIService from "src/servicehandlers/ProgramsAPIService";
import MotionsAPIService from "src/servicehandlers/MotionsAPIService";
import RankTypesAPIService from "../../../../servicehandlers/RankTypesAPIService";
import UserAPIService from "../../../../servicehandlers/UsersAPIService";

const ranksAPIService = new RanksAPIService();
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
const poomsaeAPIService = new PoomsaeAPIService();
const kicksAPIService = new KicksAPIService();
const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService();
const kickCombosAPIService = new KickCombosAPIService();
const techniqueAPIService = new TechniquesAPIService();
const terminologyAPIService = new TerminologyAPIService();
const documentationAPIService = new DocumentationAPIService();
const programsAPIService = new ProgramsAPIService();
const motionsAPIService = new MotionsAPIService();
const rankTypesAPIService = new RankTypesAPIService();
const userAPIService = new UserAPIService();

export default {
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  name: "print-details",
  data() {
    return {
      modals: {
        error: false,
        success: false,
      },
      spinning: false,
      ranks: [],
      rank_types: [],
      programs: [],
      filter_ranks: [],
      limited_ranks: [],
      selectable: true,
      table: [1],
      selectAll: false,
      limitors: {
        beginner: {
          id: 0,
          value: false,
        },
        intermediate: {
          id: 0,
          value: false,
        },
        advanced: {
          id: 0,
          value: false,
        },
      },
      selected_program: null,
      selected_rank: null,
      selected_ranks: [],
      show_rank_reorder: false,
      rank_button_text: 'Reorder Ranks',
      selectedItems: {
        'breaking_techniques': null,
        'poomsae': null,
        'kicks': null,
        'self_defense': null,
        'kick_combos': null,
        'techniques': null,
        'terminology': null,
        'documentation': null,
        'programs': null,
      },
      selected_dropdown_item: null,
      error_txt: null,
      success_txt: null,
      categories: [
        {
          id: 'breaking_techniques',
          name: 'Breaking(Green Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'poomsae',
          name: 'Poomsae(Black Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'kicks',
          name: 'Kicks(Blue Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'self_defense',
          name: 'Self Defence(Brown Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'kick_combos',
          name: 'Kick Combos(Red Tape)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'techniques',
          name: 'Techniques',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        /*{
          id: 'terminology',
          name: 'Yellow Tape(ADD PDF FOR MEDIA)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'documentation',
          name: 'White (ADD PDF FOR MEDIA)',
          items: [""],
          dropdown_list: [""],
          visible: true
        },
        {
          id: 'programs',
          name: 'Programs',
          items: [""],
          dropdown_list: [""],
          visible: true
        }*/
      ],
      userId: null,
      userRankId: null,
      userRank: null,
    }
  },
  mounted() {
    this.getRanks()
    this.userId = this.$store.getters.userId
    this.getUserRankId()
  },
  computed: {
    isChecked(){
      return () => {
        return this.selectAll
      }
    }
  },
  methods: {
    async updateTestedFlag(category, item_id, tested_flag) {
      try {
        if (category.id === 'breaking_techniques') {
          await breakingTechniquesAPIService.updateTestedOnBreakingTechique(item_id, this.selected_rank, tested_flag, this.$router);
        } else if (category.id === 'poomsae') {
          await poomsaeAPIService.updateTestedOnPoomsae(item_id, this.selected_rank, tested_flag, this.$router);
        } else if (category.id === 'kicks') {
          await kicksAPIService.updateTestedOnKick(item_id, this.selected_rank, tested_flag, this.$router);
        } else if (category.id === 'self_defense') {
          await selfDefenseTechniquesAPIService.updateTestedOnSelfDefenseTechnique(item_id, this.selected_rank, tested_flag, this.$router);
        } else if (category.id === 'kick_combos') {
          await kickCombosAPIService.updateTestedOnKickCombo(item_id, this.selected_rank, tested_flag, this.$router);
        } else if (category.id === 'techniques') {
          await techniqueAPIService.updateTestedOnTechnique(item_id, this.selected_rank, tested_flag, this.$router);
        } else if (category.id === 'terminology') {
          await terminologyAPIService.updateTestedOnTerminology(item_id, this.selected_rank, tested_flag, this.$router);
        } else if (category.id === 'documentation') {
          await documentationAPIService.updateTestedOnDocumentation(item_id, this.selected_rank, tested_flag, this.$router);
        } else if (category.id === 'programs') {
          await programsAPIService.updateProgramsToRank(item_id, this.selected_rank, tested_flag, this.$router);
        }
      } catch (e) {
        this.error_txt = 'Error updating order: ' + e;
        this.openModal('error');
      }
    },
    getItemById(array, id) {
      return array.find(item => item.id === id);
    },
    closeModal(name) {
      this.modals[name] = false
    },
    async generatePDF() {
      this.spinning = true;
      // get kicks and motions for the kick combos
      let all_motions = null;
      try {
        all_motions = await motionsAPIService.getMotion(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting motions: ' + e;
        this.openModal('error');
        return;
      }
      let all_kicks = null;
      try {
        all_kicks = await kicksAPIService.getKick(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting kicks: ' + e;
        this.openModal('error');
        return;
      }

      const date = moment().format('MM-DD-YYYY');
      // Get the width of the page
      const doc = new jsPDF({
        orientation: "landscape",
        format: 'letter',
      });
      doc.setFontSize('12');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      // do this in two page segments
      let x = 10;
      let y = 5;
      doc.text(date, x, y);
      y += 7;
      doc.setFontSize('12');
      doc.text('Beyond Sports Taekwondo Curriculum', x, y);
      y += 7;

      doc.setFontSize('12');
      doc.text(this.userRank.name, x, y);
      doc.setFontSize('8');
      y += 5;

      if (this.categories[2].visible) {
        doc.setFontSize('12');
        doc.text('Kicking', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, halfPage, y);
        y += 5;

        let kicks = null;
        try {
          kicks = await kicksAPIService.getKickByRank(this.userRank.id, this.$router)
        } catch (e) {
          this.error_txt = 'Error getting kicks by userRank: ' + e;
          this.openModal('error');
          return;
        }

        // POPULATE KICKS
        for (const item of kicks) {
          let rank_kicks = [];
          if (item.kicks) {
            rank_kicks = item.kicks;
          }
          for (const kick of rank_kicks) {
            if (kick.description) {
              doc.text(kick.description, x, y);
              y += 5;
            }
          }
        }
        y += 3;
      }
      if (this.categories[4].visible) {
        // KICK COMBOS
        doc.setFontSize('12');
        doc.text('Kicking Combos', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, halfPage, y);
        y += 5;
        let kick_combos = null;
        try {
          kick_combos = await kickCombosAPIService.getKickCombosByRank(this.userRank.id, this.$router)
        } catch (e) {
          this.error_txt = 'Error getting kick combos: ' + e;
          this.openModal('error');
          return;
        }

        for (const item of kick_combos) {
          let rank_kick_combos = [];
          if (item.kick_combos) {
            rank_kick_combos = item.kick_combos;
          }
          for (const kick_combo of rank_kick_combos) {
            let fulltext = '';
            doc.text(kick_combo.description, x, y);
            y += 5;

            doc.text(fulltext, x, y);
            y += 5;
          }
        }
        y += 3;
      }
      if (this.categories[1].visible) {
        doc.setFontSize('12');
        doc.text('Poomsae', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, halfPage, y);
        y += 5;

        let poomsae_by_rank = null;
        try {
          poomsae_by_rank = await poomsaeAPIService.getPoomsaeByRank(this.userRank.id, this.$router);
          for (const pr of poomsae_by_rank) {
            const poomsaes = pr.poomsaes;
            for (let i = 0; i < poomsaes.length; i++) {
              const poomsae = poomsaes[i];
              if (i === 0) {
                doc.text('Current: ' + poomsae.description, x, y);
                y += 5;
              } else {
                doc.text('Previous: ' + poomsae.description, x, y);
                y += 5;
              }
            }
          }
        } catch (e) {
          this.error_txt = 'Unresolved data error getting poomsae to ranks: ';
          this.openModal('error');
        }
        y += 3;
      }
      if (this.categories[5].visible) {
        doc.setFontSize('12');
        doc.text('Techniques', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, halfPage, y);
        y += 5;
        let techniques_by_rank = null;
        try {
          techniques_by_rank = await techniqueAPIService.getTechniqueByRank(this.userRank.id, this.$router);
          for (const tech of techniques_by_rank) {
            const techniques = tech.techniques;
            for (const technique of techniques) {
              doc.text(technique.description, x, y);
              y += 5;
            }
          }
        } catch (e) {
          this.error_txt = 'Unresolved data error getting techniques to ranks: ';
          this.openModal('error');
        }
        y += 3;
      }
      if (this.categories[3].visible) {
        doc.setFontSize('12');
        doc.text('Self Defense', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, halfPage, y);
        y += 5;
        let self_defense_by_rank = null;
        try {
          self_defense_by_rank = await selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(this.userRank.id, this.$router);
          for (const tech of self_defense_by_rank) {
            const techniques = tech.self_defense_techniques;
            for (const technique of techniques) {
              doc.text(technique.description, x, y);
              y += 5;
            }
          }
        } catch (e) {
          this.error_txt = 'Unresolved data error getting self defense techniques to ranks: ';
          this.openModal('error');
        }
        y += 3;
      }
      if (this.categories[0].visible) {
        doc.setFontSize('12');
        doc.text('Breaking', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, halfPage, y);
        y += 5;
        let breaking_by_rank = null;
        try {
          breaking_by_rank = await breakingTechniquesAPIService.getBreakingTechniquesByRankID(this.userRank.id, this.$router);
          for (const tech of breaking_by_rank) {
            const techniques = tech.breaking_techniques;
            for (const technique of techniques) {
              doc.text(technique.description, x, y);
              y += 5;
            }
          }
        } catch (e) {
          this.error_txt = 'Unresolved data error getting self techniques to ranks: ';
          this.openModal('error');
        }
      }

      x = 10;
      y = 10;

      this.spinning = false;
      doc.save("curriculum.pdf");
    },
    getTechniques() {
      return techniqueAPIService.getTechnique(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'techniques') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return techniqueAPIService.getTechniqueByRank(this.selected_rank, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'techniques') {
            category.items = [];
            for (const item of response) {
              const db_items = item.techniques;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.techniques_to_ranks) {
                  iteritem.tested = iteritem.techniques_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                category.items.push(iteritem);
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting techniques: ' + error;
        this.openModal('error');
      });
    },
    getDocumentation() {
      return documentationAPIService.getDocumentation(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'documentation') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return documentationAPIService.getDocumentationByRank(this.selected_rank, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'documentation') {
            category.items = [];
            for (const item of response) {
              const db_items = item.documentations;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.documentation_to_ranks) {
                  iteritem.tested = iteritem.documentation_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                category.items.push(iteritem);
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting documentation: ' + error;
        this.openModal('error');
      });
    },
    getKickCombos() {
      return kickCombosAPIService.getKickCombos(this.$router)
      .then((response) => {
        response.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });
        for (const category of this.categories) {
          if (category.id === 'kick_combos') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return kickCombosAPIService.getKickCombosByRank(this.selected_rank, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'kick_combos') {
            category.items = [];
            for (const item of response) {
              const db_items = item.kick_combos;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.kick_combos_to_ranks) {
                  iteritem.tested = iteritem.kick_combos_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                category.items.push(iteritem);
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Kick Combos: ' + error;
        this.openModal('error');
      });
    },
    getKicks() {
      return kicksAPIService.getKick(this.$router)
      .then((kicks_resp) => {

        kicks_resp.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'kicks') {
            category.dropdown_list = [];
            for (const kick of kicks_resp) {
              kick.value = kick.id;
              kick.text = kick.description;
              kick.name = kick.description;
              category.dropdown_list.push(kick);
            }
            break;
          }
        }
        return kicksAPIService.getKickByRank(this.selected_rank, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'kicks') {
            category.items = [];
            for (const kicks_to_rank of response) {
              const kicks = kicks_to_rank.kicks;
              for (const kick of kicks) {
                kick.tested = false;
                if (kick.kicks_to_ranks) {
                  kick.tested = kick.kicks_to_ranks.tested;
                }
                kick.value = kick.id;
                kick.text = kick.description;
                kick.name = kick.description;
                category.items.push(kick);
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting kicks: ' + error;
        this.openModal('error');
      });
    },
    getPoomsae() {
      return poomsaeAPIService.getPoomsae(this.$router)
      .then((poomsae_resp) => {

        poomsae_resp.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'poomsae') {
            category.dropdown_list = [];
            for (const poomsae of poomsae_resp) {
              poomsae.value = poomsae.id;
              poomsae.text = poomsae.description;
              poomsae.name = poomsae.description;
              category.dropdown_list.push(poomsae);
            }
            break;
          }
        }
        return poomsaeAPIService.getPoomsaeByRank(this.selected_rank, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'poomsae') {
            category.items = [];
            for (const poomsae_to_rank of response) {
              const poomsaes = poomsae_to_rank.poomsaes;
              for (const poomsae of poomsaes) {
                poomsae.tested = false;
                if (poomsae.poomsae_to_ranks.tested) {
                  poomsae.tested = poomsae.poomsae_to_ranks.tested;
                }
                poomsae.value = poomsae.id;
                poomsae.text = poomsae.description;
                poomsae.name = poomsae.description;
                category.items.push(poomsae);
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting poomsae: ' + error;
        this.openModal('error');
      });
    },
    getRanks() {
      return ranksAPIService.getRanks(this.$router)
      .then((response) => {
        this.ranks = response;
        for (let rank of this.ranks) {
          rank.text = rank.name;
          rank.value = rank.id;
          this.last_order = rank.order;
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Ranks: ' + error;
        this.openModal('error');
      });
    },
    getRankTypes(){
      return rankTypesAPIService.getRankTypes(this.$router)
        .then((response) => {
          this.rank_types = response;
          for (let type of this.rank_types){
            if (type.name === "beginner"){
              this.limitors.beginner.id = type.id
            } else if (type.name === "intermediate"){
              this.limitors.intermediate.id = type.id
            } else if (type.name === "advanced"){
              this.limitors.advanced.id = type.id
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting Rank Types: ' + error;
          this.openModal('error');
        });
    },
    getSelfDefense() {
      return selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
      .then((response) => {

        response.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          return 0;
        });

        for (const category of this.categories) {
          if (category.id === 'self_defense') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(this.selected_rank, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'self_defense') {
            category.items = [];
            for (const item of response) {
              const db_items = item.self_defense_techniques;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.self_defense_techniques_to_ranks.tested) {
                  iteritem.tested = iteritem.self_defense_techniques_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                category.items.push(iteritem);
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Self Defense Items: ' + error;
        this.openModal('error');
      });
    },
    getBreakingTechniques() {
      return breakingTechniquesAPIService.getBreakingTechniques(this.$router)
      .then((technique_resp) => {
        for (const category of this.categories) {
          if (category.id === 'breaking_techniques') {
            category.dropdown_list = [];

            // Sort techniques by order
            technique_resp.sort((a, b) => {
              if (a.order && b.order) {
                return a.order - b.order;
              }
              return 0;
            });

            for (const technique of technique_resp) {
              technique.value = technique.id;
              technique.text = technique.name;
              category.dropdown_list.push(technique);
            }
            break;
          }
        }
        return breakingTechniquesAPIService.getBreakingTechniquesByRankID(this.selected_rank, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'breaking_techniques') {
            category.items = [];
            for (const technique_to_rank of response) {
              const techniques = technique_to_rank.breaking_techniques;
              for (const technique of techniques) {
                technique.tested = false;
                if (technique.breaking_techniques_to_ranks) {
                  technique.tested = technique.breaking_techniques_to_ranks.tested;
                }
                technique.value = technique.id;
                technique.text = technique.name;
                category.items.push(technique);
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting breaking techniques: ' + error;
        this.openModal('error');
      });
    },
    getTerminology() {
      return terminologyAPIService.getTerminology(this.$router)
      .then((response) => {
        for (const category of this.categories) {
          if (category.id === 'terminology') {
            category.dropdown_list = [];
            for (const item of response) {
              item.value = item.id;
              item.text = item.description;
              item.name = item.description;
              category.dropdown_list.push(item);
            }
            break;
          }
        }
        return terminologyAPIService.getTerminologyByRank(this.selected_rank, this.$router)
      })
      .then((response) => {
        // now sort through the items
        for (const category of this.categories) {
          if (category.id === 'terminology') {
            category.items = [];
            for (const item of response) {
              const db_items = item.terminologies;
              for (const iteritem of db_items) {
                iteritem.tested = false;
                if (iteritem.terminology_to_ranks.tested) {
                  iteritem.tested = iteritem.terminology_to_ranks.tested;
                }
                iteritem.value = iteritem.id;
                iteritem.text = iteritem.description;
                iteritem.name = iteritem.description;
                category.items.push(iteritem);
              }
            }
            break;
          }
        }
      })
      .catch((error) => {
        this.error_txt = 'Error getting Terminology: ' + error;
        this.openModal('error');
      });
    },
    getUserRankId(){
      return userAPIService.getUserById(this.userId, this.$router)
        .then((response) => {
          this.userRankId = response.rank_id
          for (let rank of this.ranks){
            if (rank.id === this.userRankId){
              this.userRank = rank
              return
            }
          }
        })
        .catch((error) => {
          this.error_txt = "Error getting User: " + error;
          this.openModal('error')
        })
    },
    async onEnd(category) {
      const allIds = [];
      for (let i = 0; i < category.items.length; i += 1) {
        const item = category.items[i];
        allIds.push(item.id);
      }

      try {
        if (category.id === 'breaking_techniques') {
          await breakingTechniquesAPIService.updateBreakingTechniqueToRank(allIds, this.selected_rank, this.$router);
        } else if (category.id === 'poomsae') {
          await poomsaeAPIService.updatePoomsaeToRank(allIds, this.selected_rank, this.$router);
        } else if (category.id === 'kicks') {
          await kicksAPIService.updateKicksToRank(allIds, this.selected_rank, this.$router);
        } else if (category.id === 'self_defense') {
          await selfDefenseTechniquesAPIService.updateSelfDefenseToRank(allIds, this.selected_rank, this.$router);
        } else if (category.id === 'kick_combos') {
          await kickCombosAPIService.updateKickCombosToRank(allIds, this.selected_rank, this.$router);
        } else if (category.id === 'techniques') {
          await techniqueAPIService.updateTechniqueToRank(allIds, this.selected_rank, this.$router);
        } else if (category.id === 'terminology') {
          await terminologyAPIService.updateTerminologiesToRank(allIds, this.selected_rank, this.$router);
        } else if (category.id === 'documentation') {
          await documentationAPIService.updateDocumentationToRank(allIds, this.selected_rank, this.$router);
        } else if (category.id === 'programs') {
          await programsAPIService.updateProgramsToRank(allIds, this.selected_rank, this.$router);
        }
      } catch (e) {
        this.error_txt = 'Error updating order: ' + e;
        this.openModal('error');
      }
    },
    async onRankEnd(rank) {
      const allIds = [];
      for (let i = 0; i < this.ranks.length; i += 1) {
        const item = this.ranks[i];
        try {
          const parms = {
            order: i + 1,
          };
          await ranksAPIService.updateRank(item.id, parms, this.$router);
        } catch (e) {
          this.error_txt = 'Error updating rank order: ' + e;
          this.openModal('error');
        }
        allIds.push(item.id);
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    async removeItem(category, item) {
      const index = category.items.indexOf(item);
      if (index > -1) {
        category.items.splice(index, 1);

        // now unlink the item
        try {
          if (category.id === 'breaking_techniques') {
            const unlinked = breakingTechniquesAPIService.deleteBreakingTechniqueToRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'poomsae') {
            const unlinked = poomsaeAPIService.deletePoomsaeToRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'kicks') {
            const unlinked = kicksAPIService.deleteKickToRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'self_defense') {
            const unlinked = selfDefenseTechniquesAPIService.deleteSelfDefenseTechniquesToRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'kick_combos') {
            const unlinked = kickCombosAPIService.deleteKickCombosToRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'breaking') {
            const unlinked = techniqueAPIService.deleteTechniqueToRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'terminology') {
            const unlinked = terminologyAPIService.deleteTerminologyToRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'documentation') {
            const unlinked = documentationAPIService.deleteDocumentationToRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'programs') {
            const unlinked = programsAPIService.deleteProgramtoRank(item.id, this.selected_rank, this.$router);
          } else if (category.id === 'techniques') {
            const unlinked = techniqueAPIService.deleteTechniqueToRank(item.id, this.selected_rank, this.$router);
          }
        } catch (e) {
          this.error_txt = 'Error unlinking item: ' + e;
          this.openModal('error');
        }
      }
    },
    reorderRanks: function () {
      if (!this.show_rank_reorder) {
        this.rank_button_text = 'Done Ordering Ranks';
        this.show_rank_reorder = true;
      } else {
        this.rank_button_text = 'Reorder Ranks';
        this.show_rank_reorder = false;
      }
    },
    async selectDropdownItem(category) {
      const category_id = category.id;
      const selected_id = this.selected_dropdown_item.id;

      // append the dropped down item to the category
      let found = false;
      for (const item of category.items) {
        if (item.id === selected_id) {
          found = true;
          break;
        }
      }
      if (!found) {
        category.items.push(this.selected_dropdown_item);
        try {
          // now link it up to it as well
          if (category_id === 'breaking_techniques') {
            const link = await breakingTechniquesAPIService.linkBreakingTechniqueToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'poomsae') {
            const link = await poomsaeAPIService.linkPoomsaeToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'kicks') {
            const link = await kicksAPIService.linkKickToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'self_defense') {
            const link = await selfDefenseTechniquesAPIService.linkSelfDefenseTechniquesToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'kick_combos') {
            const link = await kickCombosAPIService.linkKickComboToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'breaking') {
            const link = await techniqueAPIService.linkTechniqueToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'terminology') {
            const link = await terminologyAPIService.linkTerminologyToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'documentation') {
            const link = await documentationAPIService.linkDocumentationToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'programs') {
            const link = await programsAPIService.linkProgramToRank(selected_id, this.selected_rank, this.$router);
          } else if (category_id === 'techniques') {
            const link = await techniqueAPIService.linkTechniqueToRank(selected_id, this.selected_rank, this.$router);
          }
        } catch (e) {
          this.error_txt = 'Error linking technique: ' + e;
          this.openModal('error');
        }
      }
    },
    filterRanks(){
      this.filter_ranks= []
      for (let rank of this.ranks){
        if (rank.program_id === this.selected_program){
          this.filter_ranks.push(rank)
        }
      }
      for (let rank of this.filter_ranks){
        rank.selected = false
      }
      this.limited_ranks = this.filter_ranks
      this.$forceUpdate()
    },
    limitRanksFromTypes(){
      this.limited_ranks = [];
      for (let rank of this.filter_ranks) {
        if (rank.rank_type_id === this.limitors.beginner.id && this.limitors.beginner.value) {
          this.limited_ranks.push(rank);
        } else if (rank.rank_type_id === this.limitors.intermediate.id && this.limitors.intermediate.value) {
          this.limited_ranks.push(rank);
        } else if (rank.rank_type_id === this.limitors.advanced.id && this.limitors.advanced.value) {
          this.limited_ranks.push(rank);
        }
      }

      if (!this.limitors.beginner.value && !this.limitors.intermediate.value && !this.limitors.advanced.value){
        this.limited_ranks = this.filter_ranks
      }
    },
    updateLimitedRanks(){
      let temp = this.limited_ranks
      this.limited_ranks = []
      for (let rank of temp) {
        this.limited_ranks.push(rank)
      }
    },
    selectAllCheck(){
      this.selectAll = !this.selectAll
      let temp = this.limited_ranks
      this.limited_ranks = []
      for (let rank of temp) {
        rank.selected = this.selectAll
        this.limited_ranks.push(rank)
      }
    },
  },

};
</script>

<style scoped>
</style>
