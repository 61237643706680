<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="text"
                               :error="failed ? 'The field is required': null"
                               :hasSuccess="passed"
                               label="Username"
                               name="email"
                               v-model="email">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="password"
                               :error="failed ? 'The Password field is required': null"
                               :hasSuccess="passed"
                               name="password"
                               label="Password"
                               v-model="password">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <el-button type="primary" native-type="submit" icon="">Login</el-button>
                  <br>
                  <div class="forgot">
                    <router-link to="/forgot" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <span><h5>Error Occurred</h5></span>
      <div class="row">
        <div class="col-md-12"></div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <label>{{ error_text}}</label>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="closeModal('error')" icon="">Error</el-button>
      </span>
    </el-dialog>
  </auth-layout>
</template>
<script>
// import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
import { FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import CommonAPIService from "src/servicehandlers/CommonHandler";
const apiService = new CommonAPIService();
import { Dialog, Button } from 'element-ui';

extend("email", email);
extend("required", required);
extend("min", min);

  export default {
    components: {
      // LCheckbox,
      FadeRenderTransition,
      AuthLayout,
      [Dialog.name]: Dialog,
      [Button.name]: Button,
    },
    data() {
      return {
        email: "",
        password: "",
        subscribe: true,
        error_text: '',
        modals: {
          error: false,
        },
      };
    },
    methods: {
      goToHome() {
        // this.rightsArray = this.loginCheckMixin(['page-claim', 'page-firm']);
        // let homePage=this.$store.state.userPreferences.home === 'firms' ? 'FirmsSearch' : 'ClaimsSearch';
        // let homePage=this.$store.state.userPreferences.home === 'claims' ? 'ClaimsSearch' : 'FirmsSearch';
        // homePage is preferred page if user has rights to see, else other search
        // based on all having at least one of these two page rights
        // if (!this.rightsArray.includes('page-firm')) homePage = 'ClaimsSearch';
        // if (!this.rightsArray.includes('page-claim')) homePage = 'FirmsSearch';
        this.$router.push({
          name: 'main'
        });
      },
      openModal(name) {
        this.modals[name] = true;
      },
      closeModal(name) {
        this.modals[name] = false;
      },
      submit() {
        let loggedIn = this.$store.getters.loggedIn;
        const errors = [];
        const login = {
          username: this.email,
          password: this.password
        };
        return apiService.login(login, this.$router, this.$store, errors)
        .then((response) => {
          if (response && response.success) {
            loggedIn = this.$store.getters.loggedIn;
            if (loggedIn && response.user.password_change === true) {
              this.$router.push({
                name: 'Reset Password'
              });
            } else if (loggedIn) {this.goToHome()}
          } else {
            this.error_text = response.msg;
            this.openModal('error');
          }
        })
        .catch((error) => {
          this.error_text = error;
          this.openModal('error');
        });
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open');
      },
      closeMenu () {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      }
    },
    beforeDestroy () {
      this.closeMenu();
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
